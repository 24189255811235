import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useScrollPercentage } from "react-scroll-percentage";
import { Box, useColorMode } from "theme-ui";

const UpdateOnScroll = {
  InView: ({
    threshold = null,
    rootMargin = "0px",
    triggerOnce = false,
    theme = "dark",
    children,
  }) => {
    const [, setColorMode] = useColorMode();
    const [containerRef, containerView] = useInView({
      rootMargin: rootMargin,
      threshold: threshold,
      triggerOnce: triggerOnce,
    });

    useEffect(() => {
      containerView ? setColorMode(theme) : setColorMode("light");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerView]);

    return (
      <Box ref={containerRef} className="update-on-scroll--in-view">
        {children}
      </Box>
    );
  },
  Sentinel: ({ theme = "dark" }) => {
    const [, setColorMode] = useColorMode();
    const [sentinelRef, sentinelPercentage] = useScrollPercentage();

    useEffect(() => {
      sentinelPercentage > 0.5 ? setColorMode(theme) : setColorMode("light");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sentinelPercentage]);

    return <Box ref={sentinelRef} className="update-on-scroll--sentinel" />;
  },
};

export default UpdateOnScroll;

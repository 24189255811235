// Reference: https://theme-ui.com/theme-spec
import { typography } from "./typography";
import { forms } from "./forms";
import { colors } from "./colors";

export const theme = {
  // Config theme-ui
  config: {
    // initialColorModeName: "inital",
    useLocalStorage: true,
    useBorderBox: true,
    useColorSchemeMediaQuery: false,
  },

  // Breakpoints: min-width
  // Mobile: 0 - 767px
  // Tablet: 768px - 1279px
  // Desktop: 1280px - 1499px
  // Large Desktop: 1500px
  breakpoints: ["480px", "768px", "1280px", "1500px"],
  space: [0, 4, 8, 16, 32, 64, 72, 128, 256, 512],
  ...typography,
  forms,
  colors,
  // Root styles apply to <html> tag
  styles: {
    root: {
      img: {
        width: "100%",
        height: "auto",
      },
      ul: {
        listStyle: "none",
        padding: "0",
        marginBottom: "1em",
      },
      ol: {
        marginBottom: "1em",
      },
      li: {
        marginBottom: "1em",
        "&:last-of-type": {
          marginBottom: "0",
        },
      },
    },
  },
};

import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, [location.pathname]);

  return null;
};

export default ScrollToTop;

// todo: better solution?
// import { useLayoutEffect } from 'react';
// import { useHistory } from 'react-router-dom';

// const ScrollToTop = () => {
//   const history = useHistory();
//   useLayoutEffect(() => {
//     let timeout;
//     if (history.action === 'POP') {
//       timeout = setTimeout(() => {
//         window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
//       }, 300);
//     } else {
//       window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
//     }
//     return () => {
//       timeout && clearTimeout(timeout);
//     };
//   }, [history]);
//   return null;
// };

// export default ScrollToTop;

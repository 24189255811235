import { gql } from "@apollo/client";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import { Grid } from "../../styles/layout";
import { Heading } from "../shared/typography/Heading";
import RichText from "../shared/richtext/RichText";
import Spacer from "../utility/Spacer";
import VerticalText from "../shared/decorative/VerticalText";
import TextBlock from "../shared/typography/TextBlock";
import { Button } from "../shared/ctas/Button";
import { isNumber } from "../../scripts/helpers";

export const textRowFrag = gql`
  fragment textRowFrag on BlockTextRow {
    heading
    headingSize
    body {
      json
    }
    label
    largeText
    ctaText
    ctaUrl
    ctaDescriptiveLabel
  }
`;

export const mapTextRowData = (data) => {
  return {
    heading: data.heading,
    headingSize: data.headingSize !== null ? data.headingSize : "H3",
    label: data.label,
    body: data.body,
    ctaText: data.ctaText,
    ctaUrl: data.ctaUrl,
    ctaDescriptiveLabel: data.ctaDescriptiveLabel,
    dividers: data.dividers != null ? data.dividers : false,
    largeText: data.largeText,
  };
};

const StyledTextRow = styled(Box)`
  label: text-row;
  grid-column: columns-start / columns-end;
`;

const BodyColBox = (props) => {
  const { isThreeCol, children, sx } = props;
  return (
    <Box
      sx={{
        gridColumn: [
          "columns-start / columns-end",
          "",
          isThreeCol ? "3 / span 5" : "6 / span 4",
          isThreeCol ? "3 / span 4" : "7 / span 5",
        ],
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
const TextRowHeading = (hSize, isThreeCol, heading, styles) => {
  switch (hSize) {
    case "H2":
      return (
        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              isThreeCol ? "3 / span 5" : "2 / span 4",
              isThreeCol ? "8 / span 5" : "3 / span 4",
            ],
          }}
        >
          <Heading.H2
            text={heading}
            sx={{
              marginBottom: ["32px", "", "50px", "0"],
              paddingRight: ["", "", !isThreeCol && "50px"],
              whiteSpace: "pre-wrap",
              hyphens: "auto",
              hyphenateLimitChars: "15",
              ...styles?.headingStyles,
            }}
          />
        </Box>
      );

    case "H3":
      return (
        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              isThreeCol ? "3 / span 5" : "2 / span 4",
              isThreeCol ? "8 / span 5" : "3 / span 4",
            ],
          }}
        >
          <Heading.H4
            as="h3"
            text={heading}
            sx={{
              marginBottom: ["50px"],
              paddingRight: ["", "", "50px", ""],
              whiteSpace: "pre-wrap",
              hyphens: "auto",
              hyphenateLimitChars: "15",
            }}
          />
        </Box>
      );

    case "Bold Body":
      return (
        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              isThreeCol ? "3 / span 5" : "2 / span 4",
              isThreeCol ? "8 / span 5" : "3 / span 4",
            ],
          }}
        >
          <TextBlock
            as="h3"
            variant="text.body.regular"
            sx={{
              marginBottom: ["50px"],
              paddingRight: ["", "", "50px", ""],
              whiteSpace: "pre-wrap",
              hyphens: "auto",
              hyphenateLimitChars: "15",
              fontWeight: "700",
            }}
          >
            {heading}
          </TextBlock>
        </Box>
      );

    default:
      return (
        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              isThreeCol ? "3 / span 5" : "2 / span 4",
              isThreeCol ? "8 / span 5" : "3 / span 4",
            ],
          }}
        >
          <TextBlock
            as="h3"
            variant="text.body.regular"
            sx={{
              marginBottom: ["50px"],
              paddingRight: ["", "", "50px", ""],
              whiteSpace: "pre-wrap",
              hyphens: "auto",
              hyphenateLimitChars: "15",
              fontWeight: "700",
            }}
          >
            {heading}
          </TextBlock>
        </Box>
      );
  }
};
const TextRow = ({ data, styles }) => {
  const isThreeCol = data.label;
  return (
    <StyledTextRow as="section">
      <Grid label="text-row" sx={{ gridAutoFlow: "dense" }}>
        {data.dividers && data.headingSize && (
          <>
            <Box
              sx={{
                gridColumn: ["columns-start / columns-end", "", "2 / -2"],
                borderTopColour: "blue",
                borderTopStyle: "solid",
                borderTopWidth: "1px",
              }}
            >
              <Spacer sx={{ height: ["75px", "", "100px", "150px"] }} />
            </Box>
          </>
        )}
        {isThreeCol && (
          <Box
            sx={{
              gridColumn: [
                "columns-start / columns-end",
                "",
                "2 / span 1",
                "2 / span 1",
              ],
              mb: ["24px", "", "0"],
              height: isNumber(data.label) ? "" : ["", "", "1px"],
            }}
          >
            <TextBlock as="div">
              {isNumber(data.label) ? (
                <Text>{data.label}</Text>
              ) : (
                <VerticalText sx={{ marginBottom: "0" }} flipOnMobile>
                  {data.label}
                </VerticalText>
              )}
            </TextBlock>
          </Box>
        )}
        {TextRowHeading(data.headingSize, isThreeCol, data.heading, styles)}
        <BodyColBox isThreeCol={isThreeCol}>
          <RichText content={data.body} isLarge={data.largeText} />
        </BodyColBox>
        {data.ctaUrl && (
          <BodyColBox
            isThreeCol={isThreeCol}
            sx={{ marginTop: ["48px", "", "80px"] }}
          >
            <Button.Primary
              text={data.ctaText ? data.ctaText : "Learn more"}
              to={data.ctaUrl}
              ariaLabel={data.ctaDescriptiveLabel}
              sx={{ maxWidth: "272px" }}
            />
          </BodyColBox>
        )}
      </Grid>
    </StyledTextRow>
  );
};

export default TextRow;

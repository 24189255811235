import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box } from "theme-ui";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { Grid } from "../../styles/layout";
import HeroSingleColumn from "../../components/heros/HeroSingleColumn";
import Error from "../Error/Error";
import Loading from "../../components/global/Loader";
import Spacer from "../../components/utility/Spacer";
import { useDataFetchStatus } from "../../hooks/useDataFetchStatus";
import { useIsValidURL } from "../../hooks/useIsValidURL";
import SEO from "../../components/global/SEO";

import TextRow, {
  mapTextRowData,
  textRowFrag,
} from "../../components/content/TextRow";
import PullQuote, {
  mapPullQuoteData,
  pullQuoteFrag,
} from "../../components/content/PullQuote";
import ImageBlock, {
  mapImageData,
  imageFrag,
} from "../../components/media/ImageBlock";
import ImageCluster, {
  mapImageClusterData,
  imageClusterFrag,
} from "../../components/media/ImageCluster";
import ImageCarousel, {
  mapImageCarouselData,
  imageCarouselFrag,
} from "../../components/media/ImageCarousel";
import TextColumns, {
  mapTextColumnsData,
  textColumnsFrag,
} from "../../components/content/TextColumns";
import TextWithImage, {
  textWithImageFrag,
  mapTextWithImageData,
} from "../../components/content/TextWithImage";

const GET_ARTICLE_BY_SLUG = gql`
  ${textRowFrag}
  ${imageFrag}
  ${imageCarouselFrag}
  ${imageClusterFrag}
  ${pullQuoteFrag}
  ${textColumnsFrag}
  ${textWithImageFrag}
  query ArticlePage($slug: String!, $isPreview: Boolean = false) {
    articleCollection(where: { slug: $slug }, preview: $isPreview) {
      items {
        title
        slug
        longTitle
        seoTitle
        seoDescription
        heroImage
        heroImageAltText
        maskColour
        articleDetails
        heroColumnLead {
          json
        }
        heroColumnBody {
          json
        }
        contentBlocksCollection(limit: 10) {
          items {
            __typename
            ... on BlockTextRow {
              ...textRowFrag
            }
            ... on BlockPullQuote {
              ...pullQuoteFrag
            }
            ... on BlockImage {
              ...imageFrag
            }
            ... on BlockImageCluster {
              ...imageClusterFrag
            }
            ... on BlockImageGallery {
              ...imageCarouselFrag
            }
            ... on BlockTextColumns {
              ...textColumnsFrag
            }
            ... on BlockTextWithImage {
              ...textWithImageFrag
            }
          }
        }
      }
    }
  }
`;

const imageComponentTypes = [
  "BlockImage",
  "BlockImageCluster",
  "BlockImageGallery",
];

const blockComponent = (component, keyIndex, prevComponent) => {
  switch (component.__typename) {
    case "BlockTextRow":
      const updatedComponent = Object.assign({ dividers: false }, component);
      return (
        <React.Fragment key={keyIndex}>
          {imageComponentTypes.includes(prevComponent.__typename) ? (
            <Spacer short={true} />
          ) : (
            <Spacer />
          )}
          <TextRow data={mapTextRowData(updatedComponent)} />
        </React.Fragment>
      );
    case "BlockPullQuote":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <PullQuote key={keyIndex} data={mapPullQuoteData(component)} />
        </React.Fragment>
      );
    case "BlockImage":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <Grid key={keyIndex}>
            <Box
              sx={{
                gridColumn: [
                  "columns-start / columns-end",
                  "",
                  "",
                  "",
                  "3 / -3",
                ],
              }}
            >
              <ImageBlock data={mapImageData(component)} />
            </Box>
          </Grid>
        </React.Fragment>
      );
    case "BlockImageCluster":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <ImageCluster key={keyIndex} data={mapImageClusterData(component)} />
        </React.Fragment>
      );
    case "BlockImageGallery":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <ImageCarousel
            key={keyIndex}
            data={mapImageCarouselData(component)}
          />
        </React.Fragment>
      );
    case "BlockTextColumns":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <TextColumns data={mapTextColumnsData(component)} />
        </React.Fragment>
      );
    case "BlockTextWithImage":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <TextWithImage data={mapTextWithImageData(component)} />
        </React.Fragment>
      );
    default:
      return null;
  }
};

const Article = ({ isPreview }) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_ARTICLE_BY_SLUG, {
    variables: { slug: slug, isPreview: isPreview },
  });

  const isValidURL = useIsValidURL(
    loading,
    data,
    navigate,
    "articleCollection"
  );

  useDataFetchStatus(loading, error);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (loading === false && isValidURL === true) {
    const articleContent = data.articleCollection.items[0];
    const articleContentContentBlocks =
      data.articleCollection.items[0].contentBlocksCollection.items;

    const heroData = {
      label: "Article",
      longTitle: articleContent.longTitle,
      image: articleContent.heroImage && articleContent.heroImage[0],
      imageAlt: articleContent.heroImageAltText,
      maskColour: articleContent.maskColour,
      heroColumnLead: articleContent.heroColumnLead,
      heroColumnBody: articleContent.heroColumnBody,
      articleDetails: articleContent.articleDetails,
      orientation: "left",
    };

    const seo = {
      title: articleContent.seoTitle
        ? articleContent.seoTitle
        : `${articleContent.title} | Prime Motive`,
      description: articleContent.seoDescription
        ? articleContent.seoDescription
        : articleContent.longTitle,
      url: `https://primemotive.com.au/insights/${articleContent.slug}`,
    };

    return (
      <>
        <SEO title={seo.title} description={seo.description} url={seo.url} />
        <HeroSingleColumn data={heroData} />
        <Box>
          {articleContentContentBlocks.map((item, index) =>
            blockComponent(
              item,
              index,
              index !== 0 && articleContentContentBlocks[index - 1]
            )
          )}
        </Box>
        <Spacer />
      </>
    );
  } else {
    return null;
  }
};

export default Article;

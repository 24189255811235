import { gql } from "@apollo/client";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { Grid } from "../../styles/layout";
import { Heading } from "../shared/typography/Heading";
import RichText from "../shared/richtext/RichText";
import ImageBlock from "../media/ImageBlock";
import { Button } from "../shared/ctas/Button";

export const featureCaseStudyFrag = gql`
  fragment featureCaseStudyFrag on BlockFeatureCaseStudy {
    title
    label
    body {
      json
    }
    image
    imageAltText
    imageMaskColour
    url
  }
`;

export const mapFeatureCaseStudyData = (data) => {
  return {
    title: data.title,
    label: data.label,
    body: data.body,
    image: data.image[0],
    imageAlt: data.imageAltText,
    maskColour: data.imageMaskColour,
    url: data.url,
  };
};

const StyledFeature = styled(Box)`
  label: feature;
  grid-column: columns-start / columns-end;
`;

const FeatureCaseStudy = ({ className = null, as = "p", sx, data }) => {
  return (
    <StyledFeature as="section">
      <Grid label="feature-case-study">
        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              "2 / span 4",
              "3 / span 5",
            ],
            gridRow: ["", "", "1", "2 / span 1"],
            marginTop: ["", "", "40px", "0"],
          }}
        >
          <Box>
            {data.label && (
              <Heading.H4
                caseStudy
                as="h2"
                text={data.label}
                sx={{
                  "&:last-of-type": {
                    marginBottom: ["24px", "", ""],
                  },
                }}
              />
            )}
          </Box>
          {data.title && (
            <Heading.H2
              as="h3"
              text={data.title}
              sx={{
                marginBottom: ["50px", "", ""],
              }}
            />
          )}

          {data.body && (
            <Box sx={{ marginBottom: "50px" }}>
              <RichText content={data.body} />
            </Box>
          )}
          {data.url && (
            <Button.Tertiary
              text="View case study"
              to={data.url}
              sx={{ width: "max-content" }}
            />
          )}
        </Box>

        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              "6 / span 4",
              "span 4 / -3",
            ],
            gridRow: ["1", "", "1 / 2", "2 / 4"],
            lineHeight: 0,
            mb: ["42px", "", 0],
            mt: ["", "", "40px", "0"],
          }}
        >
          <ImageBlock
            data={{
              image: {
                url: data.image ? data.image.public_id : null,
                alt: data.imageAlt,
              },
              maskColour: data.imageMaskColour,
            }}
          />
        </Box>
      </Grid>
    </StyledFeature>
  );
};

export default FeatureCaseStudy;

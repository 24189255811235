import { gql } from "@apollo/client";
import { Flex, Grid, Box } from "theme-ui";
import { Grid as WrappingGrid } from "../../styles/layout";
import { Heading } from "../shared/typography/Heading";
import ImageBlock from "../media/ImageBlock";
import RichText from "../shared/richtext/RichText";
import HorizontalLine from "../shared/decorative/HorizontalLine";
import { Button } from "../shared/ctas/Button";
export const textColumnsFrag = gql`
  fragment textColumnsFrag on BlockTextColumns {
    heading
    sideImage
    sideImageAltText
    textColumnItemsCollection(limit: 8) {
      items {
        heading
        body {
          json
        }
        image
        imageAltText
        imageMaskColour
        ctaText
        ctaDescriptiveLabel
        ctaUrl
      }
    }
  }
`;

export const mapTextColumnsData = (data) => {
  return {
    heading: data.heading,
    sideImage: data.sideImage,
    sideImageAltText: data.sideImageAltText,
    items: data.textColumnItemsCollection.items,
  };
};

const TextColumnItem = ({
  heading,
  body,
  image,
  imageAltText,
  imageMaskColour,
  ctaText,
  ctaUrl,
  ctaDescriptiveLabel,
}) => {
  return (
    <Flex
      mb={["56px", "", "80px"]}
      sx={{
        flexDirection: "column",
      }}
    >
      {image && (
        <Box mb={["42px", "", "", "80px"]}>
          <ImageBlock
            data={{
              image: {
                url: image[0].public_id,
                alt: imageAltText,
              },
            }}
            maskColour={imageMaskColour}
          />
        </Box>
      )}
      <Heading.H4 as={"h3"} text={heading} />
      <RichText content={body} />
      {ctaUrl && (
        <Button.Tertiary
          text={ctaText}
          to={ctaUrl}
          ariaLabel={ctaDescriptiveLabel}
          sx={{ width: "max-content", mt: ["32px", "", "", "48px"] }}
        />
      )}
    </Flex>
  );
};

const TextColumns = ({ data }) => {
  return (
    <>
      <HorizontalLine />
      <WrappingGrid>
        <Heading.H2
          text={data.heading}
          sx={{
            marginBottom: ["80px", "", "100px", "120px"],
            gridColumn: [
              "columns-start / columns-end",
              "",
              "2 / span 6",
              "3 / span 6",
            ],
          }}
        />
        <Grid
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              data.sideImage ? "columns-start / span 4" : "",
              data.sideImage ? "3 / span 7" : "3 / -3",
            ],
            order: 1,
            "@media only screen and (min-width: 768px)": {
              columnGap: "32px",
              order: 0,
            },
            "@media only screen and (min-width: 1500px)": {
              columnGap: "84px",
              paddingRight: data.sideImage ? "50px" : "0px",
            },
          }}
          columns={[
            "1fr",
            "1fr",
            data.sideImage ? "1fr" : "1fr 1fr",
            data.sideImage ? "1fr 1fr" : "1fr 1fr 1fr",
          ]}
        >
          {data.items.map((item, index) => {
            return <TextColumnItem key={index} {...item} />;
          })}
        </Grid>
        {data.sideImage && (
          <Box
            mb={["42px", "", "", "84px"]}
            sx={{
              gridColumn: [
                "columns-start / columns-end",
                "",
                "6 / span 4",
                "10 / span 4",
              ],
            }}
          >
            <ImageBlock
              data={{
                image: {
                  url: data.sideImage[0].public_id,
                  alt: data.sideImageAltText,
                },
              }}
            />
          </Box>
        )}
      </WrappingGrid>
    </>
  );
};

export default TextColumns;

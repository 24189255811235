import { useContext, useEffect } from "react";
import { StoreContext } from "../store/store";

export const useStaticPageStatus = (isStatic) => {
  const { setStaticPage } = useContext(StoreContext);

  useEffect(() => {
    setStaticPage(isStatic);
  }, [isStatic, setStaticPage]);
};

// Components
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { Heading } from "../../components/shared/typography/Heading";
import { Grid } from "../../styles/layout";
import CardGrid from "../../components/shared/grids/CardGrid";
import Spacer from "../../components/utility/Spacer";
import VisuallyHidden from "../../components/shared/typography/VisuallyHidden";
import { Button } from "../../components/shared/ctas/Button";
import { useCustomCursor } from "../../hooks/useCustomCursor";

const StyledCaseStudies = styled(Box)`
  label: case-studies-home-page;
`;

const HomeCaseStudies = (props) => {
  const { caseStudies } = props.data;
  const [ref, handleMouseEnter, handleMouseLeave] = useCustomCursor(
    "View\n Case\n Study",
    "default"
  );

  return (
    <StyledCaseStudies
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      as="section"
    >
      <VisuallyHidden>
        <Grid>
          <Heading.H2
            sx={{
              gridColumn: ["columns-start / columns-end"],
              marginBottom: "24px",
            }}
            text="Work"
          />
        </Grid>
      </VisuallyHidden>
      <Box ref={ref}>
        <CardGrid
          text={[
            "Our work reflects the power of collaboration and the pursuit of excellence, driven by the dedication of visionary organisations that want to bring a more prosperous future to life.",
          ]}
          label={"Work"}
          data={caseStudies}
        />
      </Box>
      <Grid label="work-link">
        <Button.Primary
          text="More work"
          to="/work"
          ariaLabel="Visit our recent work page"
          sx={{
            gridColumn: [
              "span 3 / columns-end",
              "",
              "span 4 / 10",
              "span 3 / 10",
            ],
            marginTop: ["", "", "50px"],
            maxWidth: ["", "", "", "272px"],
          }}
        />
      </Grid>
      <Spacer sx={{ height: ["200px", "", "250px", "350px"] }} />
    </StyledCaseStudies>
  );
};

export default HomeCaseStudies;

import React from "react";
import { Helmet } from "react-helmet-async";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const drawLine = keyframes`
  0% {
    right: 100%;
  }
  70%, 100% {
    right: 0%;
  }
`;

const contractLine = keyframes`
  0%, 15% {
    left: 0%;
  }
  85%, 100% {
    left: 100%;
  }
`;

const StyledLineLoader = styled.div`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  overflow-y: scroll;
  overflow-x: hidden;
  pointer-events: none;
  .line {
    width: 150px;
    position: relative;
    span {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0%;
      right: 100%;
      height: 0.75em;
      opacity: 1;
      background-color: ${(props) => props.theme.colors.blue};
      animation: ${drawLine} 1s 0.5s 3 cubic-bezier(0.39, 0.58, 0.57, 1)
          forwards,
        ${contractLine} 1s 0.5s 3 cubic-bezier(0.55, 0.09, 0.68, 0.53) forwards;
    }
  }
`;

const LineLoader = ({ loaderBgColor, lineColor }) => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Prime Motive is a Strategic Design and Research company based in Melbourne. We combine Strategic Design, Human-Centred Design and Design Thinking to deliver impact, growth, and lasting engagement between people and organisations."
        />
      </Helmet>
      <StyledLineLoader bgColor={loaderBgColor} lineColor={lineColor}>
        <div className="line">
          <span></span>
        </div>
      </StyledLineLoader>
    </>
  );
};

export default LineLoader;

import styled from "@emotion/styled";
import useEmblaCarousel from "embla-carousel-react";
import { gql } from "@apollo/client";
import { Box } from "theme-ui";
import { Heading } from "../shared/typography/Heading";
import { Grid } from "../../styles/layout";
import RichText from "../shared/richtext/RichText";
import ImageBlock from "../media/ImageBlock";
import HorizontalLine from "../shared/decorative/HorizontalLine";
import { Button } from "../shared/ctas/Button";

// This should be the only place to add/edit/delete the fields for this component
// Import this fragment into your template where you are using this component.
export const contentCarouselFrag = gql`
  fragment contentCarouselFrag on BlockContentCarousel {
    heading
    contentItemsCollection(limit: 8) {
      items {
        heading
        body {
          json
        }
        image
        imageAltText
        imageMaskColour
        ctaText
        ctaDescriptiveLabel
        ctaUrl
      }
    }
  }
`;

export const mapContentCarouselData = (data) => {
  return {
    heading: data.heading,
    items: data.contentItemsCollection.items,
  };
};

const StyledContentCarousel = styled.section``;

const StyledCarousel = styled(Box)`
  display: flex;
  cursor: grab;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
`;

const carouselStyles = {
  margin: ["0 -20px", "0 -23px", "", "0 -40px"],
};

const carouselItemStyles = {
  position: "relative",
  display: "grid",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "min-content max-content",
  flex: ["0 0 90%", "1 0 60%", "", "1 0 36%"],
  padding: ["0 20px", "0 23px", "", "0 40px"],
  justifyContent: "center",

  ".image-container": {
    height: "100%",
    width: "100%",
    lineHeight: 0,
    position: "absolute",
    top: "0",
    marginRight: "20px",
    img: {
      objectFit: "cover",
      objectPosition: "center",
      width: "100%",
      height: "100%",
    },
  },
  "&:nth-of-type(3n + 1)": {
    ".image-container": {
      paddingTop: ["30px", "", "", "50px"],
      img: {
        objectPosition: "center 45%",
      },
    },
  },
  "&:nth-of-type(3n + 2)": {
    ".image-container": {
      paddingTop: ["60px", "", "", "100px"],
      img: {
        objectPosition: "center 45%",
      },
    },
  },
  ".image-display-mask": {
    position: "relative",
    paddingTop: "120%",
    overflow: "hidden",
  },
};

const ContentCarousel = ({ data }) => {
  const [emblaRef] = useEmblaCarousel({
    align: "start",
    containScroll: "keepSnaps",
  });

  const carouselItems = data.items;

  return (
    <StyledContentCarousel as="section">
      <HorizontalLine />
      <Grid label="content-carousel">
        <Heading.H2
          text={data.heading}
          sx={{
            marginBottom: ["56px", "", "80px", "120px"],
            gridColumn: [
              "columns-start / columns-end",
              "",
              "2 / span 6",
              "2 / span 6",
            ],
          }}
        />
      </Grid>
      <Grid sx={{ overflow: "hidden" }}>
        <Box
          sx={{
            gridColumn: ["columns-start / columns-end", "", "3 / columns-end"],
          }}
        >
          <Box ref={emblaRef}>
            <StyledCarousel sx={{ ...carouselStyles }}>
              {carouselItems.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    ...carouselItemStyles,
                  }}
                >
                  <Box
                    sx={{
                      gridRow: "1",
                      alignSelf: "flex-end",
                      lineHeight: "0",
                    }}
                    className="image-display-mask"
                  >
                    <Box className="image-container">
                      <ImageBlock
                        className="carousel-image"
                        data={{
                          image: {
                            url:
                              item && item.image
                                ? item.image[0].public_id
                                : null,
                            alt: item.imageAltText,
                          },
                          maskColour: item.imageMaskColour,
                        }}
                        transform={{ width: 534, height: 657 }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ gridRow: "2", height: "fit-content" }}>
                    <Box sx={{ height: "fit-content" }}>
                      <Heading.H4
                        as="h3"
                        sx={{ my: "28px" }}
                        text={item.heading}
                      />
                      <Box sx={{ marginBottom: "40px" }}>
                        <RichText content={item.body} />
                      </Box>
                      {item.ctaUrl && (
                        <Button.Secondary
                          text={item.ctaText}
                          to={item.ctaUrl}
                          ariaLabel={item.ctaDescriptiveLabel}
                          sx={{ maxWidth: ["", "", "272px"] }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              ))}
            </StyledCarousel>
          </Box>
        </Box>
      </Grid>
    </StyledContentCarousel>
  );
};

export default ContentCarousel;

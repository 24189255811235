import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box } from "theme-ui";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { Grid } from "../../styles/layout";
import Error from "../../pages/Error/Error";
import Loading from "../../components/global/Loader";
import CardGrid from "../../components/shared/grids/CardGrid";
import Spacer from "../../components/utility/Spacer";
import { Heading } from "../../components/shared/typography/Heading";
import HorizontalLine from "../../components/shared/decorative/HorizontalLine";
import UpdateOnScroll from "../../components/utility/UpdateOnScroll";
import { useDataFetchStatus } from "../../hooks/useDataFetchStatus";
import { useIsValidURL } from "../../hooks/useIsValidURL";
import HeroWithColumns from "../../components/heros/HeroWithColumns";
import SEO from "../../components/global/SEO";

import PullQuote, {
  mapPullQuoteData,
  pullQuoteFrag,
} from "../../components/content/PullQuote";
import ImageBlock, {
  mapImageData,
  imageFrag,
} from "../../components/media/ImageBlock";
import FeatureCaseStudy, {
  mapFeatureCaseStudyData,
  featureCaseStudyFrag,
} from "../../components/content/FeatureCaseStudy";
import TextWithImage, {
  textWithImageFrag,
  mapTextWithImageData,
} from "../../components/content/TextWithImage";
import TextColumns, {
  mapTextColumnsData,
  textColumnsFrag,
} from "../../components/content/TextColumns";

const GET_SECTOR_BY_SLUG = gql`
  ${imageFrag}
  ${pullQuoteFrag}
  ${featureCaseStudyFrag}
  ${textColumnsFrag}
  ${textWithImageFrag}
  query SectorPage($slug: String!, $isPreview: Boolean = false) {
    sectorCollection(where: { slug: $slug }, preview: $isPreview) {
      items {
        title
        slug
        longTitle
        seoTitle
        seoDescription
        heroImage
        heroImageAltText
        heroImageMaskColour
        heroColumn1 {
          json
        }
        heroColumn2 {
          json
        }
        contentBlocksCollection(limit: 6) {
          items {
            __typename
            ... on BlockTextColumns {
              ...textColumnsFrag
            }
            ... on BlockFeatureCaseStudy {
              ...featureCaseStudyFrag
            }
            ... on BlockImage {
              ...imageFrag
            }
            ... on BlockPullQuote {
              ...pullQuoteFrag
            }
            ... on BlockTextWithImage {
              ...textWithImageFrag
            }
          }
        }
        contentBlocksBottomSectionCollection(limit: 4) {
          items {
            __typename
            ... on BlockTextColumns {
              ...textColumnsFrag
            }
            ... on BlockFeatureCaseStudy {
              ...featureCaseStudyFrag
            }
            ... on BlockImage {
              ...imageFrag
            }
            ... on BlockPullQuote {
              ...pullQuoteFrag
            }
            ... on BlockTextWithImage {
              ...textWithImageFrag
            }
          }
        }
        insightsHeading
        insightsCollection(limit: 4) {
          items {
            title
            label
            image
            url
          }
        }
      }
    }
  }
`;

const blockComponent = (component, keyIndex, prevComponent, dividers) => {
  switch (component.__typename) {
    case "BlockPullQuote":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <PullQuote key={keyIndex} data={mapPullQuoteData(component)} />
        </React.Fragment>
      );
    case "BlockImage":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <Grid key={keyIndex}>
            <Box sx={{ gridColumn: "columns-start / columns-end" }}>
              <ImageBlock data={mapImageData(component)} />
            </Box>
          </Grid>
        </React.Fragment>
      );
    case "BlockFeatureCaseStudy":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <FeatureCaseStudy
            key={keyIndex}
            data={mapFeatureCaseStudyData(component)}
          />
        </React.Fragment>
      );
    case "BlockTextColumns":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <TextColumns data={mapTextColumnsData(component)} />
        </React.Fragment>
      );
    case "BlockTextWithImage":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <TextWithImage data={mapTextWithImageData(component)} />
        </React.Fragment>
      );
    default:
      return null;
  }
};

const Sector = ({ slugAsProp, isPreview }) => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(GET_SECTOR_BY_SLUG, {
    variables: { slug: slug || slugAsProp, isPreview: isPreview },
  });

  const isValidURL = useIsValidURL(loading, data, navigate, "sectorCollection");

  useDataFetchStatus(loading, error);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (loading === false && isValidURL === true) {
    const sector = data.sectorCollection.items[0];
    const topContent = sector.contentBlocksCollection.items;
    const bottomContent = sector.contentBlocksBottomSectionCollection.items;
    const insights = sector.insightsCollection.items;

    const heroData = {
      label: "Sector",
      longTitle: sector.longTitle,
      image: sector.heroImage && sector.heroImage[0],
      imageAltText: sector.heroImageAltText,
      maskColour: sector.heroImageMaskColour,
      heroColumn1: sector.heroColumn1,
      heroColumn2: sector.heroColumn2,
      imagePosition: "left",
      textAlignment: "top",
    };

    const seo = {
      title: sector.seoTitle
        ? sector.seoTitle
        : `${sector.title} | Prime Motive`,
      description: sector.seoDescription
        ? sector.seoDescription
        : sector.longTitle,
      url: `https://primemotive.com.au/${sector.slug}`,
    };

    return (
      <>
        <SEO title={seo.title} description={seo.description} url={seo.url} />
        <HeroWithColumns data={heroData} />
        {topContent.length > 0 && (
          <Box>
            {topContent.map((item, index) =>
              blockComponent(
                item,
                index,
                index !== 0 && topContent[index - 1],
                false
              )
            )}
          </Box>
        )}

        {(bottomContent.length > 0 ||
          sector.insightsCollection.items.length > 0) && (
          <>
            <UpdateOnScroll.Sentinel />
            {bottomContent &&
              bottomContent.map((item, index) =>
                blockComponent(
                  item,
                  index,
                  index !== 0 && bottomContent[index - 1],
                  false
                )
              )}
            {insights.length > 0 && (
              <>
                <Spacer />
                <HorizontalLine />
                <Grid>
                  <Heading.H2
                    text={
                      sector.insightsHeading
                        ? sector.insightsHeading
                        : "Insights and past work"
                    }
                    sx={{
                      marginBottom: ["70px", "", "", "150px"],
                      gridColumn: [
                        "columns-start / columns-end",
                        "",
                        "columns-start / span 5",
                        "5 / span 4",
                      ],
                    }}
                  />
                </Grid>
                <CardGrid data={sector.insightsCollection} />
              </>
            )}
          </>
        )}
        <Spacer />
      </>
    );
  } else {
    return null;
  }
};

export default Sector;

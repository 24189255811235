import { useState, useEffect } from "react";

// export const useIsValidURL = (loading, data, navigate, collectionName) => {
//   const [isValidURL, setIsValidURL] = useState();

//   console.log("useIsValidURL");

//   useEffect(() => {
//     if (!loading) {
//       setIsValidURL(
//         data && data[collectionName] && data[collectionName].items.length > 0
//       );
//     }
//   }, [data, loading, collectionName]);

//   useEffect(() => {
//     if (isValidURL === false) {
//       navigate("/page-not-found");
//     }
//   }, [navigate, isValidURL]);

//   return isValidURL;
// };

export const useIsValidURL = (loading, data, navigate, collectionName) => {
  const [isValidURL, setIsValidURL] = useState();

  useEffect(() => {
    if (!loading) {
      const isValidData =
        data && data[collectionName] && data[collectionName].items.length > 0;
      setIsValidURL(isValidData);

      if (!isValidData) {
        navigate("/page-not-found");
      }
    }
  }, [data, loading, collectionName, navigate]);

  return isValidURL;
};

const debounce = (func, wait = 5, immediate = false) => {
  let timeout;
  return (...rest) => {
    const context = this;
    const args = rest;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

const throttle = (func, wait = 5) => {
  let time = Date.now();
  return function () {
    if (time + wait - Date.now() < 0) {
      func();
      time = Date.now();
    }
  };
};

// https://stackoverflow.com/a/1054862/13316662
const slugify = (string) => {
  return string
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
};

const isNumber = (value) => {
  if (typeof value === "string") {
    return !isNaN(value);
  }
};

const isExternalLink = (url) => {
  const pattern = new RegExp("^(http|https)://");
  return pattern.test(url);
};

export { debounce, throttle, slugify, isNumber, isExternalLink };

import TextBlock from "../typography/TextBlock";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { RICHTEXT_OPTIONS, RICHTEXT_OPTIONS_LARGE } from "./Options";

const RichText = ({ content, isLarge = false }) => {
  if (content && content.json !== undefined) {
    return documentToReactComponents(
      content.json,
      isLarge ? RICHTEXT_OPTIONS_LARGE : RICHTEXT_OPTIONS
    );
  } else {
    return (
      content &&
      content.map((line, index) => (
        <TextBlock
          key={index}
          dangerouslySetInnerHTML={{
            __html: line,
          }}
          sx={{
            a: {
              color: "red",
              "&:hover": {
                textDecoration: "none",
              },
            },
          }}
          variant={isLarge ? "text.body.large" : "text.body.regular"}
        />
      ))
    );
  }
};

export default RichText;

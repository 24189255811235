export const forms = {
  label: {
    fontSize: ["20px", "", "23px"],
  },
  input: {
    position: "relative",
    fontSize: ["20px", "", "23px"],
    width: "100%",
    bg: "inherit",
    color: "blue",
    padding: "0.5em 0",
    borderRadius: "0px",
    borderColor: "transparent",
  },
};

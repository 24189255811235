import { Box } from "theme-ui";
import { Grid } from "../../../styles/layout";
import RichText from "../../shared/richtext/RichText";

const SummaryInDetail = ({ data }) => (
  <Grid label="summary-in-detail">
    <Box
      sx={{
        gridColumn: [
          "columns-start / columns-end",
          "",
          "3 / span 6",
          "4 / span 8",
        ],
      }}
    >
      <RichText content={data} isLarge={true} />
    </Box>
  </Grid>
);

export default SummaryInDetail;

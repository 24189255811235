export const heading = "Let's talk about what's next.";

export const formSectionText = {
  heading: "Get in touch",
  text: "Interested in talking about a project or understanding what it’s like working with our team? Submit your details so we can contact you.",
};

export const validationMessage = {
  fullname: "*Name required",
  email: "*Email required",
  custom: "*Organisation required",
};

export const selectOptions = [
  "Learn more about us",
  "Talk about an upcoming project",
  "Job enquiries",
  "Media enquiries",
  "General enquiries",
];

export const formSubmitMessage =
  "Thank you! Your submission has been received.";

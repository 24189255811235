import { useContext, forwardRef } from "react";
import { CloundinaryContext } from "../../../context/cloudinary";
import { AdvancedImage } from "@cloudinary/react";
import { fill } from "@cloudinary/url-gen/actions/resize";

// New SDK reference:
// https://cloudinary.com/documentation/react_quick_start
// https://cloudinary.com/documentation/react1_migration_guide

const CloudinaryImage = forwardRef(
  (
    {
      quality = "auto",
      format = "auto",
      compressed = true,
      src,
      alt,
      className,
      onLoad,
      transform = false,
    },
    ref
  ) => {
    const cloud = useContext(CloundinaryContext);
    const image = compressed
      ? transform !== false
        ? cloud
            .image(src)
            .quality(quality)
            .format(format)
            .resize(fill().width(transform.width).height(transform.height))
        : cloud.image(src).quality(quality).format(format)
      : cloud.image(src);
    return (
      <AdvancedImage
        ref={ref}
        width="400"
        height="400"
        className={className}
        cldImg={image}
        alt={alt}
        onLoad={onLoad}
      />
    );
  }
);

export default CloudinaryImage;

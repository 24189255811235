// Reference: https://theme-ui.com/theme-spec#typography
export const typography = {
  // Base
  fontWeights: {
    body: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  // Variants
  text: {
    heading: {
      h1: {
        // Old fluid typography, todo: compare against new
        // fontSize: "clamp(2.25rem, 1.4712rem + 3.4615vw, 5.625rem)",
        // lineHeight: "0.8925",
        // minHeight: "0vw",
        fontSize: ["38px", "", "50px", "73px", "80px"],
        lineHeight: "0.96em",
        fontWeight: "body",
      },
      h2: {
        // Old fluid typography, todo: compare against new
        // fontSize: "clamp(2rem, 1.4624rem + 2.6880vw, 4.688rem)",
        // lineHeight: "1",
        fontSize: ["36px", "", "44px", "62px", "75px"],
        lineHeight: "0.96em",
        fontWeight: "body",
      },
      h3: {
        // Old
        // fontSize: ["18px", "", "", "22px"],
        // lineHeight: ["26px", "", "", "33px"],
        fontSize: ["35px", "", "44px", "70px", ""],
        lineHeight: "0.96em",
        fontWeight: "body",
      },
      h4: {
        // Old
        // fontWeight: "600",
        // fontSize: "20px",
        // lineHeight: "32px",
        fontSize: ["22px", "", "", "30px", ""],
        lineHeight: "1.4em",
        fontWeight: "body",
        marginBottom: ["32px", "", "", "40px", ""],
      },
    },
    cta: {
      large: {
        fontSize: ["17px", "", "20px", "22px", "24px"],
        lineHeight: ["25px", "", "28px", "30px", "32px"],
        "&:last-of-type": {
          marginBottom: "0",
        },
      },
      regular: {
        fontSize: ["17px", "", "18px", "19px", "20px"],
        lineHeight: ["25px", "", "26px", "26px", "28px"],
        "&:last-of-type": {
          marginBottom: "0",
        },
      },
      small: {
        fontSize: ["16px", "", "", "18px", ""],
        lineHeight: ["24px", "", "", "26px", ""],
        "&:last-of-type": {
          marginBottom: "0",
        },
      },
    },
    link: {
      regular: {
        fontSize: ["17px", "", "18px", "19px", "20px"],
        lineHeight: ["25px", "", "26px", "27px", "28px"],
        "&:last-of-type": {
          marginBottom: "0",
        },
      },
    },
    body: {
      large: {
        fontSize: ["22px", "22px", "", "30px"],
        lineHeight: ["28px", "33px", "", "45px"],
        marginBottom: ["32px", "", ""],
        "&:last-of-type": {
          marginBottom: "0",
        },
      },
      regular: {
        // backgroundColor: ["red", "aqua", "green", "purple", "gold"],
        fontSize: ["17px", "", "18px", "19px", "20px"],
        lineHeight: ["25px", "", "26px", "27px", "28px"],
        marginBottom: "24px",
        "&:last-of-type": {
          marginBottom: "0",
        },
      },
      small: {
        fontSize: ["16px", "", "", "18px"],
        lineHeight: ["24px", "", "", "26px"],
        marginBottom: "24px",
        "&:last-of-type": {
          marginBottom: "0",
        },
      },
      xSmall: {
        fontSize: ["14px", "", "", "16px", ""],
        lineHeight: ["22px", "", "", "24px", ""],
        marginBottom: "24px",
        "&:last-of-type": {
          marginBottom: "0",
        },
      },
    },
    ul: {
      richText: {
        listStyle: "disc",
        li: {
          listStylePosition: "outside",
          marginLeft: ["1.25em", "", "1.25em", "1.5em"],
          "&::marker": {
            fontSize: ["1.125em", "", "1.25em", "1.5em"],
            color: "blue",
          },
          p: { display: "inline" },
        },
      },
    },
    ol: {
      richText: {
        paddingLeft: "1em",
        li: {
          listStylePosition: "outside",
          marginLeft: ["1.25em", "", "1.25em", "0"],
          "&::marker": {
            fontSize: "1.2em",
            color: "blue",
          },
          p: { display: "inline" },
        },
      },
    },
  },
};

import Home from "../pages/Home/Home";
import CaseStudies from "../pages/CaseStudies/CaseStudies";
import CaseStudy from "../pages/CaseStudies/CaseStudy";
import Insights from "../pages/Insights/Insights";
// import Service from "../pages/Services/Service";
// import Sector from "../pages/Sectors/Sector";
import GeneralContent from "../pages/GeneralContent/GeneralContent";
import Article from "../pages/Insights/Article";
import Contact from "../pages/Contact/Contact";
import PageNotFound from "../pages/404/404";
import Error from "../pages/Error/Error";
// GeneralContent Custom Pages
import Approach from "../pages/Custom/Approach";
// import Careers from "../pages/Custom/Careers";
import PrivacyTerms from "../pages/Custom/Privacy";
import SectorsLandingPage from "../pages/Sectors/Sectors";
const { REACT_APP_PREVIEW } = process.env;

export const routes = [
  {
    path: "/error",
    component: <Error />,
    public: true,
  },
  {
    path: "/work/:slug",
    component: <CaseStudy isPreview={REACT_APP_PREVIEW === "true"} />,
    public: true,
  },
  {
    path: "/work",
    component: <CaseStudies isPreview={REACT_APP_PREVIEW === "true"} />,
    public: true,
  },
  {
    path: "/insights",
    component: <Insights isPreview={REACT_APP_PREVIEW === "true"} />,
    public: true,
  },
  {
    path: "/sectors",
    component: <SectorsLandingPage isPreview={REACT_APP_PREVIEW === "true"} />,
    public: true,
  },
  {
    path: "/insights/:slug",
    component: <Article isPreview={REACT_APP_PREVIEW === "true"} />,
    public: true,
  },
  // {
  //   path: "/sectors/:slug",
  //   component: <Sector isPreview={REACT_APP_PREVIEW === "true"} />,
  //   public: true,
  // },
  // {
  //   path: "/services/:slug",
  //   component: <Service isPreview={REACT_APP_PREVIEW === "true"} />,
  //   public: true,
  // },
  {
    path: "/contact",
    component: <Contact isPreview={REACT_APP_PREVIEW === "true"} />,
    public: true,
  },
  {
    path: "/approach",
    component: <Approach isPreview={REACT_APP_PREVIEW === "true"} />,
    public: true,
  },
  // {
  //   path: "/careers",
  //   component: <Careers isPreview={REACT_APP_PREVIEW === "true"} />,
  //   public: true,
  // },
  {
    path: "/privacy",
    component: <PrivacyTerms isPreview={REACT_APP_PREVIEW === "true"} />,
    public: true,
  },
  {
    path: "/page-not-found",
    component: <PageNotFound />,
    public: true,
  },
  {
    path: "/:slug",
    component: <GeneralContent isPreview={REACT_APP_PREVIEW === "true"} />,
    public: true,
  },
  {
    path: "/",
    component: <Home isPreview={REACT_APP_PREVIEW === "true"} />,
    public: true,
  },
  {
    path: "*",
    component: <PageNotFound />,
    public: true,
  },
];

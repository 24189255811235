import { useForm } from "react-hook-form";
import { Box } from "theme-ui";
import { Grid } from "../../styles/layout";
import { Heading } from "../../components/shared/typography/Heading";
import TextBlock from "../../components/shared/typography/TextBlock";
import { InputField } from "../../components/shared/forms/Input";
import { Button } from "../../components/shared/ctas/Button";
import {
  formSectionText,
  validationMessage,
  selectOptions,
  formSubmitMessage,
} from "./FormContent";
import HiddenFormInputs from "./HiddenInputs";

const inputFieldStyles = {
  marginBottom: "35px",
};

const lastInputFieldStyles = {
  marginBottom: ["35px", "", "", 0],
};

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, isSubmitting, errors },
  } = useForm();
  const onSubmit = async (data) => {
    try {
      // Need to rename a few fields to get this to work with activecampaign
      let preppedData = {
        "field[7]": data.message,
        "field[8]": data.subject,
        "field[1]": data.custom,
        ...data,
      };

      // Remove the old custom field (renamed above)
      const { custom, ...cleaned } = preppedData;

      // Convert to FormData
      let form_data = new FormData();
      for (let key in cleaned) {
        if (process.env.NODE_ENV !== "production") {
          console.log("in for loop", key, cleaned[key]);
        }
        form_data.append(key, cleaned[key]);
      }

      // Iterate through form_data to check data
      for (var pair of form_data.entries()) {
        if (process.env.NODE_ENV !== "production") {
          console.log(pair[0] + ", " + pair[1]);
        }
      }

      fetch("https://primemotive.activehosted.com/proc.php", {
        method: "POST",
        mode: "no-cors",
        cache: "no-cache",
        body: form_data,
      });
    } catch (error) {
      console.log("Request failed", error);
    }
  };

  return (
    <Grid id="contact-form" label="contact-form">
      <Box
        sx={{
          gridColumn: [
            "columns-start / columns-end",
            "",
            "2 / span 4",
            "3 / span 3",
            "3 / span 3",
          ],
          marginBottom: ["56px", "", 0],
        }}
      >
        <Heading.H2
          text={formSectionText.heading}
          sx={{ marginBottom: ["48px", "", "", "56px"], whiteSpace: "nowrap" }}
        />
        <TextBlock>{formSectionText.text}</TextBlock>
      </Box>
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{
          display: "contents",
          gridColumn: ["1 / span 6", "", "6 / span 4", "7 / span 7"],
          "input, textarea, select": {
            outline: "none",
          },
        }}
      >
        <HiddenFormInputs formId="7" register={register} />
        {isSubmitSuccessful ? (
          <Box
            sx={{
              display: "flex",
              gridColumn: [
                "columns-start / columns-end",
                "",
                "6 / span 4",
                "7 / span 7",
              ],
              alignItems: "center",
              justifyContent: "center",
              padding: "40px 16px",
              backgroundColor: "greyLight", //Temporary colour
            }}
          >
            <TextBlock sx={{ textAlign: "center" }}>
              {formSubmitMessage}
            </TextBlock>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                gridColumn: [
                  "columns-start / columns-end",
                  "",
                  "6 / span 4",
                  "7 / span 3",
                ],
              }}
            >
              <InputField.Standard
                id="fullname"
                name="fullname"
                label="Your name"
                htmlFor="fullname"
                register={register}
                validationRules={{ maxLength: 20 }}
                validationMessage={validationMessage.fullname}
                errors={errors && errors.fullname}
                required
                sx={{ ...inputFieldStyles }}
              />
              <InputField.Standard
                id="email"
                name="email"
                label="Email address"
                htmlFor="email"
                register={register}
                validationRules={{
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "*Please enter a valid email address",
                  },
                }}
                validationMessage={validationMessage.email}
                errors={errors && errors.email}
                required
                sx={{ ...inputFieldStyles }}
              />
              <InputField.Standard
                id="custom"
                name="custom"
                label="Organisation"
                htmlFor="custom"
                register={register}
                validationRules={{ maxLength: 20 }}
                validationMessage={validationMessage.custom}
                errors={errors && errors.custom}
                required
                sx={{ ...inputFieldStyles }}
              />
              {/* Validation for contact number? */}
              <InputField.Standard
                id="phone"
                name="phone"
                label="Phone number"
                htmlFor="phone"
                register={register}
                sx={{ ...lastInputFieldStyles }}
              />
            </Box>
            <Box
              sx={{
                gridColumn: [
                  "columns-start / columns-end",
                  "",
                  "6 / span 4",
                  "10 / span 3",
                ],
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <InputField.Dropdown
                id="subject"
                name="subject"
                label="Subject"
                htmlFor="Subject"
                selectOptions={selectOptions}
                register={register}
                sx={{ ...inputFieldStyles }}
              />
              <InputField.TextArea
                id="message"
                name="message"
                placeholder="Write a message here..."
                label="Message"
                htmlFor="message"
                rows={8}
                register={register}
              />
            </Box>
            <Box
              sx={{
                gridColumn: [
                  "columns-start / columns-end",
                  "columns-start / span 2",
                  "6 / span 3",
                  "7 / span 2",
                ],
                marginTop: ["48px", "", "56px"],
              }}
            >
              <Button.Primary
                id="_form_7_submit"
                type="submit"
                text={isSubmitting ? "Submitting..." : "Submit"}
                sx={{ minWidth: ["100%", "272px"] }}
              />
            </Box>
          </>
        )}
      </Box>
    </Grid>
  );
};

export default ContactForm;

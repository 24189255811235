import styled from "@emotion/styled";
import SVG from "react-inlinesvg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { NavLink } from "react-router-dom";
const StyledLogoWrapper = styled.div`
  position: absolute;
  padding-top: 15px;
  line-height: 0;
  @media only screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    padding-top: 20px;
  }
  .anim-box {
    position: relative;
  }
  &.blue {
    svg {
      color: ${(props) => props.theme.colors.white};
    }
  }
  &.red,
  &.yellow {
    svg {
      color: ${(props) => props.theme.colors.blue};
    }
  }
  &.red-on-blue {
    svg {
      color: ${(props) => props.theme.colors.red};
    }
  }
`;

const StyledNavyLogoWrapper = styled.div`
  padding-top: 15px;
  @media only screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    padding-top: 20px;
  }
  .anim-box {
    position: relative;
  }
  svg {
    color: ${(props) => props.theme.colors.blue};
  }
`;

const StyledLogo = styled(SVG)`
  position: relative;
  width: 40px;
  height: 40px;
  color: ${(props) => props.theme.colors.blue};
  transition: color 650ms ease-in-out;
  @media only screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 60px;
    height: 60px;
  }
  @media only screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 80px;
    height: 80px;
  }
`;
const StyledNavLinkInvisible = styled(NavLink)`
  opacity: 0;
  &:focus-visible {
    opacity: 1;
  }
`;

// https://stackoverflow.com/questions/62643362/how-to-change-header-logo-in-specific-page-in-reactjs
// For the different logo on home page
// https://www.framer.com/docs/animate-presence/

const Logo = ({ bgColor = null, location }) => {
  const variants = {
    initial: {
      opacity: 0,
      transition: { duration: 0.5 },
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: { delay: 0.1, duration: 0.5 },
    },
    exit: {
      opacity: 0,
      scale: 0,
      transition: { duration: 0.5 },
    },
  };

  return (
    <StyledLogoWrapper className={`${bgColor}`}>
      {location === "/" ? (
        <StyledNavLinkInvisible to="/" end title="Home">
          <StyledLogo src="/assets/svg/logo-navy.svg" />
        </StyledNavLinkInvisible>
      ) : (
        <motion.div
          className="anim-box"
          initial="exit"
          animate="animate"
          exit="exit"
          variants={variants}
        >
          <NavLink to="/" end title="Home">
            <StyledLogo src="/assets/svg/logo-navy.svg" />
          </NavLink>
        </motion.div>
      )}
    </StyledLogoWrapper>
  );
};

export const NavyLogo = () => {
  const [logoRef, logoView] = useInView({
    threshold: 1,
    triggerOnce: true,
  });
  return (
    <StyledNavyLogoWrapper sx={{ opacity: logoView ? 1 : 0 }} ref={logoRef}>
      <StyledLogo src="/assets/svg/logo-navy.svg" />
    </StyledNavyLogoWrapper>
  );
};

export default Logo;

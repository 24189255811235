import { Box } from "theme-ui";
import SVG from "react-inlinesvg";
import { useInView } from "react-intersection-observer";
import { Grid } from "../../../styles/layout";
import TextBlock from "../../shared/typography/TextBlock";
import RichText from "../../shared/richtext/RichText";
import VerticalText from "../../shared/decorative/VerticalText";

const verticalTextStyles = {
  position: "absolute",
  display: ["none", "", "block"],
  gridColumn: ["", "", "2 / columns-end", "2 / columns-end"],
};

const headingStyles = {
  marginBottom: ["25px", "", "", "50px"],
  lineHeight: ["26px", "", "", "33px"],
  color: "red",
};

const inBriefVerticalTextStyles = {
  lineHeight: ["26px", "", "27px", "", "28px"],
  fontSize: ["18px", "", "19px", "", "20px"],
};

const columnOneStyles = {
  gridColumn: ["columns-start / columns-end", "", "3 / span 7", "4 / span 3"],
  marginBottom: ["50px", "", "", 0],
};

const columnTwoStyles = {
  gridColumn: ["columns-start / columns-end", "", "3 / span 7", "7 / span 3"],
  marginBottom: ["50px", "", "", 0],
};

const columnThreeStyles = {
  gridColumn: ["columns-start / columns-end", "", "3 / span 7", "10 / span 3"],
  marginBottom: ["50px", "", "", 0],
};

const SummaryInBrief = ({ data }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  return (
    <Grid label="summary-in-brief" sx={{ position: "relative" }}>
      <Box sx={{ ...verticalTextStyles }}>
        <VerticalText
          sx={{
            marginBottom: "28px",
            ...inBriefVerticalTextStyles,
          }}
        >
          In Brief
        </VerticalText>
        <Box
          as="a"
          href="#summary-in-detail"
          onClick={(event) => {
            event.preventDefault();
            const element = document.querySelector("#summary-in-detail");
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
          sx={{
            textDecoration: "none",
            color: "greyDark",
            "&:hover, &:focus": {
              color: "red",
            },
          }}
        >
          <VerticalText
            color="inherit"
            sx={{
              margin: 0,
              ...inBriefVerticalTextStyles,
            }}
          >
            In Detail
          </VerticalText>
          <Box
            ref={ref}
            sx={{
              display: "flex",
              paddingTop: ["16px", "", "", "24px"],
              svg: {
                width: ["13px", "", "14px", "16px"],
                height: ["13px", "", "14px", "16px"],
                opacity: inView ? 1 : 0,
                transition:
                  "opacity 400ms 300ms ease-in-out, color 400ms 100ms ease-in-out",
              },
            }}
          >
            <SVG src={`/assets/svg/shared/arrow-down-left.svg`} />
          </Box>
        </Box>
      </Box>
      <Box sx={{ ...columnOneStyles }}>
        <Box sx={{ marginBottom: ["25px", "", "", "50px"] }}>
          <TextBlock
            semiBold
            as="h2"
            sx={{ ...headingStyles }}
            text="Challenge"
          />
        </Box>
        <RichText content={data.challenge} />
      </Box>
      <Box sx={{ ...columnTwoStyles }}>
        <Box sx={{ marginBottom: ["25px", "", "", "50px"] }}>
          <TextBlock
            semiBold
            as="h2"
            sx={{ ...headingStyles }}
            text="Outcomes"
          />
        </Box>
        <RichText content={data.outcomes} />
      </Box>
      <Box sx={{ ...columnThreeStyles }}>
        <Box sx={{ marginBottom: ["25px", "", "", "50px"] }}>
          <TextBlock
            semiBold
            as="h2"
            sx={{ ...headingStyles }}
            text="How it was done"
          />
        </Box>
        <RichText content={data.howItWasDone} />
      </Box>
    </Grid>
  );
};

export default SummaryInBrief;

import React from "react";
import { Box } from "theme-ui";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { Grid } from "../../styles/layout";
import Error from "../Error/Error";
import Loading from "../../components/global/Loader";
import Spacer from "../../components/utility/Spacer";
import HeroHeaderCentered from "../../components/heros/HeroHeaderCentered";
import UpdateOnScroll from "../../components/utility/UpdateOnScroll";
import { useDataFetchStatus } from "../../hooks/useDataFetchStatus";
import SEO from "../../components/global/SEO";

import TextRow, {
  mapTextRowData,
  textRowFrag,
} from "../../components/content/TextRow";
import PullQuote, {
  mapPullQuoteData,
  pullQuoteFrag,
} from "../../components/content/PullQuote";
import ImageBlock, {
  mapImageData,
  imageFrag,
} from "../../components/media/ImageBlock";
import ImageCluster, {
  mapImageClusterData,
  imageClusterFrag,
} from "../../components/media/ImageCluster";
import ImageCarousel, {
  mapImageCarouselData,
  imageCarouselFrag,
} from "../../components/media/ImageCarousel";
import GenericContent, {
  mapGenericContentData,
  genericContentFrag,
} from "../../components/content/GenericContent";
import TextColumns, {
  mapTextColumnsData,
  textColumnsFrag,
} from "../../components/content/TextColumns";
import TextWithImage, {
  textWithImageFrag,
  mapTextWithImageData,
} from "../../components/content/TextWithImage";

const GET_APPROACH_PAGE = gql`
  ${textRowFrag}
  ${imageFrag}
  ${imageCarouselFrag}
  ${imageClusterFrag}
  ${pullQuoteFrag}
  ${genericContentFrag}
  ${textColumnsFrag}
  ${textWithImageFrag}
  query ApproachContentPage($isPreview: Boolean = false) {
    generalContent(id: "7AsLHkZhT0s4iAbUq1TZjg", preview: $isPreview) {
      title
      slug
      longTitle
      seoTitle
      seoDescription
      heroImage
      heroImageAltText
      heroImageMaskColour
      heroColumn1 {
        json
      }
      heroColumn2 {
        json
      }
      heroLayout
      dividers
      contentBlocksCollection(limit: 10) {
        items {
          __typename
          ... on BlockTextRow {
            ...textRowFrag
          }
          ... on BlockPullQuote {
            ...pullQuoteFrag
          }
          ... on BlockImage {
            ...imageFrag
          }
          ... on BlockImageCluster {
            ...imageClusterFrag
          }
          ... on BlockImageGallery {
            ...imageCarouselFrag
          }
          ... on BlockGenericContent {
            ...genericContentFrag
          }
          ... on BlockGenericContent {
            ...genericContentFrag
          }
          ... on BlockTextColumns {
            ...textColumnsFrag
          }
          ... on BlockTextWithImage {
            ...textWithImageFrag
          }
        }
      }
    }
  }
`;

const imageComponentTypes = [
  "BlockImage",
  "BlockImageCluster",
  "BlockImageGallery",
];

const blockComponent = (component, keyIndex, prevComponent, dividers) => {
  switch (component.__typename) {
    case "BlockTextRow":
      const updatedComponent = Object.assign({ dividers: dividers }, component);
      return (
        <React.Fragment key={keyIndex}>
          {imageComponentTypes.includes(prevComponent.__typename) ? (
            <Spacer short={true} />
          ) : (
            <Spacer />
          )}
          <TextRow data={mapTextRowData(updatedComponent)} />
        </React.Fragment>
      );
    case "BlockPullQuote":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <PullQuote key={keyIndex} data={mapPullQuoteData(component)} />
        </React.Fragment>
      );
    case "BlockImage":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <Grid key={keyIndex}>
            <Box sx={{ gridColumn: "columns-start / columns-end" }}>
              <ImageBlock data={mapImageData(component)} />
            </Box>
          </Grid>
        </React.Fragment>
      );
    case "BlockImageCluster":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <ImageCluster key={keyIndex} data={mapImageClusterData(component)} />
        </React.Fragment>
      );
    case "BlockImageGallery":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <ImageCarousel
            key={keyIndex}
            data={mapImageCarouselData(component)}
          />
        </React.Fragment>
      );
    case "BlockGenericContent":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <GenericContent data={mapGenericContentData(component)} />
        </React.Fragment>
      );
    case "BlockTextColumns":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <TextColumns data={mapTextColumnsData(component)} />
        </React.Fragment>
      );
    case "BlockTextWithImage":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <TextWithImage data={mapTextWithImageData(component)} />
        </React.Fragment>
      );
    default:
      return null;
  }
};

const Approach = ({ isPreview }) => {
  const { loading, error, data } = useQuery(GET_APPROACH_PAGE, {
    variables: { isPreview: isPreview },
  });

  useDataFetchStatus(loading, error);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (loading === false) {
    const generalContent = data.generalContent;
    const generalContentContentBlocks =
      data.generalContent.contentBlocksCollection.items;

    const layout = {
      imagePosition:
        generalContent.heroLayout &&
        generalContent.heroLayout.includes("Image Left")
          ? "left"
          : "right",
      textAlignment:
        generalContent.heroLayout &&
        generalContent.heroLayout.includes("Text Top")
          ? "top"
          : "bottom",
    };

    const heroData = {
      title: generalContent.title,
      longTitle: generalContent.longTitle,
      image: generalContent.heroImage && generalContent.heroImage[0],
      imageAltText: generalContent.heroImageAltText,
      maskColour: generalContent.heroImageMaskColour,
      heroColumn1: generalContent.heroColumn1,
      heroColumn2: generalContent.heroColumn2,
      imagePosition: layout.imagePosition,
      textAlignment: layout.textAlignment,
    };

    const seo = {
      title: generalContent.seoTitle
        ? generalContent.seoTitle
        : `${generalContent.title} | Prime Motive`,
      description: generalContent.seoDescription
        ? generalContent.seoDescription
        : generalContent.longTitle,
      url: "https://primemotive.com.au/approach",
    };

    return (
      <>
        <SEO title={seo.title} description={seo.description} url={seo.url} />
        <HeroHeaderCentered data={heroData} />
        <UpdateOnScroll.Sentinel theme="brown" />
        <Box>
          {generalContentContentBlocks.map((item, index) =>
            blockComponent(
              item,
              index,
              index !== 0 && generalContentContentBlocks[index - 1],
              generalContent.dividers
            )
          )}
        </Box>
        <Spacer />
      </>
    );
  } else {
    return null;
  }
};

export default Approach;

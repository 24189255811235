import { useInView } from "react-intersection-observer";
import { Text } from "theme-ui";

import OpacityWrap from "../../utility/OpacityWrap";

export const Heading = {
  H1: ({ threshold = 0.1, as = "h1", text, id, sx }) => {
    const [textRef] = useInView({
      threshold: threshold,
      triggerOnce: true,
    });

    return (
      <Text
        ref={textRef}
        as={as}
        id={id}
        variant="text.heading.h1"
        sx={{
          position: "relative",
          color: "blue",
          ...sx,
        }}
        aria-label={text}
      >
        <OpacityWrap threshold={0.25} duration={0.6}>
          {text}
        </OpacityWrap>
      </Text>
    );
  },
  H1Variable: ({ threshold = 0.1, as = "h1", text, id, sx }) => {
    const [textRef] = useInView({
      threshold: threshold,
      triggerOnce: true,
    });

    return (
      <Text
        ref={textRef}
        as={as}
        id={id}
        variant={text.length > 27 ? "text.heading.h2" : "text.heading.h1"}
        sx={{
          position: "relative",
          color: "blue",
          ...sx,
        }}
        aria-label={text}
      >
        <OpacityWrap threshold={threshold} duration={0.6}>
          {text}
        </OpacityWrap>
      </Text>
    );
  },
  H2: ({ threshold = 0.1, as = "h2", text, id, sx }) => {
    const [textRef] = useInView({
      threshold: threshold,
      triggerOnce: true,
    });

    return (
      <Text
        ref={textRef}
        as={as}
        id={id}
        variant="text.heading.h2"
        sx={{
          position: "relative",
          color: "blue",
          ...sx,
        }}
        aria-label={text}
      >
        <OpacityWrap threshold={threshold} duration={0.6}>
          {text}
        </OpacityWrap>
      </Text>
    );
  },
  H2Quote: ({ threshold = 0.1, as = "blockquote", text, id, sx }) => {
    const [textRef] = useInView({
      threshold: threshold,
      triggerOnce: true,
    });

    return (
      <Text
        ref={textRef}
        as={as}
        id={id}
        variant="text.heading.h2"
        sx={{
          position: "relative",
          color: "blue",
          ...sx,
        }}
        aria-label={text}
      >
        <OpacityWrap threshold={threshold} duration={0.6}>
          <Text
            as="span"
            sx={{
              "&:before": {
                content: "open-quote",
                position: "absolute",
                left: "-0.4em",
              },
            }}
          ></Text>
          {text}
        </OpacityWrap>
      </Text>
    );
  },
  H3: ({ threshold = 0.1, as = "h3", text, sx, semiBold = false }) => {
    const [textRef] = useInView({
      threshold: threshold,
      triggerOnce: true,
    });

    return (
      <Text
        ref={textRef}
        as={as}
        variant="text.heading.h3"
        sx={{
          position: "relative",
          color: "blue",
          fontWeight: semiBold ? 600 : 500,
          ...sx,
        }}
        aria-label={text}
      >
        <OpacityWrap threshold={threshold} duration={0.6}>
          {text}
        </OpacityWrap>
      </Text>
    );
  },
  H4: ({
    threshold = 0.1,
    as = "h4",
    text,
    sx,
    caseStudy = false,
    semiBold = false,
  }) => {
    const [textRef] = useInView({
      threshold: threshold,
      triggerOnce: true,
    });

    return (
      <OpacityWrap threshold={threshold} duration={0.6}>
        <Text
          ref={textRef}
          as={as}
          variant={caseStudy ? "text.body.regular" : "text.heading.h4"}
          sx={{
            fontWeight: semiBold ? "semiBold" : "body",
            position: "relative",
            color: "blue",
            wordBreak: "break-word",
            hyphens: "auto",
            whiteSpace: "normal",
            ...sx,
          }}
          aria-label={text}
        >
          {caseStudy && (
            <Text color="#F02828" as="span">
              Case Study /{" "}
            </Text>
          )}
          {text}
        </Text>
      </OpacityWrap>
    );
  },
};

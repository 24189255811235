// import { useEffect, useState } from "react";
import { Grid } from "../../../styles/layout";
import CaseStudyCard from "../cards/CaseStudyCard";
import HorizontalLine from "../decorative/HorizontalLine";
import { Heading } from "../typography/Heading";
import RichText from "../richtext/RichText";
import { Box } from "theme-ui";

const cardStyles = {
  display: "grid",
  gridTemplateRows: ["1fr auto", "", "auto 1fr"],
  marginBottom: ["48px", "", "", "80px"],
  ".card__image-item": {
    gridRow: "1 / span 1",
    overflow: "hidden",
    img: {
      objectFit: "cover",
      aspectRatio: "1.375",
    },
  },
  ".card__text-item": {
    gridRow: "span 1 / -1",
    display: "grid",
    gridTemplateColumns: ["repeat(6, minmax(20px, 1fr))", ""],
    gridColumnGap: "32px",
    gridAutoRows: "min-content",
  },
  ":nth-of-type(odd)": {
    gridColumn: ["columns-start / columns-end", "", "2 / span 4", "2 / span 6"],
  },
  ":nth-of-type(even)": {
    gridColumn: ["columns-start / columns-end", "", "6 / span 4", "8 / span 6"],
  },

  ":nth-of-type(4n+1), :nth-of-type(4n+4)": {
    img: {
      aspectRatio: ["", "", "1.60"],
    },
  },
  ":nth-of-type(4n+4)": {
    ".card__image-item": {
      pt: ["", "", "10.6%"],
    },
  },
};

const CardGrid = ({ data, label, text, heading, cardHeadingAs = "h3" }) => {
  // const [hoveredIndex, setHoveredIndex] = useState(-1);

  // const handleCardHover = (index) => {
  //   setHoveredIndex(index);
  // };

  // const handleCardLeave = () => {
  //   setHoveredIndex(-1);
  // };

  return (
    <>
      {heading && (
        <>
          <HorizontalLine />
          <Grid label={label} sx={{ gap: "32px" }}>
            <Box
              sx={{
                gridColumn: ["2 / -2", "", "2 / span 5", "5 / span 4", ""],
                marginBottom: ["80px", "", "120px", ""],
              }}
            >
              <Heading.H2 text={heading} />
            </Box>
          </Grid>
        </>
      )}
      {text && (
        <>
          <HorizontalLine flipOnMobile label={label} />
          <Grid label={label}>
            <Box
              sx={{
                gridColumn: ["2 / -2", "", "3 / span 4", "8 / span 4"],
                marginBottom: ["80px", "", "120px", ""],
              }}
            >
              <RichText content={text} />
            </Box>
          </Grid>
        </>
      )}
      <Grid label="card-grid" sx={{ gridRowGap: ["40px"] }}>
        {data.items.map((card, index) => {
          // const isHovered = index === hoveredIndex;
          return (
            <CaseStudyCard
              key={index}
              card={card}
              headingAs={cardHeadingAs}
              // isHovered={isHovered}
              // hoveredIndex={hoveredIndex}
              // onMouseEnter={() => {
              //   handleCardHover(index);
              // }}
              // onMouseLeave={() => {
              //   handleCardLeave();
              // }}
              sx={{
                ...cardStyles,
              }}
            />
          );
        })}
      </Grid>
    </>
  );
};

export default CardGrid;

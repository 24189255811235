// import { useState, useRef } from "react";
import { Card, Box } from "theme-ui";
import TextBlock from "../typography/TextBlock";
import { Link } from "react-router-dom";
import ImageBlock from "../../media/ImageBlock";

const headingStyles = {
  paddingTop: ["24px"],
  gridColumn: ["span 6", "", "", "span 2"],
};

const paragraphStyles = {
  gridColumn: ["span 6", "", "", "span 4"],
  paddingTop: ["16px", "", "24px"],
};

const CaseStudyCard = ({
  // onMouseEnter,
  // onMouseLeave,
  // hoveredIndex,
  card,
  headingAs = "h3",
  sx,
}) => {
  // const [isHoveredState, setIsHoveredState] = useState(false);
  // const ref = useRef();

  // const handleMouseEnter = () => {
  //   setIsHoveredState(true);
  //   onMouseEnter();
  // };

  // const handleMouseLeave = () => {
  //   setIsHoveredState(false);
  //   onMouseLeave();
  // };

  return (
    <Card
      // ref={ref}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
      as={Link}
      to={card.url ? card.url : "/"}
      sx={{
        ...sx,
        position: "relative",
        textDecoration: "none",
        transition: "filter 300ms ease-in-out, opacity 300ms ease-in-out",
        // filter:
        // hoveredIndex !== -1 && !isHoveredState ? "grayscale(100%)" : "none",

        // opacity: hoveredIndex !== -1 && !isHoveredState ? "0.5" : "1",
      }}
    >
      <Box
        className="card__image-item c-case-study-item"
        sx={{
          img: {
            transformOrigin: "50% 50%",
            transform: "scale3d(1 , 1 , 1)",
            transition: "transform 450ms ease-in-out",
          },
          "&:hover, &:focus": {
            img: {
              transform: "scale3d(1.1, 1.1, 1)",
              transition: "transform 450ms ease-in-out",
            },
          },
        }}
      >
        <ImageBlock
          data={{
            image: {
              url: card && card.image ? card.image[0].public_id : null,
              alt: card.imageAltText,
              src: card.image[0].url,
            },
            maskColour: card.imageMaskColour ? card.imageMaskColour : "#050514",
          }}
        />
      </Box>
      <Box className="card__text-item">
        <Box sx={{ ...headingStyles }}>
          <TextBlock as={headingAs} variant="text.body.regular" semiBold>
            {card.label}
          </TextBlock>
        </Box>
        <Box sx={{ ...paragraphStyles }}>
          <TextBlock>{card.title}</TextBlock>
        </Box>
      </Box>
    </Card>
  );
};

export default CaseStudyCard;

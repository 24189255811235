import { gql, useQuery } from "@apollo/client";
import CardGrid from "../../components/shared/grids/CardGrid";
import HeroHeading from "../../components/heros/HeroHeading";
import Spacer from "../../components/utility/Spacer";
import Error from "../Error/Error";
import Loading from "../../components/global/Loader";
import { useDataFetchStatus } from "../../hooks/useDataFetchStatus";
import SEO from "../../components/global/SEO";

const GET_ARTICLES = gql`
  query feed($isPreview: Boolean = false) {
    feedCollection(
      where: { sys: { id: "2FVycL8IwaLqQtjgG4H6TV" } }
      preview: $isPreview
    ) {
      items {
        feedItemsCollection {
          items {
            ... on FeedItem {
              title
              label
              image
              imageAltText
              imageMaskColour
              url
            }
          }
        }
      }
    }
  }
`;

const seo = {
  title: "Insights | Prime Motive",
  description: "Insights at Prime Motive",
  url: "https://primemotive.com.au/insights",
};

const Insights = ({ isPreview }) => {
  const { loading, error, data } = useQuery(GET_ARTICLES, {
    variables: { isPreview: isPreview },
  });

  useDataFetchStatus(loading, error);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  const cards = data.feedCollection.items[0].feedItemsCollection;

  return (
    <>
      <SEO title={seo.title} description={seo.description} url={seo.url} />
      <HeroHeading
        data={{
          label: "Insights",
          h1: "Thinking, views and experiments in design, strategy and experience.",
        }}
      />
      <CardGrid data={cards} cardHeadingAs="h2" />
      <Spacer />
    </>
  );
};

export default Insights;

import React from "react";

// A valid form ID from Active Campaign must be provided for form to be able to be submitted.
// https://medium.com/le-wagon/how-to-hack-yourself-a-custom-activecampaign-form-component-in-react-73e4624dfbff
const HiddenFormInputs = ({ formId, register }) => {
  if (!formId) {
    throw new Error("Error: Active Campaign form ID required.");
  }
  return (
    <>
      <input
        type="hidden"
        name="u"
        value={formId}
        {...register("u", { required: true })}
      />
      <input
        type="hidden"
        name="f"
        value={formId}
        {...register("f", { required: true })}
      />
      <input type="hidden" name="s" {...register("s")} />
      <input
        type="hidden"
        name="c"
        value="0"
        {...register("c", { required: true })}
      />
      <input
        type="hidden"
        name="m"
        value="0"
        {...register("m", { required: true })}
      />
      <input
        type="hidden"
        name="act"
        value="sub"
        {...register("act", { required: true })}
      />
      <input
        type="hidden"
        name="v"
        value="2"
        {...register("v", { required: true })}
      />
    </>
  );
};

export default HiddenFormInputs;

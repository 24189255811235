import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Box } from "theme-ui";
import { Grid } from "../../styles/layout";
import Error from "../Error/Error";
import Loading from "../../components/global/Loader";
import { Heading } from "../../components/shared/typography/Heading";
import RichText from "../../components/shared/richtext/RichText";
import Spacer from "../../components/utility/Spacer";
import { useDataFetchStatus } from "../../hooks/useDataFetchStatus";
import SEO from "../../components/global/SEO";

const GET_PRIVACY_TERMS_CONTENT = gql`
  query privacyTermsContent($isPreview: Boolean = false) {
    blockTextRow(id: "15c6zh7XR7r7R1oCxuW8fv", preview: $isPreview) {
      body {
        json
      }
    }
  }
`;

const seo = {
  title: "Privacy | Prime Motive",
  description: "Privacy",
  url: "https://primemotive.com.au/privacy",
};

const PrivacyTerms = ({ isPreview }) => {
  const { loading, error, data } = useQuery(GET_PRIVACY_TERMS_CONTENT, {
    variables: { isPreview: isPreview },
  });

  useDataFetchStatus(loading, error);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <>
      <SEO title={seo.title} description={seo.description} url={seo.url} />
      <Box>
        <Grid sx={{ marginTop: ["120px", "", "28px", "32px"] }}>
          <Heading.H1
            text="Privacy Policy"
            sx={{
              marginBottom: ["100px", "", "", "375px"],
              gridColumn: [
                "columns-start / columns-end",
                "",
                "5 / span 4",
                "span 5 / -4",
              ],
            }}
          />
        </Grid>
        <Grid>
          <Box
            sx={{
              gridColumn: [
                "columns-start / columns-end",
                "",
                "5 / span 4",
                "7 / span 5",
              ],
            }}
          >
            <RichText content={data.blockTextRow.body} />
          </Box>
        </Grid>
        <Spacer />
      </Box>
    </>
  );
};
export default PrivacyTerms;

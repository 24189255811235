import { useCustomCursorBehaviour } from "../../hooks/useCustomCursorBehaviour";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useThemeUI } from "theme-ui";

const Cursor = styled.div`
  position: fixed;
  border-radius: 50%;
  pointer-events: none;
  z-index: 998;
  transition: background-color 200ms ease-in-out, width 200ms ease-in-out,
    height 200ms ease-in-out;
  transform: translate(-50%, -50%);

  ::before {
    content: ${(props) => (props.showTriangles ? '""' : "none")};
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 14px 14px 0;
    border-color: transparent
      ${(props) =>
        props.isCarousel && props.isMouseDown ? "white" : props.bgColor}
      transparent transparent;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    transition: border-color 200ms ease-in-out, left 100ms 200ms ease-in-out;
  }

  ::after {
    content: ${(props) => (props.showTriangles ? '""' : "none")};
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 0 14px 14px;
    border-color: transparent transparent transparent
      ${(props) =>
        props.isCarousel && props.isMouseDown ? "white" : props.bgColor};
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    transition: border-color 200ms ease-in-out, right 100ms 200ms ease-in-out;
  }

  &.cursor-up {
    background: ${(props) => props.bgColor};
    &:before {
      left: 0px;
    }
    &:after {
      right: 0px;
    }
  }

  &.cursor-down {
    background: ${(props) => (props.isCarousel ? "white" : props.bgColor)};
    mix-blend-mode: ${(props) => (props.isCarousel ? "exclusion" : "none")};
    &:before {
      left: -20px;
    }
    &:after {
      right: -20px;
    }
  }
`;

const CursorText = styled.p`
  position: fixed;
  transition: color 300ms ease-in-out;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  font-weight: 700;
  pointer-events: none;
  text-transform: uppercase;
  z-index: 9999;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity 0ms 0ms ease-in-out;

  &.show-text {
    transition: opacity 100ms 50ms ease-in-out;
    opacity: 1;
  }
`;

const CustomCursor = ({ text, type, speed, maxSize, minSize }) => {
  const { theme } = useThemeUI();
  const { colors } = theme;
  const { cursor, target, isMouseDown } = useCustomCursorBehaviour(
    speed,
    maxSize,
    minSize
  );
  const [showText, setShowText] = useState(false);
  const [showTriangles, setShowTriangles] = useState(false);
  const [bgColor, setBgColor] = useState(colors.brown);
  const [textColor, setTextColor] = useState(colors.blue);

  useEffect(() => {
    if (Math.ceil(cursor.size) >= maxSize - 50) {
      setShowText(true);
      type === "carousel" && setShowTriangles(true);
    } else {
      setShowText(false);
      type === "carousel" && setShowTriangles(false);
    }
  }, [cursor.size, maxSize, type]);

  useEffect(() => {
    if (isMouseDown) {
      setBgColor(colors.blue);
      setTextColor(colors.white);
    } else {
      setBgColor(colors.blue);
      setTextColor(colors.white);
    }
  }, [isMouseDown, colors]);

  return (
    <>
      <Cursor
        id="custom-cursor"
        showTriangles={type === "carousel" ? showTriangles : false}
        isCarousel={type === "carousel"}
        isMouseDown={isMouseDown}
        bgColor={bgColor}
        className={`${isMouseDown ? "cursor-down" : "cursor-up"}`}
        style={{
          top: `${cursor.y * 100}%`,
          left: `${cursor.x * 100}%`,
          width: `${target.size}px`,
          height: `${target.size}px`,
          borderRadius: type === "case-studies" && "15px",
        }}
      />
      {text && (
        <CursorText
          className={`${showText ? "show-text" : "hide-text"}`}
          showText={showText}
          style={{
            top: `${cursor.y * 100}%`,
            left: `${cursor.x * 100}%`,
            color: textColor,
            whiteSpace: "pre-line",
            textAlign: "center",
          }}
        >
          {text}
        </CursorText>
      )}
    </>
  );
};

export default CustomCursor;

import {
  useEffect,
  useLayoutEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { useColorMode } from "theme-ui";
import { RemoveScroll } from "react-remove-scroll";
import { FocusOn } from "react-focus-on";
import ReactGA from "react-ga4";
import { StoreContext } from "../../store/store";
import { observer } from "mobx-react-lite";
import CustomCursor from "../animated/CustomCursor";
// import { useMediaQuery } from "react-responsive";
import { isDesktop } from "react-device-detect";

// Components
import Navigation, { MenuButton } from "./Navigation";

// Set bgColor to null so its not undefined
const Layout = ({ children, bgColor = null, location }) => {
  const {
    setDataFetched,
    setStaticPage,
    getDataFetched,
    getIsStaticPage,
    getCursor,
  } = useContext(StoreContext);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [, setColorMode] = useColorMode();
  const previousPath = useRef(location.pathname);
  // const isDesktopWidth = useMediaQuery({ minWidth: 1024 });
  const isDesktopDevice = isDesktop;

  useEffect(() => {
    setIsNavOpen(false);
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
    });
  }, [location.pathname]);

  useEffect(() => {
    location.pathname === "/" ? setColorMode("home") : setColorMode("light");
  }, [bgColor, setColorMode, location.pathname]);

  useLayoutEffect(() => {
    const pathHasChanged = location.pathname !== previousPath.current;
    if (pathHasChanged) {
      if (getDataFetched) {
        setDataFetched(false);
      }
      if (getIsStaticPage) {
        setStaticPage(false);
      }
    }
    previousPath.current = location.pathname;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataFetched, getIsStaticPage, location.pathname]);

  return (
    <>
      <RemoveScroll enabled={isNavOpen}>
        <FocusOn
          enabled={isNavOpen}
          onClickOutside={() => setIsNavOpen(false)}
          onEscapeKey={() => setIsNavOpen(false)}
        >
          <Navigation isOpen={isNavOpen} setIsNavOpen={setIsNavOpen}>
            <MenuButton
              onClick={() => setIsNavOpen(!isNavOpen)}
              aria-expanded="false"
              aria-controls="menu-list"
              isOpen={isNavOpen}
            />
          </Navigation>
        </FocusOn>
      </RemoveScroll>
      {isDesktopDevice && location.pathname === "/" && getCursor.active && (
        <CustomCursor
          text={getCursor.text}
          type={getCursor.type}
          speed={0.3}
          minSize={0}
          maxSize={115}
        />
      )}

      {children}
    </>
  );
};

export default observer(Layout);

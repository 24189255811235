import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { HashLink } from "react-router-hash-link";
import { Grid } from "../../styles/layout";
import ImageBlock from "../media/ImageBlock";
import TextBlock from "../shared/typography/TextBlock";
import RichText from "../shared/richtext/RichText";
import { Heading } from "../shared/typography/Heading";
import { Button } from "../shared/ctas/Button";

const StyledHero = styled(Box)`
  label: hero-header-alt;
`;

const listItemStyles = {
  ul: {
    marginBottom: 0,
    li: {
      listStyle: "disc",
      marginBottom: 0,
      marginLeft: "18px",
    },
  },
};

const HeroHeaderCentered = ({ data, isCareersPage = false }) => {
  return (
    <StyledHero
      as="section"
      sx={{ marginTop: ["140px", "", "200px", "256px"] }}
    >
      <Grid label="internal-hero">
        <Box
          sx={{
            marginBottom: ["56px", "", "80px", "100px"],
            gridColumn: ["columns-start / columns-end"],
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ marginBottom: "16px" }}>
            <TextBlock
              as="h2"
              semiBold={false}
              color="red"
              sx={{ fontWeight: "400" }}
            >
              {data.title}
            </TextBlock>
          </Box>
          <Heading.H1
            text={data.longTitle}
            sx={{
              textAlign: "center",
              maxWidth: ["500px", "", "600px", "850px"],
            }}
          />
        </Box>
        {data.image && (
          <Box
            sx={{
              marginBottom: ["56px", "", "80px", "100px"],
              gridColumn: ["2 / -2", "", "3 / -3", "5 / span 6"],
            }}
          >
            <ImageBlock
              data={{
                image: { url: data.image.public_id, alt: data.imageAltText },
                maskColour: data.maskColour,
              }}
            />
          </Box>
        )}
        {data.heroColumn1 && (
          <Box
            sx={{
              gridColumn: ["2 / -2", "", "2 / span 4", "4 / span 4"],
              gridRow: ["", "", "3"],
              marginBottom: ["24px", "", "0"],
              ...listItemStyles,
            }}
          >
            <RichText content={data.heroColumn1} />
          </Box>
        )}
        {data.heroColumn2 && (
          <Box
            sx={{
              gridColumn: ["2 / -2", "", "6 / span 4", "8 / span 4"],
              gridRow: ["", "", "3"],
              ...listItemStyles,
            }}
          >
            <RichText content={data.heroColumn2} />
          </Box>
        )}
        {isCareersPage && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gridColumn: ["2 / -2", "", "6 / span 4", "8 / span 4"],
              marginTop: "56px",
            }}
          >
            <Button.Secondary
              as={HashLink}
              onClick={(event) => {
                event.preventDefault();
                const element = document.querySelector("#job-openings");
                element.scrollIntoView({ behavior: "smooth" });
              }}
              text="Job Openings"
              to="#job-openings"
              sx={{ width: ["100%", "", "100%", "50%"] }}
              anchorLink
            />
          </Box>
        )}
      </Grid>
    </StyledHero>
  );
};

export default HeroHeaderCentered;

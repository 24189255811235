import { useState, useCallback, useEffect, useRef, useMemo } from "react";
import { gql } from "@apollo/client";
import styled from "@emotion/styled";
import { useInView } from "react-intersection-observer";
import { Box } from "theme-ui";
import { forwardRef } from "react";

import CloudinaryImage from "../shared/cloudinary/CloudinaryImage";

// This should be the only place to add/edit/delete the fields for this component
// Import this fragment into your template where you are using this component.
export const imageFrag = gql`
  fragment imageFrag on BlockImage {
    title
    image
    imageAltText
    imageMaskColour
  }
`;

export const mapImageData = (data) => {
  return {
    title: data.title,
    image: {
      url: data.image[0].public_id,
      alt: data.imageAltText,
      src: data.image[0].url,
    },
    maskColour: data.imageMaskColour,
  };
};

const StyledImageContainer = styled(Box)`
  line-height: 0;
  position: relative;
  opacity: 0;
  transform: translateY(${(props) => (props.maskColour ? "50px" : "0px")});
  transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
  height: 100%;
  &.fade-up {
    opacity: 1;
    transform: translateY(0%);
  }
  &.fade-in {
    opacity: 1;
  }
`;

const StyledImageInner = styled(Box)`
  line-height: 0;
  position: relative;
  max-height: 100%;

  img {
    position: relative;
    width: 100%;
    max-width: 100%;
    clip-path: ${(props) => props.clipPath || "none"};
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.maskColour};
    z-index: 3;
    opacity: 1;
    transition: opacity 400ms 150ms ease-in-out;
    clip-path: ${(props) => props.clipPath || "none"};
  }
  &.remove-mask {
    &::before {
      opacity: 0;
    }
  }
`;

const ImageBlockAnimated = (
  {
    data,
    className,
    threshold = 0.5,
    containerThreshold = 0.1,
    transform,
    clipPath,
    borderRadius = ["16px", "", "20px", "", "30px"],
    height = "auto",
    sx,
    // onLoad = null,
  },
  ref
) => {
  // TODO: Re-factor component
  const [containerRef, containerView] = useInView({
    threshold: containerThreshold,
    triggerOnce: true,
  });

  // eslint-disable-next-line no-unused-vars
  const [imgRef, _imgView] = useInView({
    threshold: threshold,
    triggerOnce: true,
  });
  const [imageLoaded, setImageLoaded] = useState(false);
  // const [currentIndex, setCurrentIndex] = useState(0);

  const [currentIndex, setCurrentIndex] = useState(
    data.images.length > 1 ? Math.floor(Math.random() * data.images.length) : 0
  );

  // const handleImageLoad = useCallback(() => {
  //   setImageLoaded(true);
  //   onLoad && onLoad();
  // }, [onLoad]);

  const imageLoadCountRef = useRef(0);

  const handleImageLoad = useCallback(() => {
    if (imageLoadCountRef.current === data.images.length) return;
    imageLoadCountRef.current += 1;
    if (imageLoadCountRef.current === data.images.length) {
      setImageLoaded(true);
      // onLoad && onLoad();
    }
  }, [data.images.length]);

  const mappedImages = useMemo(
    () =>
      data.images.map((image, index) => {
        return (
          <Box
            key={image.public_id + index}
            sx={{
              position: "absolute",
              top: "0",
              left: "0",
              height: "100%",
              width: "100%",
              opacity: index === currentIndex && imageLoaded ? 1 : 0,
              transition: "opacity 1s ease-in-out",
            }}
          >
            <CloudinaryImage
              className={className}
              src={image.public_id}
              alt={data.altText[index]}
              transform={transform}
              onLoad={handleImageLoad}
              ref={ref}
            />
          </Box>
        );
      }),
    [
      data.images,
      currentIndex,
      imageLoaded,
      className,
      data.altText,
      transform,
      ref,
      handleImageLoad,
    ]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.images.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [data.images.length]);

  return (
    <StyledImageContainer
      ref={containerRef}
      maskColour={data.maskColour}
      className={`${
        containerView && imageLoaded && data.maskColour && "fade-up"
      } ${
        containerView && imageLoaded && !data.maskColour && "fade-in"
      }  ${className}`}
      sx={{
        borderRadius: borderRadius,
      }}
    >
      <StyledImageInner
        ref={imgRef}
        className={`${imageLoaded && "remove-mask"}`}
        maskColour={data.maskColour}
        clipPath={clipPath}
        sx={{
          borderRadius: borderRadius,
          overflow: "hidden",
          img: {
            height: height,
          },
          "&:before": {
            borderRadius: borderRadius,
          },
          ...sx,
        }}
      >
        <Box
          sx={{ visibility: "hidden" }}
          aria-hidden="true"
          role="presentation"
        >
          <CloudinaryImage
            className={className}
            src={data.images[0].public_id}
            alt={data.images[0].alt}
            transform={transform}
            ref={ref}
          />
        </Box>
        {mappedImages}
      </StyledImageInner>
    </StyledImageContainer>
  );
};

export default forwardRef(ImageBlockAnimated);

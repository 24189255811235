export const discoverImageLeft = {
  hidden: { opacity: 0, y: 50, x: -300 },
  visible: { opacity: 1, y: 0, x: 0 },
};
export const discoverImageRight = {
  hidden: { opacity: 0, y: -50, x: 300 },
  visible: { opacity: 1, y: 0, x: 0 },
};

export const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

export const slideInRtL = {
  hidden: { opacity: 0, x: "100px" },
  visible: { opacity: 1, x: 0 },
};

export const slideInLrR = {
  hidden: { opacity: 0, x: "-100px" },
  visible: { opacity: 1, x: 0 },
};

export const animateInUp = {
  hidden: { opacity: 0, y: "60px" },
  visible: { opacity: 1, y: 0 },
};

export const animateInUp2 = {
  hidden: { opacity: 0, y: "-110px", x: "60vw" },
  visible: { opacity: 1, y: 0, x: 0 },
};

export const animateInUp3 = {
  hidden: { y: "30px", x: "-50vw" },
  visible: { y: 0, x: 0 },
};

export const animateInUp4 = {
  hidden: { opacity: 0, y: "360px" },
  visible: { opacity: 1, y: 0 },
};

export const animateInUp5 = {
  hidden: { y: "-30px" },
  visible: { y: 0 },
};
export const punchIn = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};

export const punchInTransition = {
  type: "spring",
  damping: 15,
  duration: 2,
  ease: "easeOut",
  delay: 0.5,
};
export const punchInTransition2 = {
  type: "spring",
  damping: 8,
  ease: "easeInOut",
  delay: 3,
};

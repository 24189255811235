import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box } from "theme-ui";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { Grid } from "../../styles/layout";
import Error from "../../pages/Error/Error";
import Loading from "../../components/global/Loader";
import { useDataFetchStatus } from "../../hooks/useDataFetchStatus";
import SEO from "../../components/global/SEO";

import UpdateOnScroll from "../../components/utility/UpdateOnScroll";

import HeroFullWidth from "../../components/heros/HeroFullWidth";
import TextRow, {
  mapTextRowData,
  textRowFrag,
} from "../../components/content/TextRow";
import PullQuote, {
  mapPullQuoteData,
  pullQuoteFrag,
} from "../../components/content/PullQuote";
import ImageBlock, {
  mapImageData,
  imageFrag,
} from "../../components/media/ImageBlock";
import ImageCluster, {
  mapImageClusterData,
  imageClusterFrag,
} from "../../components/media/ImageCluster";
import ImageCarousel, {
  mapImageCarouselData,
  imageCarouselFrag,
} from "../../components/media/ImageCarousel";
import ImageScrollContent, {
  imageScrollContentFrag,
  mapImageScrollContentData,
} from "../../components/media/ImageScrollContent";
import Spacer from "../../components/utility/Spacer";
import SummaryInBrief from "../../components/content/Summary/SummaryInBrief";
import SummaryInDetail from "../../components/content/Summary/SummaryInDetail";
import { useIsValidURL } from "../../hooks/useIsValidURL";

const GET_CS_BY_SLUG = gql`
  ${textRowFrag}
  ${imageFrag}
  ${imageCarouselFrag}
  ${imageClusterFrag}
  ${imageScrollContentFrag}
  ${pullQuoteFrag}

  query CaseStudyPage($slug: String!, $isPreview: Boolean = false) {
    caseStudyCollection(where: { slug: $slug }, preview: $isPreview) {
      items {
        title
        slug
        shortDescription
        longDescription
        seoTitle
        seoDescription
        showHeroImage
        heroImage
        heroImageAltText
        maskColour
        clientName
        backgroundColours
        showInBriefSection
        challenge {
          json
        }
        outcomes {
          json
        }
        howItWasDone {
          json
        }
        inDetailIntro {
          json
        }
        serviceCollection(limit: 10) {
          items {
            title
          }
        }
        contentBlocksCollection(limit: 20) {
          items {
            __typename
            ... on BlockTextRow {
              ...textRowFrag
            }
            ... on BlockPullQuote {
              ...pullQuoteFrag
            }
            ... on BlockScrollJackPanel {
              ...imageScrollContentFrag
            }
            ... on BlockImage {
              ...imageFrag
            }
            ... on BlockImageCluster {
              ...imageClusterFrag
            }
            ... on BlockImageGallery {
              ...imageCarouselFrag
            }
          }
        }
      }
    }
  }
`;

const imageComponentTypes = [
  "BlockImage",
  "BlockImageCluster",
  "BlockImageGallery",
];

const blockComponent = (component, keyIndex, prevComponent, themeProps) => {
  switch (component.__typename) {
    case "BlockTextRow":
      return (
        <React.Fragment key={keyIndex}>
          {imageComponentTypes.includes(prevComponent.__typename) ? (
            <Spacer short={true} />
          ) : (
            <Spacer />
          )}
          <TextRow data={mapTextRowData(component)} />
        </React.Fragment>
      );
    case "BlockPullQuote":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <PullQuote key={keyIndex} data={mapPullQuoteData(component)} />
        </React.Fragment>
      );
    case "BlockImage":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <Grid key={keyIndex} sx={{ label: "full-width-image" }}>
            <Box
              sx={{
                gridColumn: [
                  "columns-start / columns-end",
                  "",
                  "",
                  "",
                  "3 / -3",
                ],
              }}
            >
              <ImageBlock data={mapImageData(component)} />
            </Box>
          </Grid>
        </React.Fragment>
      );
    case "BlockImageCluster":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <ImageCluster key={keyIndex} data={mapImageClusterData(component)} />
        </React.Fragment>
      );
    case "BlockImageGallery":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <ImageCarousel
            key={keyIndex}
            data={mapImageCarouselData(component)}
          />
        </React.Fragment>
      );
    case "BlockScrollJackPanel":
      const updatedComponent = Object.assign(
        { bg: themeProps.imageScrollBG },
        component
      );
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <ImageScrollContent
            key={keyIndex}
            data={mapImageScrollContentData(updatedComponent)}
          />
        </React.Fragment>
      );
    default:
      return null;
  }
};

const caseStudyThemes = {
  grey: { mainBG: "#F8F8F8", imageScrollBG: "#EFEFEF" },
  red: { mainBG: "#FFEDED", imageScrollBG: "#FFDFE2" },
  purple: { mainBG: "#FBF6FF", imageScrollBG: "#F5EDFC" },
  blue: { mainBG: "#F4FBFF", imageScrollBG: "#E7F4FC" },
  green: { mainBG: "#F7FFF6", imageScrollBG: "#E7F3E6" },
  yellow: { mainBG: "#FFFDF2", imageScrollBG: "#F8F5E7" },
  orange: { mainBG: "#FFF1E9", imageScrollBG: "#FFE3D4" },
};

const CaseStudy = ({ isPreview }) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_CS_BY_SLUG, {
    variables: { slug: slug, isPreview: isPreview },
  });

  const isValidURL = useIsValidURL(
    loading,
    data,
    navigate,
    "caseStudyCollection"
  );

  useDataFetchStatus(loading, error);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (loading === false && isValidURL === true) {
    const caseStudy = data.caseStudyCollection.items[0];
    const caseStudyContentBlocks = caseStudy.contentBlocksCollection.items;
    const heroData = {
      label: "Work",
      title: caseStudy.title,
      longDescription: caseStudy.longDescription,
      showHeroImage: caseStudy.showHeroImage,
      image: caseStudy.heroImage && caseStudy.heroImage[0],
      imageAlt: caseStudy.heroImageAltText,
      maskColour: caseStudy.maskColour,
      clientName: caseStudy.clientName,
      services: caseStudy.serviceCollection.items,
    };

    const inBriefData = {
      challenge: caseStudy.challenge,
      outcomes: caseStudy.outcomes,
      howItWasDone: caseStudy.howItWasDone,
    };

    const themeProps =
      caseStudy.backgroundColours !== null
        ? caseStudyThemes[caseStudy.backgroundColours.toLowerCase()]
        : caseStudyThemes.grey;

    const seo = {
      title: caseStudy.seoTitle
        ? caseStudy.seoTitle
        : `${caseStudy.title} | Prime Motive`,
      description: caseStudy.seoDescription
        ? caseStudy.seoDescription
        : caseStudy.longDescription,
      url: `https://primemotive.com.au/work/${caseStudy.slug}`,
    };

    return (
      <>
        <SEO title={seo.title} description={seo.description} url={seo.url} />
        <HeroFullWidth data={heroData} />
        <Spacer
          sx={{
            height: [
              heroData.showHeroImage === true ? "50px" : "25px",
              "",
              "50px",
              "100px",
            ],
          }}
        />
        {caseStudy.showInBriefSection === true ? (
          <>
            <SummaryInBrief data={inBriefData} />
            <Spacer short />
            <Spacer short />
          </>
        ) : (
          <></>
        )}
        <UpdateOnScroll.InView
          rootMargin="-50%"
          theme={
            caseStudy.backgroundColours
              ? caseStudy.backgroundColours.toLowerCase()
              : "grey"
          }
        >
          <div id="summary-in-detail"></div>
          <Spacer short />
          <Spacer short />
          <SummaryInDetail data={caseStudy.inDetailIntro} />
          {caseStudyContentBlocks.map((item, index) => {
            return blockComponent(
              item,
              index,
              index !== 0 && caseStudyContentBlocks[index - 1],
              themeProps
            );
          })}
        </UpdateOnScroll.InView>
        <Spacer />
      </>
    );
  } else {
    return null;
  }
};

export default CaseStudy;

import styled from "@emotion/styled";
import { Box } from "theme-ui";

import { Grid } from "../../styles/layout";

import ImageBlock from "../media/ImageBlock";
import TextBlock from "../shared/typography/TextBlock";
import RichText from "../shared/richtext/RichText";
import { Heading } from "../shared/typography/Heading";

const StyledHero = styled(Box)`
  label: hero-with-columns;
`;

const imageLeftStyles = {
  gridColumn: ["columns-start / columns-end", "", "columns-start / span 4", ""],
  gridRow: ["", "", "2 / span 3", "2 / span 2"],
};

const imageRightStyles = {
  gridColumn: ["columns-start / columns-end", "", "6 / span 4", "10 / span 4"],
  gridRow: ["", "", "2 / span 3", "2 / span 2"],
};

const textLeftStyles = {
  fullColumn: {
    gridColumn: [
      "columns-start / columns-end",
      "",
      "columns-start / span 4",
      "columns-start / span 6",
    ],
  },
  leftColumn: {
    gridColumn: [
      "columns-start / columns-end",
      "",
      "columns-start / span 4",
      "columns-start / span 3",
    ],
  },
  rightColumn: {
    gridColumn: [
      "columns-start / columns-end",
      "",
      "columns-start / span 4",
      "5 / span 3",
    ],
    marginTop: ["24px", "", "", "0"],
  },
};

const textRightStyles = {
  fullColumn: {
    gridColumn: ["columns-start / columns-end", "", "6 / span 4", "8 / span 6"],
  },
  leftColumn: {
    marginBottom: ["1em", "", "", "0"],
    gridColumn: ["columns-start / columns-end", "", "6 / span 4", "8 / span 3"],
  },
  rightColumn: {
    gridColumn: [
      "columns-start / columns-end",
      "",
      "6 / span 4",
      "11 / span 3",
    ],
    marginTop: ["24px", "", "", "0"],
  },
};

const textTopStyles = {
  gridRow: ["", "", "", "2 / 3"],
};
const textBottomStyles = {
  gridRow: ["", "", "", "3 / 4"],
};

const listItemStyles = {
  ul: {
    marginBottom: 0,
    li: {
      listStyle: "disc",
      marginBottom: 0,
      marginLeft: "18px",
    },
  },
};

const HeroWithColumns = ({ data }) => {
  return (
    <StyledHero as="section" sx={{ marginTop: ["120px", "", "28px", "32px"] }}>
      <Grid label="internal-hero">
        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              "4 / span 1",
              "5 / span 1",
            ],
          }}
        >
          <Box
            sx={{
              writingMode: ["", "", "vertical-lr"],
              transform: ["", "", "scale(-1, -1)"],
              width: ["", "", "", "100%"],
              marginBottom: ["24px", "", "0px"],
            }}
          >
            <TextBlock as="span">{data.label}</TextBlock>
          </Box>
        </Box>
        <Heading.H1Variable
          text={data.longTitle}
          sx={{
            marginBottom: ["100px", "", "", data.image ? "375px" : ""],
            gridColumn: [
              "columns-start / columns-end",
              "",
              "5 / span 4",
              data.longTitle.length > 27 ? "span 6 / -3" : "span 5 / -4",
              data.longTitle.length > 27 ? "span 6 / -3" : "span 5 / -4",
            ],
          }}
        />
        {data.image && (
          <Box
            sx={{
              marginBottom: ["48px", "", "0"],
              ...(data.imagePosition === "left"
                ? imageLeftStyles
                : imageRightStyles),
            }}
          >
            <ImageBlock
              data={{
                image: { url: data.image.public_id, alt: data.imageAltText },
                maskColour: data.maskColour,
              }}
            />
          </Box>
        )}

        {data.heroColumn1 && data.heroColumn2 ? (
          <>
            <Box
              sx={{
                ...(data.imagePosition !== "left"
                  ? textLeftStyles.leftColumn
                  : textRightStyles.leftColumn),
                ...(data.textAlignment === "top"
                  ? textTopStyles
                  : textBottomStyles),
                ...listItemStyles,
              }}
            >
              <RichText content={data.heroColumn1} />
            </Box>
            <Box
              sx={{
                ...(data.imagePosition !== "left"
                  ? textLeftStyles.rightColumn
                  : textRightStyles.rightColumn),
                ...(data.textAlignment === "top"
                  ? textTopStyles
                  : textBottomStyles),
                ...listItemStyles,
              }}
            >
              <RichText content={data.heroColumn2} />
            </Box>
          </>
        ) : (
          data.heroColumn1 && (
            <Box
              sx={{
                ...(data.imagePosition !== "left"
                  ? textLeftStyles.fullColumn
                  : textRightStyles.fullColumn),
                ...(data.textAlignment === "top"
                  ? textTopStyles
                  : textBottomStyles),
                ...listItemStyles,
              }}
            >
              <RichText content={data.heroColumn1} />
            </Box>
          )
        )}
      </Grid>
    </StyledHero>
  );
};

export default HeroWithColumns;

import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { Grid } from "../../styles/layout";
import { Heading } from "../../components/shared/typography/Heading";
import TextBlock from "../shared/typography/TextBlock";

const StyledHero = styled(Box)`
  label: hero-heading;
`;

const HeroHeading = ({ data }) => {
  return (
    <StyledHero as="section" sx={{ marginTop: ["120px", "", "28px", "32px"] }}>
      <Grid>
        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              "4 / span 1",
              "5 / span 1",
            ],
          }}
        >
          <Box
            sx={{
              writingMode: ["", "", "vertical-lr"],
              transform: ["", "", "scale(-1, -1)"],
              width: ["", "", "", "100%"],
              marginBottom: ["24px", "", "0px"],
              paddingBottom: ["", "", "6px"],
            }}
          >
            <TextBlock>{data.label}</TextBlock>
          </Box>
        </Box>
        <Heading.H1Variable
          text={data.h1}
          sx={{
            marginBottom: ["100px", "", "", "375px"],
            gridColumn: [
              "columns-start / columns-end",
              "",
              "5 / span 4",
              data.h1.length > 27 ? "span 6 / -3" : "span 5 / -4",
              data.h1.length > 27 ? "span 6 / -3" : "span 5 / -4",
            ],
          }}
        />
      </Grid>
    </StyledHero>
  );
};

export default HeroHeading;

import styled from "@emotion/styled";
import { Box } from "theme-ui";

const StyledGrid = styled(Box)`
  label: ${(props) => props.label};
  margin: 0 auto;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1920px;
  display: grid;
  /* border: 1px dashed red; */

  // Mobile
  grid-template-columns:
    [left-margin] calc(4px) [columns-start] repeat(4, minmax(20px, 1fr))
    [columns-end] calc(4px) [right-margin];
  grid-column-gap: 20px;
  grid-row-gap: 0px;

  // Tablet
  @media only screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns:
      [left-margin] calc(32px - 24px) [columns-start] repeat(
        8,
        minmax(20px, 1fr)
      )
      [columns-end] calc(32px - 24px) [right-margin];
    grid-column-gap: 24px;
    grid-row-gap: 0px;
  }

  // Desktop
  @media only screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    grid-template-columns:
      [left-margin] calc(64px - 32px) [columns-start] repeat(
        12,
        minmax(20px, 1fr)
      )
      [columns-end] calc(64px - 32px) [right-margin];
    grid-column-gap: 32px;
    grid-row-gap: 0px;
  }
`;

const Grid = ({ label, children, sx, id }) => {
  const gridLabel = label ? `grid-${label}` : "grid";
  return (
    <StyledGrid label={gridLabel} id={id} sx={{ ...sx }}>
      {children}
    </StyledGrid>
  );
};

const StyledInnerGrid = styled(Box)`
  label: ${(props) => props.label};
  margin: 0 auto;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1920px;
  display: grid;
  /* border: 1px dashed red; */

  // Mobile
  grid-template-columns:
    [columns-start] repeat(4, minmax(20px, 1fr))
    [columns-end];
  grid-column-gap: 20px;
  grid-row-gap: 0px;

  // Tablet
  @media only screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns:
      [columns-start] repeat(8, minmax(20px, 1fr))
      [columns-end];
    grid-column-gap: 24px;
    grid-row-gap: 0px;
  }

  // Desktop
  @media only screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    grid-template-columns:
      [columns-start] repeat(12, minmax(20px, 1fr))
      [columns-end];
    grid-column-gap: 32px;
    grid-row-gap: 0px;
  }
`;

const InnerGrid = ({ label, children, sx }) => {
  const gridLabel = label ? `grid-${label}` : "grid";
  return (
    <StyledInnerGrid label={gridLabel} sx={{ ...sx }}>
      {children}
    </StyledInnerGrid>
  );
};

const StyledInnerGridTabletUp = styled(Box)`
  label: ${(props) => props.label};
  margin: 0 auto;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1920px;
  grid-column: columns-start / columns-end;
  // Tablet
  @media only screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    display: grid;
    grid-template-columns:
      [columns-start] repeat(8, minmax(20px, 1fr))
      [columns-end];
    grid-column-gap: 24px;
    grid-row-gap: 0px;
  }

  // Desktop
  @media only screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    grid-template-columns:
      [columns-start] repeat(12, minmax(20px, 1fr))
      [columns-end];
    grid-column-gap: 32px;
    grid-row-gap: 0px;
  }
`;

const InnerGridTabletUp = ({ label, children, sx }) => {
  const gridLabel = label ? `grid-${label}` : "grid";
  return (
    <StyledInnerGridTabletUp label={gridLabel} sx={{ ...sx }}>
      {children}
    </StyledInnerGridTabletUp>
  );
};

export { Grid, InnerGrid, InnerGridTabletUp };

import { useState, useRef, useEffect, useCallback } from "react";

const lerp = (a, b, n) => (1 - n) * a + n * b;

const cursorItems = [
  ".c-carousel-item",
  ".c-sector-item",
  ".c-case-study-item",
];

export const useCustomCursorBehaviour = (
  speed = 0.3,
  maxSize = 10,
  minSize = 20
) => {
  const [tick, setTick] = useState(0);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const cursor = useRef({ x: 0.5, y: 0.5, size: minSize, isMouseDown: false });
  const target = useRef({ x: 0.5, y: 0.5, size: minSize });
  const raf = useRef(null);

  const render = useCallback(() => {
    cursor.current = {
      x: lerp(cursor.current.x, target.current.x, speed),
      y: lerp(cursor.current.y, target.current.y, speed),
      size: lerp(cursor.current.size, target.current.size, speed),
      isMouseDown: isMouseDown,
    };

    document.documentElement.style.setProperty("--cursor-x", cursor.current.x);
    document.documentElement.style.setProperty("--cursor-y", cursor.current.y);
    document.documentElement.style.setProperty(
      "--cursor-size",
      cursor.current.size
    );

    const delta = Math.sqrt(
      Math.pow(target.current.x - cursor.current.x, 2) +
        Math.pow(target.current.y - cursor.current.y, 2)
    );

    if (delta < 0.001) {
      cancelAnimationFrame(raf.current);
      raf.current = null;
    } else {
      raf.current = requestAnimationFrame(render);
      setTick(tick + 1);
    }
  }, [isMouseDown, speed, tick]);

  const onMouseMove = useCallback(
    (e) => {
      target.current = {
        x: e.clientX / window.innerWidth,
        y: e.clientY / window.innerHeight,
        size: target.current.size,
      };
      if (!raf.current) raf.current = requestAnimationFrame(render);
    },
    [render]
  );

  useEffect(() => {
    window.addEventListener("mousemove", onMouseMove);
    raf.current = requestAnimationFrame(render);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      if (raf.current) cancelAnimationFrame(raf.current);
    };
  }, [onMouseMove, render, speed, tick]);

  const addEventListeners = useCallback(
    (classNames, enterHandler, leaveHandler, downHandler, upHandler) => {
      classNames.forEach((className) => {
        const items = document.querySelectorAll(className);
        items.forEach((item) => {
          item.style.cursor = "none";
          item.addEventListener("mouseenter", enterHandler);
          item.addEventListener("mouseleave", leaveHandler);
          if (className === ".c-carousel-item") {
            item.addEventListener("mousedown", downHandler);
            item.addEventListener("mouseup", upHandler);
          }
        });
      });
    },
    []
  );

  const removeEventListeners = useCallback(
    (classNames, enterHandler, leaveHandler, downHandler, upHandler) => {
      classNames.forEach((className) => {
        const items = document.querySelectorAll(className);
        items.forEach((item) => {
          document.documentElement.style.cursor = "auto";
          item.removeEventListener("mouseenter", enterHandler);
          item.removeEventListener("mouseleave", leaveHandler);
          if (className === ".c-carousel-item") {
            item.removeEventListener("mousedown", downHandler);
            item.removeEventListener("mouseup", upHandler);
          }
        });
      });
    },
    []
  );

  const handleMouseDown = useCallback(() => {
    setIsMouseDown(true);
    target.current.size = maxSize - 40;
    if (!raf.current) raf.current = requestAnimationFrame(render);
  }, [maxSize, render]);

  const handleMouseUp = useCallback(() => {
    setIsMouseDown(false);
    target.current.size = maxSize;
    if (!raf.current) raf.current = requestAnimationFrame(render);
  }, [maxSize, render]);

  const handleMouseEnter = useCallback(() => {
    target.current.size = maxSize;
    document.documentElement.style.cursor = "none";
    if (!raf.current) raf.current = requestAnimationFrame(render);
  }, [maxSize, render]);

  const handleMouseLeave = useCallback(() => {
    document.documentElement.style.cursor = "auto";
    target.current.size = minSize;
  }, [minSize]);

  useEffect(() => {
    addEventListeners(
      cursorItems,
      handleMouseEnter,
      handleMouseLeave,
      handleMouseDown,
      handleMouseUp
    );

    return () => {
      removeEventListeners(
        cursorItems,
        handleMouseEnter,
        handleMouseLeave,
        handleMouseDown,
        handleMouseUp
      );
    };
  }, [
    addEventListeners,
    removeEventListeners,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseDown,
    handleMouseUp,
  ]);

  return { cursor: cursor.current, target: target.current, isMouseDown };
};

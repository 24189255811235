import styled from "@emotion/styled";
import { Box } from "theme-ui";
import TextBlock from "../typography/TextBlock";
import { useInView } from "react-intersection-observer";

const StyledVerticalText = styled(Box)`
  label: vertical-text;
  /* text-decoration: underline; */
  position: relative;
`;

const VerticalText = ({
  children,
  color = "blue",
  flipOnMobile = false,
  sx,
}) => {
  const [ref] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  return (
    <StyledVerticalText
      as="div"
      ref={ref}
      sx={{
        marginBottom: "24px",
        color: color,
        display: "flex",
        width: "fit-content",
        writingMode: flipOnMobile ? ["auto", "", "tb-rl"] : "tb-rl",
        ...sx,
      }}
    >
      <Box
        sx={{
          transform: flipOnMobile
            ? ["none", "", "rotate(180deg)"]
            : "rotate(180deg)",
        }}
      >
        <TextBlock
          variant="text.body.regular"
          color={color}
          sx={{
            position: "relative",
            lineHeight: "1em",
          }}
        >
          {children}
        </TextBlock>
      </Box>
    </StyledVerticalText>
  );
};

export default VerticalText;

import { gql, useQuery } from "@apollo/client";
import HomeHero from "./HomeHero";
import HomeCaseStudies from "./HomeCaseStudies";
import HomeSectors from "./HomeSectors";
import HomeServices from "./HomeServices";
import HomeCarousel from "./HomeCarousel";
import Error from "../Error/Error";
import Loading from "../../components/global/Loader";
import { textRowFrag } from "../../components/content/TextRow";
import { contentRowFrag } from "../../components/content/ContentRow";
import { useDataFetchStatus } from "../../hooks/useDataFetchStatus";
import SEO from "../../components/global/SEO";

const GET_HOMEPAGE_CONTENT = gql`
  ${textRowFrag}
  ${contentRowFrag}
  query HomePageContent($isPreview: Boolean = false) {
    homeHero: homeHero(id: "3FSFVIJhrG4dM2tCGaJFOk") {
      homeHeading
      homeHeroIntro {
        json
      }
      homeHeroColumnLeft {
        json
      }
      homeHeroColumnRight {
        json
      }
      homeHeroImage
      homeHeroImageMaskColour
      homeHeroImageAltText
    }
    servicesFeed: feedCollection(
      where: { sys: { id: "3Q8km16Oei4ocLOhNFzafe" } }
      preview: $isPreview
    ) {
      items {
        feedItemsCollection(limit: 3) {
          items {
            ... on FeedItem {
              relatedEntry {
                __typename
                ... on BlockContentRow {
                  ...contentRowFrag
                }
              }
            }
          }
        }
      }
    }
    sectorsContent: blockTextRow(
      id: "1OtR0zpFwa6USFMSOxSSsi"
      preview: $isPreview
    ) {
      ...textRowFrag
    }
    sectorsFeed: feedCollection(
      where: { sys: { id: "4DSePQtFOYq4SYyQv7dgPB" } }
      preview: $isPreview
    ) {
      items {
        feedItemsCollection(limit: 12) {
          items {
            ... on Sector {
              title
              slug
              heroImage
              heroImageAltText
              heroImageMaskColour
            }
          }
        }
      }
    }
    insightsFeed: feedCollection(
      where: { sys: { id: "6G57Gz4uVRa8bBnGWi0WF" } }
      preview: $isPreview
    ) {
      items {
        feedItemsCollection(limit: 4) {
          items {
            ... on FeedItem {
              title
              label
              body: paragraphText
              image
              imageAltText
              imageMaskColour
              url
            }
          }
        }
      }
    }
    caseStudyFeed: feedCollection(
      where: { sys: { id: "2jxO5sGlDafCvvw1YdpMY2" } }
      preview: $isPreview
    ) {
      items {
        feedItemsCollection(limit: 4) {
          items {
            ... on FeedItem {
              title
              label
              image
              imageAltText
              url
              imageMaskColour
            }
          }
        }
      }
    }
  }
`;

// const hero = {
//   h1: "Together,\n we will uncover and shape next-generation  human  prosperity.",
//   image: {
//     url: "pm-website-v4/home/home-hero-lucy",
//     alt: "Young woman works on whiteboard",
//     maskColour: "#ADC9CE",
//   },
//   text: [
//     `We are a strategy and design consultancy assisting purpose-led, visionary organisations that want to progress, re-frame and connect more genuinely with the people they serve.`,
//   ],
//   columns: [
//     [
//       `We partner with Australia’s most ambitious teams to reframe challenge spaces and create genuine opportunities for lasting engagement.`,
//       `Through deep collaboration, we’re reimagining products, services, systems and brands that deliver equitable results and create stronger relationships between organisations and people.`,
//     ],
//     [
//       `The work we do is born from the collision of vision and action. It’s predicated on rapidly changing environments and evolving human needs, which demand highly bespoke, yet adaptable outcomes.`,
//     ],
//   ],
// };

const seo = {
  title: "Prime Motive is a Strategic Design Agency | Melbourne & Sydney",
  content:
    "Prime Motive is a Strategic Design and Research company based in Melbourne. We combine Strategic Design, Human-Centred Design and Design Thinking to deliver impact, growth, and lasting engagement between people and organisations.",
  url: "https://primemotive.com.au",
};

const Home = ({ isPreview }) => {
  const { loading, error, data } = useQuery(GET_HOMEPAGE_CONTENT, {
    variables: { isPreview: isPreview },
  });

  useDataFetchStatus(loading, error);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <>
      <SEO title={seo.title} description={seo.description} url={seo.url} />
      <HomeHero
        data={{
          hero: data.homeHero,
        }}
      />
      <HomeCaseStudies
        data={{
          caseStudies: data.caseStudyFeed.items[0].feedItemsCollection,
        }}
      />
      <HomeServices
        data={{
          services: data.servicesFeed.items[0].feedItemsCollection,
        }}
      />
      <HomeSectors
        data={{
          sectors: data.sectorsFeed.items[0].feedItemsCollection,
          textRowContent: data.sectorsContent,
        }}
      />
      <HomeCarousel
        data={{ carouselItems: data.insightsFeed.items[0].feedItemsCollection }}
      />
    </>
  );
};

export default Home;

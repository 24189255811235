import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box } from "theme-ui";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { Grid } from "../../styles/layout";
import { Heading } from "../../components/shared/typography/Heading";
import Error from "../../pages/Error/Error";
import Loading from "../../components/global/Loader";
import HorizontalLine from "../../components/shared/decorative/HorizontalLine";
import CardGrid from "../../components/shared/grids/CardGrid";
import Spacer from "../../components/utility/Spacer";
import UpdateOnScroll from "../../components/utility/UpdateOnScroll";
import { useDataFetchStatus } from "../../hooks/useDataFetchStatus";
import { useIsValidURL } from "../../hooks/useIsValidURL";
import SEO from "../../components/global/SEO";

import HeroSingleColumn from "../../components/heros/HeroSingleColumn";
import TextRow, {
  mapTextRowData,
  textRowFrag,
} from "../../components/content/TextRow";
import PullQuote, {
  mapPullQuoteData,
  pullQuoteFrag,
} from "../../components/content/PullQuote";
import ImageBlock, {
  mapImageData,
  imageFrag,
} from "../../components/media/ImageBlock";
import ImageCluster, {
  mapImageClusterData,
  imageClusterFrag,
} from "../../components/media/ImageCluster";
import ImageCarousel, {
  mapImageCarouselData,
  imageCarouselFrag,
} from "../../components/media/ImageCarousel";
import FeatureCaseStudy, {
  mapFeatureCaseStudyData,
  featureCaseStudyFrag,
} from "../../components/content/FeatureCaseStudy";
import ContentCarousel, {
  mapContentCarouselData,
  contentCarouselFrag,
} from "../../components/content/ContentCarousel";
import TextWithImage, {
  textWithImageFrag,
  mapTextWithImageData,
} from "../../components/content/TextWithImage";
import TextColumns, {
  mapTextColumnsData,
  textColumnsFrag,
} from "../../components/content/TextColumns";

const GET_SERVICE_BY_SLUG = gql`
  ${textRowFrag}
  ${imageFrag}
  ${imageCarouselFrag}
  ${imageClusterFrag}
  ${pullQuoteFrag}
  ${featureCaseStudyFrag}
  ${contentCarouselFrag}
  ${textWithImageFrag}
  ${textColumnsFrag}
  ${textWithImageFrag}
  query ServicePage($slug: String!, $isPreview: Boolean = false) {
    serviceCollection: categoryCollection(
      where: { slug: $slug }
      preview: $isPreview
    ) {
      items {
        title
        slug
        longTitle
        seoTitle
        seoDescription
        heroImage
        heroImageAltText
        heroImageMaskColour
        heroColumnBody {
          json
        }
        heroColumnLead {
          json
        }
        contentBlocksTopSectionCollection(limit: 10) {
          items {
            __typename
            ... on BlockFeatureCaseStudy {
              ...featureCaseStudyFrag
            }
            ... on BlockContentCarousel {
              ...contentCarouselFrag
            }
            ... on BlockTextRow {
              ...textRowFrag
            }
            ... on BlockPullQuote {
              ...pullQuoteFrag
            }
            ... on BlockImage {
              ...imageFrag
            }
            ... on BlockImageCluster {
              ...imageClusterFrag
            }
            ... on BlockImageGallery {
              ...imageCarouselFrag
            }
            ... on BlockTextColumns {
              ...textColumnsFrag
            }
            ... on BlockTextWithImage {
              ...textWithImageFrag
            }
          }
        }
        contentBlocksBottomSectionCollection(limit: 10) {
          items {
            __typename
            ... on BlockTextRow {
              ...textRowFrag
            }
            ... on BlockPullQuote {
              ...pullQuoteFrag
            }
            ... on BlockImage {
              ...imageFrag
            }
            ... on BlockImageCluster {
              ...imageClusterFrag
            }
            ... on BlockImageGallery {
              ...imageCarouselFrag
            }
            ... on BlockTextWithImage {
              ...textWithImageFrag
            }
          }
        }
        insightsHeading
        insightsCollection(limit: 4) {
          items {
            title
            label
            image
            url
          }
        }
      }
    }
  }
`;

const imageComponentTypes = [
  "BlockImage",
  "BlockImageCluster",
  "BlockImageGallery",
];

const blockComponent = (component, keyIndex, prevComponent, dividers) => {
  switch (component.__typename) {
    case "BlockTextRow":
      const updatedComponent = Object.assign({ dividers: dividers }, component);
      return (
        <React.Fragment key={keyIndex}>
          {imageComponentTypes.includes(prevComponent.__typename) ? (
            <Spacer short={true} />
          ) : (
            <Spacer />
          )}
          <TextRow data={mapTextRowData(updatedComponent)} />
        </React.Fragment>
      );
    case "BlockPullQuote":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <PullQuote key={keyIndex} data={mapPullQuoteData(component)} />
        </React.Fragment>
      );
    case "BlockImage":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <Grid key={keyIndex}>
            <Box sx={{ gridColumn: "columns-start / columns-end" }}>
              <ImageBlock data={mapImageData(component)} />
            </Box>
          </Grid>
        </React.Fragment>
      );
    case "BlockImageCluster":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <ImageCluster key={keyIndex} data={mapImageClusterData(component)} />
        </React.Fragment>
      );
    case "BlockImageGallery":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <ImageCarousel
            key={keyIndex}
            data={mapImageCarouselData(component)}
          />
        </React.Fragment>
      );
    case "BlockFeatureCaseStudy":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <FeatureCaseStudy
            key={keyIndex}
            data={mapFeatureCaseStudyData(component)}
          />
        </React.Fragment>
      );

    case "BlockContentCarousel":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <ContentCarousel
            key={keyIndex}
            data={mapContentCarouselData(component)}
          />
        </React.Fragment>
      );
    case "BlockTextWithImage":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <TextWithImage data={mapTextWithImageData(component)} />
        </React.Fragment>
      );
    case "BlockTextColumns":
      return (
        <React.Fragment key={keyIndex}>
          <Spacer />
          <TextColumns data={mapTextColumnsData(component)} />
        </React.Fragment>
      );
    default:
      return null;
  }
};

const Service = ({ slugAsProp, isPreview }) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_SERVICE_BY_SLUG, {
    variables: { slug: slug || slugAsProp, isPreview: isPreview },
  });

  const isValidURL = useIsValidURL(
    loading,
    data,
    navigate,
    "serviceCollection"
  );

  useDataFetchStatus(loading, error);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (loading === false && isValidURL === true) {
    const service = data.serviceCollection.items[0];
    const topContent = service.contentBlocksTopSectionCollection.items;
    const bottomContent = service.contentBlocksBottomSectionCollection.items;
    const insights = service.insightsCollection.items;
    const heroData = {
      label: "Service",
      longTitle: service.longTitle,
      image: service.heroImage && service.heroImage[0],
      imageAltText: service.heroImageAltText,
      maskColour: service.heroImageMaskColour,
      heroColumnBody: service.heroColumnBody,
      heroColumnLead: service.heroColumnLead,
      imagePosition: "right",
    };

    const seo = {
      title: service.seoTitle
        ? service.seoTitle
        : `${service.title} | Prime Motive`,
      description: service.seoDescription
        ? service.seoDescription
        : service.longTitle,
      url: `https://primemotive.com.au/${slugAsProp}`,
    };

    return (
      <>
        <SEO title={seo.title} description={seo.description} url={seo.url} />
        <HeroSingleColumn data={heroData} />
        {topContent.length > 0 && (
          <Box>
            {topContent.map((item, index) =>
              blockComponent(
                item,
                index,
                index !== 0 && topContent[index - 1],
                false
              )
            )}
          </Box>
        )}
        <Spacer short />
        <Spacer short />
        {(bottomContent.length > 0 ||
          service.insightsCollection.items.length > 0) && (
          <>
            <UpdateOnScroll.Sentinel />
            {bottomContent &&
              bottomContent.map((item, index) =>
                blockComponent(
                  item,
                  index,
                  index !== 0 && bottomContent[index - 1],
                  false
                )
              )}
            {insights.length > 0 && (
              <>
                <Spacer />
                <HorizontalLine />
                <Grid>
                  <Heading.H2
                    text={
                      service.insightsHeading
                        ? service.insightsHeading
                        : "Insights and past work"
                    }
                    sx={{
                      marginBottom: ["70px", "", "", "150px"],
                      gridColumn: [
                        "columns-start / columns-end",
                        "",
                        "columns-start / span 5",
                        "5 / span 4",
                      ],
                    }}
                  />
                </Grid>
                <CardGrid data={service.insightsCollection} />
              </>
            )}
          </>
        )}
        <Spacer />
      </>
    );
  } else {
    return null;
  }
};

export default Service;

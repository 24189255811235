import { useRef, useCallback, useLayoutEffect, useState } from "react";
import { gql } from "@apollo/client";
import { Box } from "theme-ui";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CloudinaryImage from "../shared/cloudinary/CloudinaryImage";
import { Grid } from "../../styles/layout";
import TextBlock from "../shared/typography/TextBlock";
import OpacityWrap from "../utility/OpacityWrap";
import RichText from "../shared/richtext/RichText";
import { debounce } from "../../scripts/helpers";
gsap.registerPlugin(ScrollTrigger);

// This should be the only place to add/edit/delete the fields for this component
// Import this fragment into your template where you are using this component.
export const imageScrollContentFrag = gql`
  fragment imageScrollContentFrag on BlockScrollJackPanel {
    heading
    body {
      json
    }
    image
    imagesAltText
  }
`;

export const mapImageScrollContentData = (data) => {
  return {
    heading: data.heading,
    body: data.body,
    image: data.image,
    imagesAltText: data.imagesAltText,
  };
};

const imageScrollContentStyles = {
  paddingTop: ["100px", "", "150px"],
  paddingBottom: ["100px", "", "150px"],
};

const textContainerStyles = {
  gridColumn: ["columns-start / columns-end", "", "2 / span 4", "3 / span 4"],
  marginBottom: ["32px", "", "0", ""],
  maxWidth: ["", "", "510px"],
};

const imageStyles = {
  gridColumn: ["columns-start / columns-end", "", "6 / span 4", "7 / span 7"],
  lineHeight: 0,
  padding: 0,
};

const topImageStyles = {
  borderTopLeftRadius: ["16px", "", "20px", "", "30px"],
  borderTopRightRadius: ["16px", "", "20px", "", "30px"],
};

const bottomImageStyles = {
  borderBottomLeftRadius: ["16px", "", "20px", "", "30px"],
  borderBottomRightRadius: ["16px", "", "20px", "", "30px"],
};

const ImageScrollContent = ({ data }) => {
  const [loadedImages, setLoadedImages] = useState({});
  const imageRef = useRef();
  const scrollContainer = useRef(null);
  const pinContent = useRef(null);
  const scrollTriggerInstance = useRef(null);
  const resizeObserver = useRef(null);

  const handleImageLoad = useCallback((src) => {
    setLoadedImages((prevState) => {
      if (!prevState[src]) {
        return {
          ...prevState,
          [src]: true,
        };
      }
      return prevState;
    });
  }, []);

  const pinText = useCallback((containerEl, pinEl) => {
    ScrollTrigger.matchMedia({
      "(min-width: 768px)": function () {
        const scrollTrigger = ScrollTrigger.create({
          trigger: containerEl,
          start: "top 0px",
          end: `bottom ${pinEl.clientHeight + 300}px`,
          pin: pinEl,
        });
        scrollTriggerInstance.current = scrollTrigger;
      },
    });
  }, []);

  useLayoutEffect(() => {
    if (Object.keys(loadedImages).length !== data.image.length) return;
    if (!pinContent.current || !scrollContainer.current) return;

    const pinEl = pinContent.current;
    const containerEl = scrollContainer.current;

    pinText(containerEl, pinEl);

    return () => {
      if (scrollTriggerInstance.current) {
        scrollTriggerInstance.current.kill();
        scrollTriggerInstance.current = null;
      }
    };
  }, [data.image.length, loadedImages, pinText]);

  useLayoutEffect(() => {
    const containerEl = scrollContainer.current;
    if (containerEl) {
      resizeObserver.current = new ResizeObserver(
        debounce(() => {
          if (scrollTriggerInstance.current) {
            scrollTriggerInstance.current.refresh();
          }
        }),
        500
      );

      resizeObserver.current.observe(containerEl);

      return () => {
        if (resizeObserver.current) {
          resizeObserver.current.unobserve(containerEl);
          resizeObserver.current.disconnect();
        }
      };
    }
  }, []);

  return (
    <Box
      sx={{
        ...imageScrollContentStyles,
        background: data.bg,
      }}
      ref={scrollContainer}
      bg={data.bg}
    >
      <Grid>
        <Box sx={{ ...textContainerStyles }}>
          <Box
            className="text-ref"
            sx={{
              "> *": { paddingRight: ["", "", "2.5%"] },
            }}
            ref={pinContent}
          >
            {data.heading && (
              <TextBlock
                sx={{
                  fontWeight: "semiBold",
                  marginBottom: ["24px", "", "34px"],
                }}
              >
                {data.heading}
              </TextBlock>
            )}
            <RichText content={data.body} />
          </Box>
        </Box>
        <Box sx={{ ...imageStyles }}>
          <div className="inner-image">
            <OpacityWrap duration={0.4}>
              {data.image.map((item, index) => (
                <Box
                  sx={{
                    mb: "10px",
                    img: {
                      borderRadius: 0,
                    },
                    "&:first-of-type": {
                      img: {
                        ...topImageStyles,
                      },
                    },
                    "&:last-of-type": {
                      img: {
                        ...bottomImageStyles,
                        mb: "0px",
                      },
                    },
                  }}
                  key={index}
                >
                  <CloudinaryImage
                    src={item.public_id}
                    alt={data.imagesAltText[index]}
                    key={index}
                    ref={imageRef}
                    onLoad={() => handleImageLoad(item.public_id)}
                  />
                </Box>
              ))}
            </OpacityWrap>
          </div>
        </Box>
      </Grid>
    </Box>
  );
};

export default ImageScrollContent;

// Reference: https://theme-ui.com/theme-spec#color
// The theme.colors scale (i.e. color palette) should be an object literal with the following keys.
export const colors = {
  background: "#FFFFFF",

  // Base Colours
  white: "#FFFFFF",

  blue: "#050514",
  blueStatic: "#050514",
  blueLight: "#32323E",
  navy: "#32323E",
  navyLight: "",

  red: "#F02828",
  redLight: "#FFE1DF",
  redLightTwo: "#F4C2C7",

  yellow: "#FFD214",
  yellowLight: "#FAF6E1",

  grey: "#EBEBEB",
  greyLight: "#F5F5F5",
  greyDark: "#AFAFB6",

  coolMint: "#E0E8E9",

  brown: "#EDE8E0",

  dot: "#F02828",

  modes: {
    // Solid
    light: {
      background: "#FFFFFF",
      blue: "#050514",
      white: "#FFFFFF",
      dot: "#F02828",
    },
    dark: {
      background: "#050514",
      blue: "#FFFFFF",
      white: "#050514",
      dot: "#FFD214",
    },
    home: {
      background: "#EDE8E0",
    },

    // Soft
    grey: {
      background: "#F8F8F8",
    },
    red: {
      background: "#FFEDED",
    },
    purple: {
      background: "#FBF6FF",
    },
    blue: {
      background: "#F4FBFF",
    },
    green: {
      background: "#F7FFF6",
    },
    yellow: {
      background: "#FFFDF2",
    },
    orange: {
      background: "#FFF1E9",
    },
    coolMint: {
      background: "#E0E8E9",
    },
    brown: {
      background: "#EDE8E0",
    },
  },
};

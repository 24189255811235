import { gql, useQuery } from "@apollo/client";
import SEO from "../../components/global/SEO";

import HeroHeading from "../../components/heros/HeroHeading";
import Spacer from "../../components/utility/Spacer";
import Error from "../Error/Error";
import Loading from "../../components/global/Loader";
import { useDataFetchStatus } from "../../hooks/useDataFetchStatus";
import ContentRow, {
  mapContentRowData,
  contentRowFrag,
} from "../../components/content/ContentRow";
const GET_SECTORS_PAGE = gql`
  ${contentRowFrag}
  query SectorsLandingPage($isPreview: Boolean = false) {
    rowList(id: "3Q4r7NGBvT0H5rSd0N0pUu", preview: $isPreview) {
      rowsCollection {
        items {
          __typename
          ... on BlockContentRow {
            ...contentRowFrag
          }
        }
      }
    }
  }
`;

const seo = {
  title: "Sectors | Prime Motive",
  description:
    "We collaborate with leading organisations across industry segments including fintech, educational organisations, non-for-profits, start ups, government agencies and others.",
  url: "https://primemotive.com.au/sectors",
};

const SectorsLandingPage = ({ isPreview }) => {
  const { loading, error, data } = useQuery(GET_SECTORS_PAGE, {
    variables: { isPreview: isPreview },
  });

  useDataFetchStatus(loading, error);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  const sectors = data.rowList.rowsCollection.items;

  return (
    <>
      <SEO title={seo.title} description={seo.description} url={seo.url} />
      <HeroHeading
        data={{
          label: "Sectors",
          h1: "Industry and Impact area",
        }}
      />
      {sectors.map((sector, index) => (
        <ContentRow
          key={index}
          data={mapContentRowData({
            ...sector,
            verticalText: `${index + 1}`,
          })}
        />
      ))}
      <Spacer short="true" />
    </>
  );
};

export default SectorsLandingPage;

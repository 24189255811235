import { gql } from "@apollo/client";
import { Box } from "theme-ui";
import { Grid } from "../../styles/layout";
import ImageBlock from "./ImageBlock";

// This should be the only place to add/edit/delete the fields for this component
// Import this fragment into your template where you are using this component.
export const imageClusterFrag = gql`
  fragment imageClusterFrag on BlockImageCluster {
    title
    images
    imagesAltText
    maskColour
  }
`;

export const mapImageClusterData = (data) => {
  return {
    title: data.title,
    images: data.images,
    imageAltText: data.imagesAltText,
    maskColour: data.maskColour,
  };
};

const clusterGridStyles = {
  gridColumn: ["columns-start / columns-end", "", "3 /-3"],
  display: "grid",
  gridTemplateColumns: ["100%", "", "", "repeat(2,1fr)"],
  gridGap: ["20px", "24px", "32px"],
  img: {
    borderRadius: ["16px", "", "20px", "", "30px"],
  },
  "div:nth-of-type(odd)": {
    gridColumn: ["", "", "", "1"],
  },
  "div:nth-of-type(even)": {
    gridColumn: ["", "", "", "2"],
    transform: ["", "", "", "translateY(25%)"],
  },
};

const isOddImageStyles = {
  "div:nth-of-type(even)": {
    transform: ["", "", "", "translateY(50%)"],
  },
};

const ImageCluster = ({ data }) => {
  return (
    <Grid>
      <Box
        sx={{
          ...clusterGridStyles,
          paddingBottom: ["", "", "", data.images.length % 2 === 0 && "12.5%"],
          ...(data.images.length % 2 !== 0 ? isOddImageStyles : {}),
        }}
      >
        {data.images.map((image, i) => (
          <Box>
            <ImageBlock
              key={i}
              data={{
                image: {
                  url: image.public_id,
                  alt: data.imageAltText && data.imageAltText[i],
                },
                maskColour: data.maskColour,
              }}
            />
          </Box>
        ))}
      </Box>
    </Grid>
  );
};

export default ImageCluster;

import { Grid } from "../../styles/layout";
import { Heading } from "../../components/shared/typography/Heading";
import { Box, Text } from "theme-ui";
import { useContext } from "react";
import { StoreContext } from "../../store/store";
import { useStaticPageStatus } from "../../hooks/useStaticPageStatus";
import SEO from "../../components/global/SEO";

const Error = () => {
  const store = useContext(StoreContext);
  const { graphQLErrors, networkError } = store.getErrors;

  const errors = graphQLErrors || networkError;

  useStaticPageStatus(true);

  return (
    <>
      <SEO noIndex={true} />
      <Grid label="error-page" sx={{ pt: ["128px", "", "", "32px"] }}>
        <Heading.H1
          text="Sorry, an error has occured. Please contact us."
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              "columns-start / span 6",
              "7 / -3",
            ],
          }}
        />
        {errors && (
          <Text
            sx={{
              paddingTop: "64px",
              gridColumn: [
                "columns-start / columns-end",
                "",
                "columns-start / span 6",
                "7 / -3",
              ],
            }}
          >
            {networkError && (
              <Text
                as="pre"
                sx={{
                  paddingBottom: "24px",
                }}
              >
                <b>Network error(s):</b>
                <p>{networkError}</p>
              </Text>
            )}

            {graphQLErrors && (
              <Text as="pre">
                <b>GraphQL errors(s):</b>
                <ul>
                  {graphQLErrors.map((error, index) => (
                    <li key={index}>{error.message}</li>
                  ))}
                </ul>
              </Text>
            )}
          </Text>
        )}

        <Box
          marginBottom="64px"
          sx={{ gridColumn: ["columns-start / columns-end"] }}
        >
          &nbsp;
        </Box>
      </Grid>
    </>
  );
};

export default Error;

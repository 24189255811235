import React, { useState } from "react";
import { gql } from "@apollo/client";
import { Link } from "react-router-dom";
import { Box, Text } from "theme-ui";
import { HashLink } from "react-router-hash-link";
import { Grid } from "../../styles/layout";
import HorizontalLine from "../shared/decorative/HorizontalLine";
import VerticalText from "../shared/decorative/VerticalText";
import { Heading } from "../shared/typography/Heading";
import TextBlock from "../shared/typography/TextBlock";
import { Button, InlineLink } from "../shared/ctas/Button";
import Spacer from "../utility/Spacer";
import { isNumber, isExternalLink } from "../../scripts/helpers";

// Need to add a '/' for the to property for InlineLink when using the slug in servicesCollection
// https://stackoverflow.com/questions/53654151/react-appends-link-to-link-url-to-the-present-path-of-the-component

export const contentRowFrag = gql`
  fragment contentRowFrag on BlockContentRow {
    heading
    description
    ctaText
    ctaUrl
    ctaDescriptiveLabel
    descriptionLinksCollection(limit: 3) {
      items {
        __typename
        ... on Sector {
          title
          slug
        }
        ... on Category {
          title
          slug
        }
      }
    }
  }
`;

export const mapContentRowData = (data) => {
  return {
    verticalText: data.verticalText,
    heading: data.heading,
    descriptionLinksCollection: data.descriptionLinksCollection,
    description: data.description,
    ctaText: data.ctaText,
    ctaDescriptiveLabel: data.ctaDescriptiveLabel,
    ctaUrl: data.ctaUrl,
  };
};

const ContentRow = ({ data }) => {
  const [isExternal] = useState(isExternalLink(data.ctaUrl));
  // console.log(data.ctaDescriptiveLabel);
  return (
    <Box>
      <HorizontalLine />
      {data.verticalText && (
        <Grid sx={{ position: "relative", height: "0" }}>
          <Box
            sx={{
              display: ["none", "", "block"],
              gridRow: ["", "", "1"],
              gridColumn: "columns-start / columns-end",
              height: isNumber(data.verticalText) ? "" : ["", "", "1px"],
              pointerEvents: "none",
            }}
          >
            {isNumber(data.verticalText) ? (
              <Text>{data.verticalText}</Text>
            ) : (
              <VerticalText flipOnMobile>{data.verticalText}</VerticalText>
            )}
          </Box>
        </Grid>
      )}
      <Grid label="content-row">
        <Box
          sx={{
            gridRow: ["", "", "1"],
            gridColumn: ["columns-start / columns-end", "", "3 / span 4", ""],
            marginBottom: ["32px", "", "24px", 0],
          }}
        >
          <Heading.H2 text={data.heading} />
        </Box>
        {data.descriptionLinksCollection &&
          data.descriptionLinksCollection.items.length !== 0 && (
            <Box
              sx={{
                gridRow: ["", "", "2", "1"],
                gridColumn: [
                  "columns-start / columns-end",
                  "",
                  "3 / span 3",
                  "8 / span 3",
                ],
                marginBottom: ["48px", "", 0],
                maxWidth: ["", "", "306px", "none"],
              }}
            >
              <TextBlock>
                {data.descriptionLinksCollection.items.map((service, i) => (
                  <React.Fragment key={i}>
                    {i ? ", " : ""}
                    <InlineLink to={`/${service.slug}`} text={service.title} />
                  </React.Fragment>
                ))}
              </TextBlock>
            </Box>
          )}
        {data.description && (
          <Box
            sx={{
              gridRow: ["", "", "2", "1"],
              gridColumn: [
                "columns-start / columns-end",
                "",
                "3 / span 4",
                "8 / span 3",
              ],
              marginBottom: ["48px", "", 0],
              maxWidth: ["", "", "306px", "none"],
            }}
          >
            <TextBlock variant="text.body.regular" sx={{}}>
              {data.description}
            </TextBlock>
          </Box>
        )}
        <Box
          sx={{
            gridRow: ["", "", "1"],
            gridColumn: [
              "columns-start / columns-end",
              "span 3 / columns-end",
              "",
              "span 3 / -2",
            ],
          }}
        >
          <Button.Primary
            as={isExternal ? "a" : data.ctaUrl.includes("#") ? HashLink : Link}
            text={data.ctaText}
            to={data.ctaUrl}
            ariaLabel={data.ctaDescriptiveLabel && data.ctaDescriptiveLabel}
            sx={{ maxWidth: ["", "", "", "272px"], ml: ["", "", "", "auto"] }}
          />
        </Box>
      </Grid>
      <Spacer sx={{ height: ["100px", "", "120px", "200px"] }} />
    </Box>
  );
};

export default ContentRow;

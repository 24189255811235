import { createContext } from "react";
import { Cloudinary } from "@cloudinary/url-gen";

const cloudinary = new Cloudinary({
  cloud: {
    cloudName: "primemotive",
  },
});

const CloundinaryContext = createContext(cloudinary);

const CloudinaryProvider = ({ children }) => {
  return (
    <CloundinaryContext.Provider value={cloudinary}>
      {children}
    </CloundinaryContext.Provider>
  );
};

export { CloudinaryProvider, CloundinaryContext };

import { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { routes as routesArray } from "../routes/routes";

import Service from "../pages/Services/Service";
import Sector from "../pages/Sectors/Sector";
const { REACT_APP_PREVIEW } = process.env;

const GET_SERVICES_SECTORS = gql`
  query {
    services: categoryCollection {
      items {
        slug
      }
    }
    sectors: sectorCollection {
      items {
        slug
      }
    }
  }
`;

export const useCreateRoutes = () => {
  const [routes, setRoutes] = useState(null);
  const [services, setServices] = useState(null);
  const [sectors, setSectors] = useState(null);

  const { data, loading, error } = useQuery(GET_SERVICES_SECTORS);

  useEffect(() => {
    if (!loading && data) {
      const { services, sectors } = data;
      const serviceRoutes = services.items.map((service) => {
        return {
          path: `/${service.slug}`,
          component: (
            <Service
              isPreview={REACT_APP_PREVIEW === "true"}
              slugAsProp={`${service.slug}`}
            />
          ),
          public: true,
        };
      });
      const sectorRoutes = sectors.items.map((sector) => {
        return {
          path: `/${sector.slug}`,
          component: (
            <Sector
              isPreview={REACT_APP_PREVIEW === "true"}
              slugAsProp={`${sector.slug}`}
            />
          ),
          public: true,
        };
      });
      setServices(serviceRoutes);
      setSectors(sectorRoutes);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (services && sectors) {
      const combineRoutes = () => {
        let rootRoute = routesArray.findIndex((object) => {
          return object.path === "/:slug";
        });
        let newRoutes = [...services, ...sectors];
        routesArray.splice(rootRoute, 0, ...newRoutes);
        return routesArray;
      };
      setRoutes(combineRoutes);
    }
  }, [services, sectors]);

  return routes;
};

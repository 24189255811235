import { Box } from "theme-ui";
import { Grid } from "../../styles/layout";
import Spacer from "../../components/utility/Spacer";
import TextBlock from "../../components/shared/typography/TextBlock";
// import ImageBlock from "../../components/media/ImageBlock";
import { Heading } from "../../components/shared/typography/Heading";
import ContactInformation from "../../components/content/ContactInformation";
import ContactForm from "./ContactForm";
import { heading } from "./FormContent";
import { useStaticPageStatus } from "../../hooks/useStaticPageStatus";
import SEO from "../../components/global/SEO";

const seo = {
  title: "Contact Us | Prime Motive",
  description: "Let's talk about what's next.",
  url: "https://primemotive.com.au/contact",
};

const Contact = () => {
  useStaticPageStatus(true);
  return (
    <>
      <SEO title={seo.title} description={seo.description} url={seo.url} />
      <Box sx={{ marginTop: ["120px", "", "28px", "32px"] }}>
        <Grid>
          <Box
            sx={{
              gridColumn: [
                "columns-start / columns-end",
                "",
                "4 / span 1",
                "5 / span 1",
              ],
            }}
          >
            <Box
              sx={{
                writingMode: ["", "", "vertical-lr"],
                transform: ["", "", "scale(-1, -1)"],
                width: ["", "", "", "100%"],
                marginBottom: ["24px", "", "0px"],
              }}
            >
              <TextBlock as="span">Contact</TextBlock>
            </Box>
          </Box>
          <Heading.H1Variable
            text={heading}
            sx={{
              marginBottom: ["100px", "", "", "375px"],
              gridColumn: [
                "columns-start / columns-end",
                "",
                "5 / span 4",
                heading > 27 ? "span 6 / -3" : "span 5 / -4",
                heading > 27 ? "span 6 / -3" : "span 5 / -4",
              ],
            }}
          />
        </Grid>
        <ContactInformation />
        <Spacer />
        {/* <Grid>
          <Box sx={{ gridColumn: "columns-start / columns-end" }}>
            <ImageBlock
              data={{
                image: {
                  url: "./pm-website/images/about/carousel-1/about-carousel-image-7_2x",
                  alt: "Contact us",
                },
                maskColour: "#E0E8E9",
              }}
              sx={{ gridColumn: "columns-start / columns-end" }}
            />
          </Box>
        </Grid>
        <Spacer /> */}
        <ContactForm />
        <Spacer />
      </Box>
    </>
  );
};
export default Contact;

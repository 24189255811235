import { gql } from "@apollo/client";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { Grid } from "../../styles/layout";
import { Heading } from "../shared/typography/Heading";
import { Button } from "../shared/ctas/Button";
import ImageBlock from "../media/ImageBlock";
import TextLink from "../shared/typography/TextLink";
import RichText from "../shared/richtext/RichText";
import Spacer from "../utility/Spacer";
import { slugify } from "../../scripts/helpers";

export const genericContentFrag = gql`
  fragment genericContentFrag on BlockGenericContent {
    componentLayout
    image
    imageAltText
    imageMaskColour
    heading
    listOfLinks
    column1 {
      json
    }
    column2 {
      json
    }
    ctaText
    ctaDescriptiveLabel
    ctaUrl
  }
`;

export const mapGenericContentData = (data) => {
  return {
    componentLayout: data.componentLayout,
    image: data.image[0],
    imageAltText: data.imageAltText,
    imageMaskColour: data.imageMaskColour,
    heading: data.heading,
    listOfLinks: data.listOfLinks,
    column1: data.column1,
    column2: data.column2,
    ctaText: data.ctaText,
    ctaUrl: data.ctaUrl,
    ctaDescriptiveLabel: data.ctaDescriptiveLabel,
  };
};

const StyledGenericContent = styled(Box)`
  label: generic-content;
  grid-column: columns-start / columns-end;
`;

const imageLeftStyles = {
  gridColumn: ["columns-start / columns-end", "", "columns-start / span 4", ""],
};

const imageRightStyles = {
  gridColumn: ["columns-start / columns-end", "", "6 / span 4", "10 / span 4"],
};

const headingRightStyles = {
  gridColumn: ["columns-start / columns-end", "", "6 / span 4", "7 / span 6"],
};

const headingLeftStyles = {
  gridColumn: [
    "columns-start / columns-end",
    "",
    "columns-start / span 4",
    "3 / span 6",
  ],
};

const textLeftStyles = {
  gridColumn: [
    "columns-start / columns-end",
    "",
    "columns-start / span 4",
    "3 / span 3",
  ],
};

const textRightStyles = {
  gridColumn: [
    "columns-start / columns-end",
    "",
    "columns-start / span 4",
    "7 / span 3",
  ],
};

const listItemStyles = {
  ul: {
    marginBottom: 0,
    li: {
      listStyle: "disc",
      marginBottom: 0,
      marginLeft: "18px",
    },
  },
};

const GenericContent = ({ data }) => {
  return (
    <StyledGenericContent id={slugify(data.heading)}>
      <Spacer sx={{ height: ["50px", "", "100px", "200px"] }} />
      <Grid label="generic-content" sx={{ gridAutoFlow: "dense" }}>
        <Box
          sx={{
            gridColumn: ["columns-start / columns-end", "", "2 / -2"],
            borderTopColour: "blue",
            borderTopStyle: "solid",
            borderTopWidth: "1px",
          }}
        >
          <Spacer sx={{ height: "32px" }} />
        </Box>
        <Box
          sx={{
            marginBottom: ["50px", "", "100px"],
            ...(data.componentLayout &&
            data.componentLayout.includes("Image Left")
              ? headingRightStyles
              : headingLeftStyles),
          }}
        >
          <Heading.H2 as="h2" text={data.heading} sx={{}} />
        </Box>
        {data.image && (
          <Box
            sx={{
              gridRow: ["", "", "", "3 / span 3"],
              marginBottom: ["48px", "", "100px"],

              img: {
                maxHeight: ["", "", "", "640px"],
                aspectRatio: ["16 / 9", "", "", "3 / 4"],
                objectFit: "cover",
              },
              ...(data.componentLayout &&
              data.componentLayout.includes("Image Left")
                ? imageLeftStyles
                : imageRightStyles),
            }}
          >
            <ImageBlock
              data={{
                image: { url: data.image.public_id, alt: data.imageAltText },
                maskColour: data.imageMaskColour,
              }}
            />
          </Box>
        )}

        {data.column1 && (
          <Box
            sx={{
              ...(data.componentLayout &&
              data.componentLayout.includes("Text Right")
                ? textRightStyles
                : textLeftStyles),
              ...listItemStyles,
            }}
          >
            <RichText content={data.column1} />
          </Box>
        )}
        {data.listOfLinks && (
          <Box
            sx={{
              gridColumn:
                data.componentLayout &&
                data.componentLayout.includes("Image Left")
                  ? [
                      "columns-start / columns-end",
                      "",
                      "6 / span 4",
                      "10 / span 3",
                    ]
                  : [
                      "columns-start / columns-end",
                      "",
                      "6 / span 4",
                      "6 / span 3",
                    ],
            }}
          >
            {data.listOfLinks.map((item, index) => (
              <TextLink
                key={index}
                text={item.text}
                url={item.url}
                secondaryText={item.desc}
                sx={{
                  mt: "48px",
                  display: "inline-block",
                  width: "100%",
                  "&:first-of-type": {
                    mt: ["48px", "", 0],
                  },
                }}
              />
            ))}
          </Box>
        )}
        {data.ctaText && data.ctaUrl && (
          <Button.Tertiary
            sx={{
              mt: ["80px", "", "100px"],
              gridColumn:
                data.componentLayout &&
                data.componentLayout.includes("Image Left")
                  ? [
                      "columns-start / columns-end",
                      "",
                      "",
                      "7 / span 4",
                      "7 / span 3",
                    ]
                  : [
                      "columns-start / columns-end",
                      "",
                      "",
                      "3 / span 4",
                      "3 / span 3",
                    ],
            }}
            text={data.ctaText}
            to={data.ctaUrl}
            ariaLabel={data.ctaDescriptiveLabel}
          />
        )}
      </Grid>
    </StyledGenericContent>
  );
};

export default GenericContent;

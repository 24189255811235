import { useInView } from "react-intersection-observer";
import { Grid } from "../../styles/layout";
import { Box } from "theme-ui";
import SVG from "react-inlinesvg";
import VerticalText from "../shared/decorative/VerticalText";
import TextBlock from "../shared/typography/TextBlock";
import HorizontalLine from "../shared/decorative/HorizontalLine";

const verticalTextStyles = {
  position: "absolute",
  display: ["none", "", "block"],
  gridColumn: ["", "", "2 / columns-end", "2 / columns-end"],
};

const headingStyles = {
  // marginBottom: ["25px", "", "", "50px"],
  // lineHeight: ["26px", "", "", "33px"],
  fontWeight: "body",
};

const inBriefVerticalTextStyles = {
  lineHeight: ["26px", "", "27px", "", "28px"],
  fontSize: ["18px", "", "19px", "", "20px"],
};

const linkStyles = {
  a: {
    color: "blue",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};

const columnOneStyles = {
  gridColumn: [
    "columns-start / columns-end",
    "",
    "3 / span 3",
    "5 / span 3",
    "",
  ],
  marginBottom: ["50px", "", "", 0],
};

const columnTwoStyles = {
  gridColumn: [
    "columns-start / columns-end",
    "",
    "6 / span 3",
    "8 / span 3",
    "",
  ],
  marginBottom: ["50px", "", "", 0],
};

const columnThreeStyles = {
  gridColumn: [
    "columns-start / columns-end",
    "",
    "3 / span 7",
    "11 / span 3",
    "",
  ],
  marginBottom: ["50px", "", "", 0],
};

const columnData = [
  {
    sx: columnOneStyles,
    heading: "Melbourne",
    lines: [
      "Wurundjeri Woi Wurrung Country",
      "Level 17, 21 Queen St, VIC 3000",
    ],
    addressUrl:
      "https://www.google.com/maps/place/17%2F31+Queen+St,+Melbourne+VIC+3000",
    number: "+03 8393 1949",
  },
  {
    sx: columnTwoStyles,
    heading: "Sydney",
    lines: ["Gadigal Country", "Ground Floor, 71 York Street, NSW 2000"],
    addressUrl: "https://goo.gl/maps/LjeVQR5D1hr",
    number: "+02 9057 6419",
  },
  {
    sx: columnThreeStyles,
    heading: "Contact",
    lines: ["studio@primemotive.com"],
    number: "+03 8393 1949",
    numberUrl: "0383931949",
  },
];

const Column = ({ heading, lines, phoneNumber, addressUrl, sx }) => {
  return (
    <Box
      sx={{ ...sx, display: "flex", flexDirection: "column", ...linkStyles }}
    >
      <Box>
        <TextBlock
          as="h2"
          variant="text.heading.h4"
          sx={{ ...headingStyles }}
          text={heading}
        />
      </Box>

      {lines && (
        <TextBlock
          as="a"
          href={heading === "Contact" ? `mailto:${lines[0]}` : addressUrl}
          target="_blank"
          rel="noopener noreferer"
          sx={{
            marginBottom: "24px",
            "&:last-of-type": "24px",
          }}
        >
          {lines.map((line) => {
            return `${line + "\n"}`;
          })}
        </TextBlock>
      )}
      <TextBlock
        as="a"
        href={"tel:" + phoneNumber.replace(/\+| /g, "")}
        sx={{ marginTop: "auto" }}
      >
        {phoneNumber}
      </TextBlock>
    </Box>
  );
};

const ContactInformation = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <>
      <HorizontalLine />
      <Grid label="summary-in-brief" sx={{ position: "relative" }}>
        <Box sx={{ ...verticalTextStyles }}>
          <VerticalText
            sx={{
              marginBottom: "28px",
              ...inBriefVerticalTextStyles,
            }}
          >
            Our offices
          </VerticalText>
          <Box
            as="a"
            href="#contact-form"
            onClick={(event) => {
              event.preventDefault();
              const element = document.querySelector("#contact-form");
              element.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
            sx={{
              textDecoration: "none",
              color: "greyDark",
              "&:hover, &:focus": {
                color: "red",
              },
            }}
          >
            <VerticalText
              color="inherit"
              sx={{
                margin: 0,
                ...inBriefVerticalTextStyles,
              }}
            >
              Contact form
            </VerticalText>
            <Box
              ref={ref}
              sx={{
                display: "flex",
                paddingTop: ["16px", "", "", "24px"],
                svg: {
                  width: ["13px", "", "14px", "16px"],
                  height: ["13px", "", "14px", "16px"],
                  opacity: inView ? 1 : 0,
                  transition:
                    "opacity 400ms 300ms ease-in-out, color 400ms 100ms ease-in-out",
                },
              }}
            >
              <SVG src={`/assets/svg/shared/arrow-down-left.svg`} />
            </Box>
          </Box>
        </Box>
        {columnData.map((item, index) => {
          return (
            <Column
              key={index}
              heading={item.heading}
              lines={item.lines}
              phoneNumber={item.number}
              sx={item.sx}
              addressUrl={item.addressUrl}
            />
          );
        })}
      </Grid>
    </>
  );
};

export default ContactInformation;

import React from "react";
import { Helmet } from "react-helmet-async";

const defaults = {
  title: "Prime Motive is a Strategic Design Agency | Melbourne & Sydney",
  description:
    "Prime Motive is a Strategic Design and Research company based in Melbourne. We combine Strategic Design, Human-Centred Design and Design Thinking to deliver impact, growth, and lasting engagement between people and organisations.",
  image:
    "https://res.cloudinary.com/primemotive/image/upload/v1601011074/pm-website/images/homepage/home-header.jpg",
  url: "https://primemotive.com.au",
};

const SEO = ({ title, description, url, image, noIndex = false }) => {
  return (
    <Helmet>
      {/* Standard Meta Tags */}
      <title>{title ? title : defaults.title}</title>
      <meta
        name="description"
        content={description ? description : defaults.description}
      />
      {noIndex === true && <meta name="robots" content="noindex" />}
      {/* Standard Canonical URL */}
      <link rel="canonical" href={url ? url : defaults.url} />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title ? title : defaults.title} />
      <meta
        property="og:description"
        content={description ? description : defaults.description}
      />
      <meta property="og:image" content={image ? image : defaults.image} />
      <meta property="og:url" content={url ? url : defaults.url} />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title ? title : defaults.title} />
      <meta
        name="twitter:description"
        content={description ? description : defaults.description}
      />
      <meta name="twitter:image" content={image ? image : defaults.image} />
    </Helmet>
  );
};

export default SEO;

import { gql } from "@apollo/client";
import styled from "@emotion/styled";
import { Box } from "theme-ui";

import { Heading } from "../shared/typography/Heading";
import { Grid } from "../../styles/layout";
import TextBlock from "../shared/typography/TextBlock";

const StyledPullQuote = styled(Box)`
  label: pull-quote;
  grid-column: columns-start / columns-end;
`;

const quoteText = {
  gridColumn: ["columns-start / columns-end", "", "3 / -3", "3 / -3"],
  marginBottom: ["24px", "", "", "42px"],
  position: "relative",
  marginLeft: "0.4em",
};

const citeStyles = {
  gridColumn: ["columns-start / columns-end", "", "3 / -3", "3 / -3"],
  whiteSpace: "pre-line",
};

// This should be the only place to add/edit/delete the fields for this component
// Import this fragment into your template where you are using this component.
export const pullQuoteFrag = gql`
  fragment pullQuoteFrag on BlockPullQuote {
    quote
    citeName
    citeRole
    citeCompany
  }
`;

export const mapPullQuoteData = (data) => {
  return {
    quote: data.quote,
    citeName: data.citeName,
    citeRole: data.citeRole,
    citeCompany: data.citeCompany,
  };
};

const PullQuote = ({ data }) => {
  return (
    <StyledPullQuote>
      <Grid label="pull-quote">
        <Heading.H2Quote
          sx={{
            ...quoteText,
          }}
          text={data.quote + "”"}
        />
        {data.citeName && (
          <TextBlock
            as="span"
            sx={{
              variant: "text.body.small",
              ...citeStyles,
              marginBottom: ["6px"],
            }}
          >
            <b>{data.citeName}</b>
          </TextBlock>
        )}
        {(data.citeRole || data.citeCompany) && (
          <TextBlock
            as="span"
            sx={{ variant: "text.body.small", ...citeStyles }}
          >
            {data.citeRole && data.citeRole}
            {data.citeRole && data.citeCompany && `, `}
            {data.citeCompany && data.citeCompany}
          </TextBlock>
        )}
      </Grid>
    </StyledPullQuote>
  );
};

export default PullQuote;

import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import TextBlock from "../typography/TextBlock";
import { Text } from "theme-ui";

export const RICHTEXT_OPTIONS = {
  renderNode: {
    [BLOCKS.UL_LIST]: (_node, children) => {
      return children !== "" ? (
        <TextBlock as="ul" variant="text.ul.richText">
          {children}
        </TextBlock>
      ) : (
        ""
      );
    },
    [BLOCKS.OL_LIST]: (_node, children) => {
      return children !== "" ? (
        <TextBlock as="ol" variant="text.ol.richText">
          {children}
        </TextBlock>
      ) : (
        ""
      );
    },
    [BLOCKS.HEADING_1]: (_node, children) => {
      return children !== "" ? (
        <TextBlock as="h4" sx={{ marginBottom: 0 }}>
          {children}
        </TextBlock>
      ) : (
        ""
      );
    },
    [BLOCKS.HEADING_2]: (_node, children) => {
      return children !== "" ? (
        <TextBlock as="h4" sx={{ marginBottom: 0 }}>
          {children}
        </TextBlock>
      ) : (
        ""
      );
    },
    [BLOCKS.HEADING_3]: (_node, children) => {
      return children !== "" ? (
        <TextBlock as="h4" sx={{ marginBottom: 0 }}>
          {children}
        </TextBlock>
      ) : (
        ""
      );
    },
    [BLOCKS.HEADING_4]: (_node, children) => {
      return children !== "" ? (
        <TextBlock as="h4" sx={{ marginBottom: 0 }}>
          {children}
        </TextBlock>
      ) : (
        ""
      );
    },
    [BLOCKS.HEADING_4]: (_node, children) => {
      return children !== "" ? (
        <TextBlock as="h4" sx={{ marginBottom: 0 }}>
          {children}
        </TextBlock>
      ) : (
        ""
      );
    },
    [BLOCKS.PARAGRAPH]: (_node, children) => {
      return children !== "" ? (
        <TextBlock variant="text.body.regular">{children}</TextBlock>
      ) : (
        ""
      );
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return children !== "" ? (
        <Text
          as="a"
          href={node.data.uri}
          sx={{
            color: "blue",
            "&:hover": {
              textDecoration: "none",
            },
          }}
        >
          {children}
        </Text>
      ) : (
        ""
      );
    },
  },
};

export const RICHTEXT_OPTIONS_LARGE = {
  renderNode: {
    [BLOCKS.UL_LIST]: (_node, children) => {
      return children !== "" ? (
        <TextBlock as="ul" variant="text.ul.richText">
          {children}
        </TextBlock>
      ) : (
        ""
      );
    },
    [BLOCKS.HEADING_1]: (_node, children) => {
      return children !== "" ? (
        <TextBlock as="h4" variant="text.body.large" sx={{ marginBottom: 0 }}>
          {children}
        </TextBlock>
      ) : (
        ""
      );
    },
    [BLOCKS.HEADING_2]: (_node, children) => {
      return children !== "" ? (
        <TextBlock as="h4" variant="text.body.large" sx={{ marginBottom: 0 }}>
          {children}
        </TextBlock>
      ) : (
        ""
      );
    },
    [BLOCKS.HEADING_3]: (_node, children) => {
      return children !== "" ? (
        <TextBlock as="h4" variant="text.body.large" sx={{ marginBottom: 0 }}>
          {children}
        </TextBlock>
      ) : (
        ""
      );
    },
    [BLOCKS.HEADING_4]: (_node, children) => {
      return children !== "" ? (
        <TextBlock as="h4" variant="text.body.large" sx={{ marginBottom: 0 }}>
          {children}
        </TextBlock>
      ) : (
        ""
      );
    },
    [BLOCKS.PARAGRAPH]: (_node, children) => {
      return children !== "" ? (
        <TextBlock variant="text.body.large">{children}</TextBlock>
      ) : (
        ""
      );
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return children !== "" ? (
        <Text
          as="a"
          variant="text.body.large"
          href={node.data.uri}
          sx={{
            color: "blue",
            "&:hover": {
              textDecoration: "none",
            },
          }}
        >
          {children}
        </Text>
      ) : (
        ""
      );
    },
  },
};

import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { StoreContext } from "../../../store/store";
import { Heading } from "../typography/Heading";
import { Grid } from "../../../styles/layout";
import Spacer from "../../utility/Spacer";
import HorizontalLine from "../decorative/HorizontalLine";
import { Button } from "./Button";

const StyledContact = styled(Box)`
  label: cta-contact-us;
`;

const ContactUs = () => {
  const { getDataFetched, getIsStaticPage } = useContext(StoreContext);
  const location = useLocation();

  // TEMP: Hide pre-footer until Approach Page is in production if on Contact page.
  // TODO: Once Approach page is in production, conditionally set heading and CTA to reflect design.
  if (location.pathname === "/contact") return null;

  return getDataFetched === true || getIsStaticPage === true ? (
    <StyledContact>
      <HorizontalLine where="contact-us" />
      <Grid>
        <Heading.H2
          sx={{
            marginBottom: ["48px", "", "80px"],
            gridColumn: [
              "columns-start / columns-end",
              "",
              "3 / span 6",
              "7 / span 7",
              "7 / span 6",
            ],
          }}
          text="Let's talk about how our team can help you."
        />
        <Button.Secondary
          text="Get in touch"
          to="/contact"
          ariaLabel="Visit our Contact page"
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "columns-start / span 2",
              "3 / span 3",
              "7 / span 3",
              "7 / span 2",
            ],
          }}
        />
      </Grid>
      <Spacer />
    </StyledContact>
  ) : null;
};

export default observer(ContactUs);

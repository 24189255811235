import { useContext, useEffect } from "react";
import { StoreContext } from "../store/store";

export const useDataFetchStatus = (loading, error) => {
  const { setDataFetched } = useContext(StoreContext);

  useEffect(() => {
    if (!loading && !error) {
      setDataFetched(true);
    }
  }, [loading, error, setDataFetched]);
};

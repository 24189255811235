import { gql } from "@apollo/client";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { Grid } from "../../styles/layout";
import { Heading } from "../shared/typography/Heading";
import ImageBlock from "../media/ImageBlock";
import RichText from "../shared/richtext/RichText";
import { Button } from "../shared/ctas/Button";

export const textWithImageFrag = gql`
  fragment textWithImageFrag on BlockTextWithImage {
    leftAligned
    heading
    largeText
    text {
      json
    }
    image
    imageAltText
    imageMaskColour
    ctaText
    ctaUrl
  }
`;

export const mapTextWithImageData = (data) => {
  return {
    leftAligned: data.leftAligned,
    heading: data.heading,
    largeText: data.largeText,
    text: data.text,
    ctaText: data.ctaText,
    ctaUrl: data.ctaUrl,
    image: data.image[0],
    imageAltText: data.imageAltText,
    imageMaskColour: data.imageMaskColour,
  };
};

const StyledTextWithImage = styled(Box)`
  label: text-with-image;
  grid-column: columns-start / columns-end;
`;

const TextWithImage = ({ data }) => {
  return (
    <StyledTextWithImage as="section">
      <Grid label="text-with-image">
        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              `${data.leftAligned ? 2 : 6} / span 4`,
              `${data.leftAligned ? 3 : 8} / span 5`,
            ],
            gridRow: ["2", "", "1"],
          }}
        >
          <Box paddingTop={["0px", "", "80px"]}>
            {data.heading && (
              <Heading.H2
                text={data.heading}
                sx={{
                  marginBottom: ["24px", "", "64px"],
                }}
              />
            )}
            <RichText content={data.text} isLarge={data.largeText} />
            {data.ctaUrl && (
              <Button.Primary
                text={data.ctaText ? data.ctaText : "Learn more"}
                to={data.ctaUrl}
                sx={{ mt: ["24px", "", "64px"], maxWidth: "272px" }}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              `${data.leftAligned ? 6 : 2} / span 4`,
              `${data.leftAligned ? 9 : 3} / span 4`,
            ],
            gridRow: ["1", "", "1"],
            lineHeight: 0,
            mb: ["42px", "", 0],
          }}
        >
          <ImageBlock
            data={{
              image: {
                url: data.image ? data.image.public_id : null,
                alt: data.imageAltText,
              },
              maskColour: data.imageMaskColour,
            }}
          />
        </Box>
      </Grid>
    </StyledTextWithImage>
  );
};

export default TextWithImage;

import { createContext } from "react";
import { useLocalObservable } from "mobx-react-lite";
import { onError } from "@apollo/client/link/error";
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloProvider,
} from "@apollo/client";

const {
  REACT_APP_SPACE_ID,
  REACT_APP_CDA_TOKEN,
  REACT_APP_PREVIEW,
  REACT_APP_CPA_TOKEN,
} = process.env;

const StoreContext = createContext();

const StoreProvider = ({ children }) => {
  const store = useLocalObservable(() => ({
    // State
    errors: {
      graphQLErrors: [],
      networkError: null,
    },
    isDataFetched: false,
    isStaticPage: false,
    // isCursorActive: false,
    cursor: {
      active: false,
      text: "Read More",
      type: "carousel",
    },
    // Setters
    setGraphQLErrors(errors) {
      store.errors.graphQLErrors = errors;
    },
    setNetworkError(error) {
      store.errors.networkError = error;
    },
    // setCursorActive(value) {
    //   store.cursor.active = value;
    // },
    // setCursortext(value) {
    //   store.cursor.text = value;
    // },
    // setCursorType(value) {
    //   store.cursor.type = value;
    // },
    setCursor(values) {
      store.cursor = {
        ...store.cursor,
        ...values,
      };
    },
    resetCursor() {
      store.cursor = {
        active: false,
        text: "Read More",
        type: "default",
      };
    },
    clearErrors() {
      store.errors = {
        graphQLErrors: [],
        networkError: null,
      };
    },
    setDataFetched(value) {
      store.isDataFetched = value;
    },
    setStaticPage(value) {
      store.isStaticPage = value;
    },
    // Getters
    get getErrors() {
      return store.errors;
    },
    get getDataFetched() {
      return store.isDataFetched;
    },
    get getIsStaticPage() {
      return store.isStaticPage;
    },
    get getCursor() {
      return store.cursor;
    },
    // get getIsCursorActive() {
    //   return store.isCursorActive;
    // },
  }));

  const httpLink = new HttpLink({
    uri: `https://graphql.contentful.com/content/v1/spaces/${REACT_APP_SPACE_ID}?access_token=${
      REACT_APP_PREVIEW ? REACT_APP_CPA_TOKEN : REACT_APP_CDA_TOKEN
    }`,
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      console.log("[GraphQL Error(s)]:", graphQLErrors);
      store.setGraphQLErrors(graphQLErrors);
    }
    if (networkError) {
      console.log("[Network Error(s)]:", networkError);
      store.setNetworkError(networkError.message);
    }
  });

  const link = errorLink.concat(httpLink);

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
  });

  return (
    <ApolloProvider client={client}>
      <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    </ApolloProvider>
  );
};

export { StoreProvider, StoreContext };

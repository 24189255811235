import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { gql, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { keyframes } from "@emotion/react";
// Components
import { Grid } from "../../styles/layout";
import Logo from "./Logo";
import Error from "../../pages/Error/Error";
import Loading from "../global/Loader";
import TextBlock from "../shared/typography/TextBlock";

const menuButtonWrapper = {
  position: "fixed",
  top: "0px",
  zIndex: "12",
  width: "100vw",
  margin: "0 auto",
  maxWidth: "1920px",
};

const menuButtonStyles = {
  zIndex: "12",
  border: "none",
  background: "transparent",
  width: ["40px", "", "75px"],
  height: ["40px", "", "75px"],
  padding: ["8px", "", "7px"],
  margin: "0",
  position: "absolute",
  top: ["15px", "", "24px", "24px"],
  right: ["16px", "", "22px", "56px"],
};

const menuDotSyles = {
  width: ["40px", "", "75px"],
  height: ["40px", "", "75px"],
  borderRadius: "50%",
  transition: "250ms ease-in-out",
};

const animateDotOpen = keyframes`
0% {
  transform: scale(1);
}
35%, 50% {
  transform: scale(0.01);
}
100% {
  transform: scale(0);
}`;

const animateDotClosed = keyframes`
0% {
  transform: scale(0);
}
45%, 55% {
  transform: scale(0.05);
}
100% {
  transform: scale(1);
}`;

const crossLineOpen = keyframes`
0% {
  width: 0px;
}
50% {
  width: calc(15 / 2)px;
}
100% {
  width: 15px;
}`;

const crossLineClose = keyframes`
0% {
  width: 15px;
}
50% {
  width: calc(15 / 2)px;
}
100% {
  width: 0px;
}`;

const crossLineStyles = {
  position: "absolute",
  width: ["10px", "", "15px"],
  height: ["3px", "", "4px"],
  borderRadius: "20px",
  transition: "250ms ease-in-out",
};

const Dot = ({ sx, ...props }) => {
  return (
    <Box
      sx={{
        pointerEvents: "auto",
        position: "absolute",
        top: "50%",
        left: "50%",
        transition: props.isOpen
          ? "transform 300ms 100ms ease-in-out"
          : "transform 200ms 50ms ease-in-out",
        "&:hover, &:focus": {
          cursor: "pointer",
        },
        ...sx,
      }}
      className="dot"
      {...props}
    >
      <Box
        className="dot-inner"
        sx={(theme) => ({
          ...menuDotSyles,
          bg: theme.colors.dot,
          animation: props.isOpen
            ? `${animateDotOpen} 600ms ${props.delayOpen} linear 1 forwards`
            : `${animateDotClosed} 200ms linear 1 forwards`,
        })}
      />
    </Box>
  );
};

// https://codepen.io/angro/pen/bGdVqXv
const Cross = ({ sx, ...props }) => {
  return (
    <Box
      sx={{
        pointerEvents: "auto",
        position: "absolute",
        height: ["40px", "", "60px"],
        width: ["40px", "", "60px"],
        top: "50%",
        transform: ["translate(-8px, -50%)", "", "translate(0px, -50%)"],
        "&:hover, &:focus": {
          cursor: "pointer",
        },
        ...sx,
      }}
      className="cross"
      {...props}
    >
      <Box
        sx={(theme) => ({
          top: ["19px", "", "18px"],
          left: "21px",
          transform: "rotate(45deg)",
          transformOrigin: "top left",
          ...crossLineStyles,
          bg: theme.colors.white,
          animation: props.isOpen
            ? `${crossLineOpen} .5s ease-in-out .25s both`
            : `${crossLineClose} .5s ease-in-out .25s both`,
        })}
      />
      <Box
        sx={(theme) => ({
          top: ["19px", "", "18px"],
          right: "21px",
          transform: "rotate(-45deg)",
          transformOrigin: "top right",
          ...crossLineStyles,
          bg: theme.colors.white,
          animation: props.isOpen
            ? `${crossLineOpen} .5s ease-in-out .25s both`
            : `${crossLineClose} .5s ease-in-out .25s both`,
        })}
      />
      <Box
        sx={(theme) => ({
          bottom: ["19px", "", "18px"],
          left: "21px",
          transform: "rotate(-45deg)",
          transformOrigin: "bottom left",
          ...crossLineStyles,
          bg: theme.colors.white,
          animation: props.isOpen
            ? `${crossLineOpen} .5s ease-in-out .25s both`
            : `${crossLineClose} .5s ease-in-out .25s both`,
        })}
      />
      <Box
        sx={(theme) => ({
          bottom: ["19px", "", "18px"],
          right: "21px",
          transform: "rotate(45deg)",
          transformOrigin: "bottom right",
          ...crossLineStyles,
          bg: theme.colors.white,
          animation: props.isOpen
            ? `${crossLineOpen} .5s ease-in-out .25s both`
            : `${crossLineClose} .5s ease-in-out .25s both`,
        })}
      />
    </Box>
  );
};

export const MenuButton = (props) => {
  const [isOpening, setIsOpening] = useState(props.isOpen);

  useEffect(() => {
    let timeout;
    props.isOpen
      ? setIsOpening(true)
      : (timeout = setTimeout(() => {
          setIsOpening(false);
        }, 650));
    return () => {
      clearTimeout(timeout);
    };
  }, [props.isOpen]);

  return (
    <Box sx={{ ...menuButtonWrapper }}>
      <Box as="button" title="Menu" sx={{ ...menuButtonStyles }} {...props}>
        <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
          <Dot
            isOpen={props.isOpen}
            sx={{
              transform: "translateX(-50%) translateY(-50%)",
            }}
            delayOpen="0ms"
          />
          {isOpening && <Cross isOpen={props.isOpen} delayOpen="100ms" />}
        </Box>
      </Box>
    </Box>
  );
};

const GET_NAV_SECTIONS = gql`
  query {
    firstColumn: navigationSections(id: "57Hr6RlN3yKceAdfyYNbvK") {
      navigationLinksCollection {
        items {
          label
          navLink
        }
      }
    }
    secondColumn: navigationSections(id: "7ytIPtfB4zg1s1vGZhh1My") {
      navigationLinksCollection {
        items {
          label
          navLink
        }
      }
    }
    thirdColumn: navigationSections(id: "6qhe9K8cgukmeH4FJQqyij") {
      navigationLinksCollection {
        items {
          label
          navLink
        }
      }
    }
    services: navigationSections(id: "2ZRaHT0H7HNAHuRjGuRqgU") {
      label
      navigationLinksCollection {
        items {
          label
          navLink
        }
      }
    }
    sectors: navigationSections(id: "5SgsJK8ZiFHdfVoTfAVbQe") {
      label
      navigationLinksCollection {
        items {
          label
          navLink
        }
      }
    }
  }
`;

const StyledNavigation = styled(Box)`
  label: navigation;
  position: relative;
  grid-column: 1 / -1;
  height: 100vh;
  width: 100vw;
  background: ${(props) => props.theme.colors.blue};
  transition: transform 500ms ease-in-out;
  transform: ${(props) =>
    !props.isOpen ? "translateY(-100%)" : "translateY(0%)"};
  padding-top: 20px;
  /* padding-bottom: 120px; */
  z-index: 10;
  ul {
    list-style: none;
  }
  a {
    color: ${(props) => props.theme.colors.white};
  }
  .dot {
    top: 30px;
    position: relative;
    margin-left: auto;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-decoration: none;
  }
`;

const StyledSectionLabel = styled.p`
  color: #afafb6;
  font-weight: 400;
  font-size: 18px;
`;

const largeLinkStyles = {
  fontWeight: "400",
  position: "relative",
  fontSize: "clamp(2rem, 1.4624rem + 2.6880vw, 4.688rem)",
  lineHeight: "1",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
};

const smallLinkStyles = {
  fontWeight: "400",
  position: "relative",
  fontSize: ["18px", "", "", "22px"],
  lineHeight: ["26px", "", "", "33px"],
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
};

const LargeNavLink = (props) => {
  return (
    <Box
      as={props.link.navLink.includes("#") ? HashLink : NavLink}
      sx={{ ...largeLinkStyles }}
      to={props.link.navLink}
      tabIndex={props.isOpen ? 0 : -1}
      role="menuitem"
      onClick={props.handleLinkClick}
    >
      {props.link.label}
    </Box>
  );
};

const SmallNavLink = (props) => {
  return (
    <Box
      as={props.link.navLink.includes("#") ? HashLink : NavLink}
      sx={{ ...smallLinkStyles }}
      to={props.link.navLink}
      tabIndex={props.isOpen ? 0 : -1}
      role="menuitem"
      onClick={props.handleLinkClick}
    >
      {props.link.label}
    </Box>
  );
};

const PrimaryNavSection = (props) => {
  return (
    <Box
      as="section"
      sx={{
        flex: ["1 0 33.33%"],
        paddingX: "16px",
        marginBottom: ["40px", "", 0],
        "&:last-child": {
          marginBottom: 0,
        },
      }}
      {...props}
      role="group"
    />
  );
};

const SecondaryNavSection = (props) => {
  return (
    <Box
      as="section"
      sx={{
        flex: [
          "100%",
          "",
          props.children[0].props.children === "Sectors"
            ? "1 0 66.66%"
            : "1 0 33.33%",
          "1 0 33.33%",
          "1 0 33.33%",
        ],
        paddingX: "16px",
        marginTop: [
          props.children[0].props.children === "Sectors" ? "50px" : 0,
          "",
          0,
        ],
      }}
      {...props}
      role="group"
    />
  );
};

const navContent = [
  "Our offices are located on the traditional lands of the Wurundjeri People of the Kulin Nation and the Gadigal People of the Eora Nation.",
  "We acknowledge that sovereignty was never ceded and pay our respects to elders past, present, and emerging.",
];

const Navigation = ({ isOpen, setIsNavOpen, children }) => {
  const location = useLocation();
  const { loading, error, data } = useQuery(GET_NAV_SECTIONS);
  const [primaryNavigation, setPrimaryNavigation] = useState([]);
  const [secondaryNavigation, setSecondaryNavigation] = useState([]);
  const handleLinkClick = () => {
    setIsNavOpen(false); // Close the navigation menu
  };
  useEffect(() => {
    if (!data) return;
    const firstColumn = data.firstColumn.navigationLinksCollection.items;
    const secondColumn = data.secondColumn.navigationLinksCollection.items;
    const thirdColumn = data.thirdColumn.navigationLinksCollection.items;
    setPrimaryNavigation([firstColumn, secondColumn, thirdColumn]);
    setSecondaryNavigation([data.services, data.sectors]);
  }, [data]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Box
      as="nav"
      sx={{
        opacity: 1,
        position: "fixed",
        width: "100%",
        top: 0,
        pointerEvents: isOpen ? "auto" : "none",
        zIndex: "50",
        transition: "opacity 400ms ease-in-out, top 400ms ease-in-out",
      }}
    >
      <Grid>
        <Box
          sx={{
            gridColumn: "columns-start / span 1",
            zIndex: "9",
            pointerEvents: "auto",
          }}
        >
          <Logo location={location.pathname} size="nav" />
        </Box>
        {children}
      </Grid>
      <StyledNavigation
        as="nav"
        sx={{ overflowY: ["scroll", "", "auto"] }}
        isOpen={isOpen}
        id="menu-list"
      >
        <Grid sx={{ height: ["100%"] }}>
          <Box
            sx={{
              gridColumn: "columns-start / columns-end",
              display: ["", "", "flex"],
              marginTop: "30px",
              marginBottom: ["60px", "", "0"],
              marginX: "-16px",
              // "@media only screen and (max-height: 990px) and (min-width: 768px )":
              //   {
              //     marginBottom: "200px",
              //   },
            }}
          >
            {primaryNavigation.map((section, s) => {
              return (
                <PrimaryNavSection key={s}>
                  <Box
                    role="menu"
                    as="ul"
                    sx={{
                      display: ["", "", section.length > 4 && "flex"],
                      marginX: "-16px",
                      flexWrap: "wrap",
                      padding: "0",
                    }}
                  >
                    {section.map((link, i) => {
                      return (
                        <Box
                          as="li"
                          sx={{
                            flex: ["0 1 35%"],
                            paddingX: "16px",
                            margin: "16px 0 0",
                          }}
                          role="presentation"
                          key={i}
                        >
                          <LargeNavLink
                            link={link}
                            isOpen={isOpen}
                            handleLinkClick={handleLinkClick}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </PrimaryNavSection>
              );
            })}
          </Box>
          <Box
            sx={{
              gridColumn: "columns-start / columns-end",
              display: ["", "", "flex"],
              flexWrap: ["", "", "wrap", "nowrap", ""],
              marginX: "-16px",
              paddingBottom: ["20px", "", "60px", ""],
              alignSelf: "end",
            }}
          >
            {secondaryNavigation.map((section, s) => {
              return (
                <SecondaryNavSection key={s}>
                  <StyledSectionLabel>{section.label}</StyledSectionLabel>
                  <Box
                    role="menu"
                    as="ul"
                    sx={{
                      display: [
                        "",
                        "",
                        section.navigationLinksCollection.items.length > 4 &&
                          "flex",
                      ],
                      marginX: "-16px",
                      flexWrap: "wrap",
                      padding: "0",
                    }}
                  >
                    {section.navigationLinksCollection.items.map((link, i) => {
                      return (
                        <Box
                          as="li"
                          sx={{
                            flex: ["0 1 35%", "", "", "0 1 45%", "0 1 50%"],
                            paddingX: "16px",
                            margin: ["14px 0 0", "", "16px 0 0"],
                          }}
                          role="presentation"
                          key={i}
                        >
                          <SmallNavLink
                            link={link}
                            isOpen={isOpen}
                            handleLinkClick={handleLinkClick}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </SecondaryNavSection>
              );
            })}
            <Box
              sx={{
                flex: ["100%", "", "", "1 0 33.33%", ""],
                alignSelf: "flex-end",
                paddingX: "16px",
                marginTop: ["80px", "", "100px", "0", ""],
              }}
            >
              {navContent.map((content, index) => (
                <TextBlock
                  key={index}
                  sx={{ color: "#ACACAC" }}
                  variant="text.body.small"
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                />
              ))}
            </Box>
          </Box>
        </Grid>
      </StyledNavigation>
    </Box>
  );
};

export default Navigation;

import { useContext } from "react";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../../store/store";
import styled from "@emotion/styled";
import { Grid } from "../../styles/layout";
import { Box, Link } from "theme-ui";
import TextBlock from "../shared/typography/TextBlock";
import HorizontalLine from "../shared/decorative/HorizontalLine";
import Spacer from "../utility/Spacer";

//?: Test Newsletter submit for future use
// import { useForm } from "react-hook-form";

const StyledFooter = styled(Box)`
  label: footer;
`;

const socialIconStyles = {
  borderRadius: "50%",
  width: "20px",
  height: "20px",
  marginRight: "0.5em",
};

const instagramStyles = {
  background:
    "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
};

const linkedInStyles = {
  background: "#2172D1",
};

const footerLinkStyles = {
  a: {
    color: "blue",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};

const SocialLinks = () => {
  return (
    <Box
      as="ul"
      sx={{
        li: {
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      <Box as="li">
        <Box
          sx={{
            ...socialIconStyles,
            ...instagramStyles,
          }}
        >
          &nbsp;
        </Box>

        <Link
          variant="text.body.regular"
          href="https://www.instagram.com/prime_motive/?hl=en"
          target="_blank"
          rel="noreferrer"
        >
          <TextBlock>Instagram</TextBlock>
        </Link>
      </Box>
      <Box as="li">
        <Box
          sx={{
            ...socialIconStyles,
            ...linkedInStyles,
          }}
        >
          &nbsp;
        </Box>
        <Link
          variant="text.body.regular"
          href="https://www.linkedin.com/company/prime-motive"
          target="_blank"
          rel="noreferrer"
        >
          <TextBlock>LinkedIn</TextBlock>
        </Link>
      </Box>
    </Box>
  );
};

const Footer = () => {
  const { getDataFetched, getIsStaticPage } = useContext(StoreContext);
  const currentYear = new Date();

  return getDataFetched === true || getIsStaticPage === true ? (
    <StyledFooter as="footer">
      <HorizontalLine where="footer" />
      <Spacer short />
      <Grid
        sx={{
          ...footerLinkStyles,
        }}
      >
        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              "columns-start / span 4",
              "3 / span 4",
            ],
            marginBottom: ["48px", "", "64px", "136px"],
          }}
        >
          <Box
            className="new-business"
            sx={{
              marginBottom: "36px",
              gridColumn: [
                "columns-start / columns-end",
                "",
                "columns-start / span 4",
                "3 / span 4",
              ],
            }}
          >
            <TextBlock
              semiBold
              text="New Business"
              sx={{ paddingBottom: ["25px", "", "36px", "", ""] }}
            />
            <TextBlock
              as="a"
              href="mailto:business@primemotive.com"
              target="_blank"
              rel="noopener noreferer"
            >
              business@primemotive.com.au
            </TextBlock>
            <TextBlock
              as="a"
              href="tel:0385600441"
              target="_blank"
              rel="noopener noreferer"
              sx={{ display: "block", marginTop: "auto" }}
            >
              +03 8560 0441
            </TextBlock>
          </Box>
          <Box className="general-enquiries">
            <TextBlock
              semiBold
              text="General Enquiries"
              sx={{ paddingBottom: ["25px", "", "36px", "", ""] }}
            />
            <TextBlock
              as="a"
              href="mailto:studio@primemotive.com"
              target="_blank"
              rel="noopener noreferer"
            >
              studio@primemotive.com.au
            </TextBlock>
          </Box>
        </Box>
        <Box
          as="address"
          className="location-melbourne"
          sx={{
            marginBottom: ["48px", "", "0px", ""],
            display: "flex",
            flexDirection: "column",
            gridColumn: [
              "columns-start / columns-end",
              "",
              "6 / span 2",
              "7 / span 2",
            ],
            fontStyle: "normal",
            gridRow: ["", "", "1 / 3", ""],
          }}
        >
          <TextBlock
            semiBold
            text="Melbourne"
            sx={{ paddingBottom: ["25px", "", "36px", "", ""] }}
          />
          <TextBlock
            as="a"
            href="https://g.page/PrimeMotive"
            target="_blank"
            rel="noopener noreferer"
          >
            Level 17 <br />
            31 Queen St <br />
            Melbourne 3000
          </TextBlock>
          <TextBlock
            as="a"
            href="tel:0383931949"
            target="_blank"
            rel="noopener noreferrer"
          >
            +03 8393 1949
          </TextBlock>
        </Box>
        <Box
          as="address"
          className="location-sydney"
          sx={{
            marginBottom: ["48px", "", "0px", ""],
            display: "flex",
            flexDirection: "column",
            gridColumn: [
              "columns-start / columns-end",
              "",
              "8 / span 2",
              "9 / span 2",
            ],
            fontStyle: "normal",
            gridRow: ["", "", "1 / 3", ""],
          }}
        >
          <TextBlock
            semiBold
            text="Sydney"
            sx={{
              paddingBottom: ["25px", "", "36px", "", ""],
            }}
          />
          <TextBlock
            as="a"
            href="https://goo.gl/maps/6aFDhUt8wqckJhvi9"
            target="_blank"
            rel="noopener noreferer"
          >
            Ground Floor <br /> 71 York Street <br /> NSW 2000 <br />
          </TextBlock>
          <TextBlock
            as="a"
            href="tel:0290576419"
            target="_blank"
            rel="noopener noreferrer"
          >
            +02 9057 6419
          </TextBlock>
        </Box>
        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              "columns-start / span 4",
              "11 / span 3",
            ],
            gridRow: ["", "", "", "1"],
            marginBottom: ["48px", "", "64px", ""],
          }}
        >
          <TextBlock
            semiBold
            text="Socials"
            sx={{ paddingBottom: ["25px", "", "calc(50px - 0.5rem)"] }}
          />
          <SocialLinks />
        </Box>
        <Box
          sx={{
            gridColumn: ["columns-start / columns-end", "", "", "3 / span 7"],
            marginBottom: ["48px", "", "45px", ""],
          }}
        >
          <TextBlock>
            Our offices are located on the traditional lands of the Wurundjeri
            People of the Kulin Nation and the Gadigal People of the Eora
            Nation. We acknowledge that sovereignty was never ceded and pay our
            respects to elders past, present, and emerging.
          </TextBlock>
        </Box>
        <Box
          sx={{
            gridColumn: ["columns-start / columns-end", "", "", "11 / span 3"],
            marginBottom: ["45px", "", "", "0"],
          }}
        >
          <TextBlock sx={{ marginBottom: "0" }}>
            <a href="/privacy">Privacy Policy</a>
          </TextBlock>
          <TextBlock>© {currentYear.getFullYear()} Prime Motive</TextBlock>
        </Box>
      </Grid>
    </StyledFooter>
  ) : null;
};

export default observer(Footer);

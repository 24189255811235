import styled from "@emotion/styled";
import { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Box, Flex } from "theme-ui";
import { useInView } from "react-intersection-observer";
import TextBlock from "./TextBlock";
import { fadeIn } from "../../../animations/transitions";
import OpacityWrap from "../../utility/OpacityWrap";
import { isExternalLink } from "../../../scripts/helpers";

const StyledTextLink = styled(Box)`
  label: text-link;
`;

const TextLink = ({
  text,
  secondaryText = null,
  url = "#",
  color = "blue",
  sx,
  as = Link,
}) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  // Might need to refactor, test on all other links
  const [isExternal] = useState(isExternalLink(url));

  const textLinkStyles = {
    textDecoration: "none",
    fontSize: ["17px,", "", "18px", "19px", "20px"],
    lineHeight: ["25px", "", "26px", "27px", "28px"],
    fontWeight: "600",
    color: color,
    "&:hover, &:focus": {
      color: color === "blue" ? "red" : "blue",
      ".link-container": {
        borderColor: color === "blue" ? "red" : "blue",
      },
    },
  };

  const LinkContent = ({ ...props }) => (
    <>
      <OpacityWrap>
        <Flex
          sx={{
            flexDirection: "row",
            borderBottom: "1px solid",
            borderColor: color,
            alignItems: "center",
            paddingBottom: [2],
            marginBottom: [2],
            transition: "border-color 400ms ease-in-out",
          }}
          className="link-container"
        >
          <TextBlock as="p" sx={{ color: "inherit" }}>
            {text}
          </TextBlock>
          <Box
            sx={{
              marginLeft: "auto",
              svg: {
                transition: "color 400ms ease-in-out",
              },
            }}
          >
            <motion.div
              variants={fadeIn}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              transition={{
                default: { delay: 0.1, duration: 0.4, ease: "easeInOut" },
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.309 11.3231L10.3267 0.987558L11.249 0L24 12L11.249 24.0001L10.3267 23.0125L21.3093 12.6768L-6.09173e-05 12.6768L-6.10352e-05 11.3231L21.309 11.3231Z"
                  fill="currentColor"
                />
              </svg>
            </motion.div>
          </Box>
        </Flex>
        {/* use text.body.small, might need to change once typography changes get merged in */}
        {secondaryText && (
          <TextBlock
            as="p"
            sx={{ variant: "text.body.small", fontWeight: "normal" }}
          >
            {secondaryText}
          </TextBlock>
        )}
      </OpacityWrap>
    </>
  );

  // Conditional props
  // https://www.designcise.com/web/tutorial/how-to-conditionally-add-props-to-a-react-component
  return (
    <StyledTextLink
      ref={ref}
      as={isExternal ? "a" : as}
      {...(isExternal ? { href: url } : {})}
      {...(isExternal ? { target: "_blank" } : {})}
      {...(isExternal ? {} : { to: url })}
      sx={{
        ...textLinkStyles,
        ...sx,
      }}
    >
      <LinkContent
        text={text}
        secondaryText={secondaryText}
        color={color}
        url={url}
        sx={sx}
      />
    </StyledTextLink>
  );
};

export default TextLink;

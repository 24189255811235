import { Heading } from "../../components/shared/typography/Heading";
import { Grid } from "../../styles/layout";
import TextBlock from "../../components/shared/typography/TextBlock";
import { Box } from "theme-ui";
import { useStaticPageStatus } from "../../hooks/useStaticPageStatus";
import SEO from "../../components/global/SEO";

const seo = {
  title: "Page Not Found | Prime Motive",
  description: "Page Not Found",
};

const PageNotFound = () => {
  useStaticPageStatus(true);
  return (
    <>
      <SEO title={seo.title} description={seo.description} noIndex={true} />
      <Grid>
        <Heading.H1
          text="Page not found"
          sx={{
            gridColumn: ["columns-start / columns-end", "", "3 /-3"],
            my: "120px",
          }}
        />
        <Box
          sx={{
            gridColumn: ["columns-start / columns-end", "", "3 /-3"],
            mb: "120px",
          }}
        >
          <TextBlock as="p" variant="text.body.large">
            An issue has occured, it appears that the page you are looking for
            does not exist. Maybe it is a mystery and has vanished, or maybe
            there is just a fullstop in your hyper link. Either way let's return
            home.
          </TextBlock>
        </Box>
      </Grid>
    </>
  );
};

export default PageNotFound;

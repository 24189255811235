import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";

// Components
import HorizontalLine from "../../components/shared/decorative/HorizontalLine";
import { Grid } from "../../styles/layout";
import VerticalText from "../../components/shared/decorative/VerticalText";
import TextBlock from "../../components/shared/typography/TextBlock";
import Spacer from "../../components/utility/Spacer";
import ContentRow from "../../components/content/ContentRow";

const StyledHomeServices = styled(Box)`
  label: services;
`;

const HomeServices = (props) => {
  const { data } = props;

  return (
    <StyledHomeServices as="section">
      <HorizontalLine />
      <Grid sx={{ position: "relative", height: ["", "", "0"] }}>
        <VerticalText
          sx={{
            display: ["block", "", "block"],
            gridRow: ["", "", "1"],
            gridColumn: "columns-start / columns-end",
          }}
          flipOnMobile
        >
          Services
        </VerticalText>
      </Grid>
      <Grid>
        <Box
          sx={{
            gridRow: ["", "", "1"],
            gridColumn: [
              "columns-start / columns-end",
              "",
              "3 / span 4",
              "3 / span 5",
            ],
          }}
        >
          <TextBlock variant="text.link.regular" sx={{ marginBottom: "24px" }}>
            We help organisations gain profound insights into the human
            experience. We utilise design, human-centricity, and systemic
            thinking to develop cohesive strategies, services, products and
            experiences that facilitate transformation, effective change and
            lasting engagement.
          </TextBlock>
        </Box>
      </Grid>
      <Spacer sx={{ height: ["80px", "", "100px"] }} />
      {data.services.items.map((item, index) => (
        <ContentRow
          key={index}
          data={{ ...item.relatedEntry, verticalText: `${index + 1}` }}
        />
      ))}
      <Spacer sx={{ height: ["100px", "", "150px", "150px"] }} />
    </StyledHomeServices>
  );
};

export default HomeServices;

import { useInView } from "react-intersection-observer";
import { Text } from "theme-ui";

const TextBlock = ({
  rootMargin = "100px 0px 0px 0px",
  threshold = 0,
  className = null,
  variant = null,
  text = null,
  as = "p",
  color = "blue",
  children,
  semiBold,
  sx,
  ...props
}) => {
  const [textRef, textView] = useInView({
    threshold: threshold,
    rootMargin: rootMargin,
    triggerOnce: true,
  });

  return (
    <Text
      as={as}
      className={className}
      sx={{
        fontWeight: semiBold && 600,
        variant: variant ? variant : "text.body.regular",
        opacity: textView ? 1 : 0,
        color: color,
        transition:
          "transform 400ms ease-in-out, opacity 400ms 100ms ease-in-out, color 400ms ease-in-out",
        span: {
          "&.red": {
            color: "red",
          },
        },
        whiteSpace: "pre-line",
        ...sx,
      }}
      ref={textRef}
      {...props}
    >
      {children || text}
    </Text>
  );
};

export default TextBlock;

import { useState } from "react";
import { gql } from "@apollo/client";
import styled from "@emotion/styled";
import { useInView } from "react-intersection-observer";
import { Box } from "theme-ui";
import { forwardRef } from "react";

import CloudinaryImage from "../shared/cloudinary/CloudinaryImage";

// This should be the only place to add/edit/delete the fields for this component
// Import this fragment into your template where you are using this component.
export const imageFrag = gql`
  fragment imageFrag on BlockImage {
    title
    image
    imageAltText
    imageMaskColour
  }
`;

export const mapImageData = (data) => {
  return {
    title: data.title,
    image: {
      url: data.image[0].public_id,
      alt: data.imageAltText,
      src: data.image[0].url,
    },
    maskColour: data.imageMaskColour,
  };
};

const StyledImageContainer = styled(Box)`
  line-height: 0;
  position: relative;
  opacity: 0;
  transform: translateY(${(props) => (props.maskColour ? "50px" : "0px")});
  transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
  height: 100%;
  &.fade-up {
    opacity: 1;
    transform: translateY(0%);
  }
  &.fade-in {
    opacity: 1;
  }
`;

const StyledImageInner = styled(Box)`
  line-height: 0;
  position: relative;
  max-height: 100%;

  img {
    position: relative;
    width: 100%;
    max-width: 100%;
    clip-path: ${(props) => props.clipPath || "none"};
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.maskColour};
    z-index: 3;
    opacity: 1;
    transition: opacity 400ms 150ms ease-in-out;
    clip-path: ${(props) => props.clipPath || "none"};
  }
  &.remove-mask {
    &::before {
      opacity: 0;
    }
  }
`;

const ImageBlock = (
  {
    data,
    className,
    threshold = 0.5,
    containerThreshold = 0.1,
    transform,
    clipPath,
    borderRadius = ["16px", "", "20px", "", "30px"],
    height = "auto",
    sx,
    onLoad = null,
  },
  ref
) => {
  const [containerRef, containerView] = useInView({
    threshold: containerThreshold,
    triggerOnce: true,
  });

  const [imgRef, imgView] = useInView({
    threshold: threshold,
    triggerOnce: true,
  });
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <StyledImageContainer
      ref={containerRef}
      maskColour={data.maskColour}
      className={`${
        containerView && imageLoaded && data.maskColour && "fade-up"
      } ${
        containerView && imageLoaded && !data.maskColour && "fade-in"
      }  ${className}`}
      sx={{
        borderRadius: borderRadius,
      }}
    >
      <StyledImageInner
        ref={imgRef}
        className={`${imgView && imageLoaded && "remove-mask"}`}
        maskColour={data.maskColour}
        clipPath={clipPath}
        sx={{
          borderRadius: borderRadius,
          overflow: "hidden",
          img: {
            height: height,
          },
          "&:before": {
            borderRadius: borderRadius,
          },
          ...sx,
        }}
      >
        <CloudinaryImage
          className={className}
          src={data.image.url}
          alt={data.image.alt}
          transform={transform}
          onLoad={() => {
            setImageLoaded(true);
            onLoad && onLoad();
          }}
          ref={ref}
        />
      </StyledImageInner>
    </StyledImageContainer>
  );
};

export default forwardRef(ImageBlock);

import useEmblaCarousel from "embla-carousel-react";
import { Box } from "theme-ui";
import styled from "@emotion/styled";
import { Heading } from "../../components/shared/typography/Heading";
import { Grid } from "../../styles/layout";
import TextBlock from "../../components/shared/typography/TextBlock";
import ImageBlock from "../../components/media/ImageBlock";
import Spacer from "../../components/utility/Spacer";
import VerticalText from "../../components/shared/decorative/VerticalText";
import HorizontalLine from "../../components/shared/decorative/HorizontalLine";
import { Button } from "../../components/shared/ctas/Button";
import { useCustomCursor } from "../../hooks/useCustomCursor";

const StyledHomeCarousel = styled(Box)`
  label: musings;
`;

const StyledCarousel = styled(Box)`
  display: flex;
  cursor: grab;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const carouselStyles = {
  margin: ["0 -20px", "0 -23px", "", "0 -40px"],
};

const carouselItemStyles = {
  position: "relative",
  display: "grid",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "min-content max-content",
  flex: ["0 0 90%", "1 0 60%", "", "1 0 36%"],
  padding: ["0 20px", "0 23px", "", "0 40px"],
  justifyContent: "center",

  ".image-container": {
    height: "100%",
    width: "100%",
    lineHeight: 0,
    position: "absolute",
    top: "0",
    marginRight: "20px",
    img: {
      objectFit: "cover",
      objectPosition: "center",
      width: "100%",
      height: "100%",
    },
  },
  "&:nth-of-type(3n + 1)": {
    ".image-container": {
      paddingTop: ["30px", "", "", "50px"],
      img: {
        objectPosition: "center 45%",
      },
    },
  },
  "&:nth-of-type(3n + 2)": {
    ".image-container": {
      paddingTop: ["60px", "", "", "100px"],
      img: {
        objectPosition: "center 45%",
      },
    },
  },

  ".image-display-mask": {
    position: "relative",
    paddingTop: "120%",
    overflow: "hidden",
  },
};

const HomeCarousel = (props) => {
  const { carouselItems } = props.data;
  const [emblaRef] = useEmblaCarousel({
    align: "start",
    containScroll: "keepSnaps",
  });
  const [ref, handleMouseEnter, handleMouseLeave] = useCustomCursor(
    "Drag",
    "carousel"
  );

  return (
    <StyledHomeCarousel
      as="section"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <HorizontalLine />
      <Grid sx={{ position: "relative", height: ["auto", "", "0", ""] }}>
        <VerticalText
          sx={{
            gridColumn: "columns-start / columns-end",
          }}
          flipOnMobile
        >
          Musings
        </VerticalText>
      </Grid>
      <Grid label="musings">
        <Heading.H2
          text="News, insight and worthwhile things that are going on."
          sx={{
            marginBottom: ["56px", "", "80px", "120px"],
            gridColumn: [
              "columns-start / columns-end",
              "",
              "3 / span 6",
              "3 / span 6",
            ],
          }}
        />
      </Grid>
      <Grid sx={{ overflow: "hidden" }}>
        <Box
          ref={ref}
          sx={{
            gridColumn: ["columns-start / columns-end", "", "3 / columns-end"],
          }}
        >
          <Box ref={emblaRef}>
            <StyledCarousel sx={{ ...carouselStyles }}>
              {carouselItems.items.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    ...carouselItemStyles,
                  }}
                >
                  <Box
                    sx={{
                      gridRow: "1",
                      alignSelf: "flex-end",
                      lineHeight: "0",
                    }}
                    className="image-display-mask c-carousel-item"
                  >
                    <Box className="image-container">
                      <ImageBlock
                        threshold={0}
                        containerThreshold={0}
                        className="carousel-image"
                        data={{
                          image: {
                            url:
                              item && item.image
                                ? item.image[0].public_id
                                : null,
                            alt: item.imageAltText,
                          },
                          maskColour: item.imageMaskColour,
                        }}
                        transform={{ width: 534, height: 657 }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      gridRow: "2",
                      height: "fit-content",
                    }}
                  >
                    <Box sx={{ height: "fit-content" }}>
                      <Heading.H4
                        as="h3"
                        threshold={0}
                        sx={{ mt: "40px", mb: ["24px", "", "40px"] }}
                        text={item.title}
                      />
                      <Box sx={{ marginBottom: "40px" }}>
                        <TextBlock variant="text.body.regular">
                          {item.body}
                        </TextBlock>
                      </Box>

                      <Button.Secondary
                        threshold={0}
                        to={item.url}
                        text="Learn more"
                        ariaLabel={`Read about ${item.title}`}
                        sx={{ mr: "auto", maxWidth: "280px" }}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </StyledCarousel>
          </Box>
        </Box>
      </Grid>
      <Spacer sx={{ height: ["200px", "", "250px", "350px"] }} />
    </StyledHomeCarousel>
  );
};

export default HomeCarousel;

import { useInView } from "react-intersection-observer";
import { Box } from "theme-ui";
import { keyframes } from "@emotion/react";
import { Grid } from "../../../styles/layout";

import VerticalText from "./VerticalText";

const animate = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }`;

const HorizontalLine = ({
  label = null,
  sx,
  where,
  theme,
  flipOnMobile = false,
}) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <>
      <Grid>
        <Box
          ref={ref}
          as="div"
          sx={{
            gridColumn: ["columns-start / columns-end"],
            marginBottom: "32px",
            outline: "none",
            border: "0",
            position: "relative",
            width: "100%",
            height: "1px",
            bg: "blue",
            overflow: "hidden",
            "&:before": {
              content: `""`,
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              bg: "background",
              animation: inView ? `${animate} 600ms linear 1 forwards` : "none",
            },
            ...sx,
          }}
        />
      </Grid>
      {label && (
        <Grid sx={{ position: "relative", height: ["auto", "", "0", ""] }}>
          <VerticalText
            flipOnMobile={flipOnMobile}
            sx={{
              gridColumn: "columns-start / columns-end",
            }}
          >
            {label}
          </VerticalText>
        </Grid>
      )}
    </>
  );
};

export default HorizontalLine;

import styled from "@emotion/styled";
import TextBlock from "../shared/typography/TextBlock";
import { Box } from "theme-ui";
import { Grid } from "../../styles/layout";
import { Heading } from "../shared/typography/Heading";
import ImageBlock from "../media/ImageBlock";
import Spacer from "../utility/Spacer";

const StyledHero = styled(Box)`
  label: hero-full-width;
`;

const HeroFullWidth = ({ data }) => {
  return (
    <StyledHero sx={{ marginTop: ["120px", "", "28px", "32px"] }}>
      <Grid>
        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              "4 / span 1",
              "5 / span 1",
            ],
          }}
        >
          <Box
            sx={{
              writingMode: ["", "", "vertical-lr"],
              transform: ["", "", "scale(-1, -1)"],
              width: ["", "", "", "100%"],
              marginBottom: ["24px", "", "0px"],
              paddingBottom: ["", "", "6px"],
            }}
          >
            <TextBlock>{data.label}</TextBlock>
          </Box>
        </Box>
        <Heading.H1Variable
          text={data.longDescription}
          sx={{
            marginBottom: ["100px", "", "", "375px"],
            gridColumn: [
              "columns-start / columns-end",
              "",
              "5 / span 4",
              data.longDescription.length > 27 ? "span 6 / -3" : "span 5 / -4",
              data.longDescription.length > 27 ? "span 6 / -3" : "span 5 / -4",
            ],
          }}
        />
        <Box
          sx={{
            display: "grid",
            gridColumn: [
              "columns-start / columns-end",
              "",
              "4 / columns-end",
              "7 / columns-end",
            ],
            borderBottom: data.showHeroImage === true ? "" : "1px solid black",
          }}
        >
          <Box
            sx={{
              marginBottom: "24px",
              gridColumn: ["", "", "1 / span 3"],
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <TextBlock variant="text.body.regular" color="red">
              {data.clientName}
            </TextBlock>
          </Box>
          {data.services.length > 0 && (
            <Box
              sx={{
                marginBottom: "24px",
                gridColumn: ["", "", "5 / span 3"],
              }}
            >
              {data.services.map((service, i) => (
                <TextBlock as={"span"} key={i}>
                  {(i ? ", " : "") + service.title}
                </TextBlock>
              ))}
            </Box>
          )}
        </Box>
        {data.showHeroImage === true ? (
          <></>
        ) : (
          <Spacer
            sx={{
              gridColumn: "columns-start / columns-end",
              height: ["125px", "", "220px"],
              borderBottom: "1px solid black",
            }}
          />
        )}
        {data.showHeroImage === true ? (
          data.image && (
            <Box
              sx={{
                gridColumn: ["columns-start / columns-end", "", "", "2 / -2"],
                lineHeight: 0,
              }}
            >
              <ImageBlock
                data={{
                  image: { url: data.image.public_id, alt: data.imageAlt },
                  maskColour: data.maskColour,
                }}
              />
            </Box>
          )
        ) : (
          <></>
        )}
      </Grid>
    </StyledHero>
  );
};

export default HeroFullWidth;

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "theme-ui";
import App from "./App";
import { theme } from "./theme/theme";
import { StoreProvider } from "./store/store";
import { CloudinaryProvider } from "./context/cloudinary";
import { HelmetProvider } from "react-helmet-async";

ReactDOM.render(
  <StoreProvider>
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CloudinaryProvider>
          <Router>
            <App />
          </Router>
        </CloudinaryProvider>
      </ThemeProvider>
    </HelmetProvider>
  </StoreProvider>,
  document.getElementById("root")
);

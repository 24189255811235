import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { Box } from "theme-ui";
import TextBlock from "../typography/TextBlock";
import { isExternalLink } from "../../../scripts/helpers";

// Pass in grid-column styles in sx

const buttonGeneralStyles = {
  border: "1px solid",
  borderRadius: "100px",
  textDecoration: "none",
  transition: "opacity 500ms 100ms ease-in-out",
  display: "flex",
  width: ["100%"],
  backgroundColor: "transparent",
  padding: "0",
  overflow: "hidden",
  "&.disabled": {
    opacity: "0.5",
    pointerEvents: "none",
  },
  btnOuter: {
    display: "flex",
    width: "100%",
    padding: "16px 32px",
    position: "relative",
    overflow: "hidden",
    borderRadius: "100px",
    zIndex: 1,
    "&:before, &:after": {
      content: "' '",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      zIndex: 0,
      transition: "transform 0.3s cubic-bezier(0.7, 0, 0.2, 1)",
    },
  },
  btnInner: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "color 0.3s cubic-bezier(0.7, 0, 0.2, 1)",
    zIndex: 1,
  },
  p: {
    lineHeight: ["25px", "26px", "", "27px", "28px"],
    fontSize: ["17px", "18px", "", "19px", "20px"],
    whiteSpace: "nowrap",
    transition: "color 0.3s cubic-bezier(0.7, 0, 0.2, 1)",
  },
  svg: {
    marginLeft: ["16px"],
  },
};

const primaryButtonStyles = {
  btn: {
    outlineOffset: "3px",
    border: "1px solid",
    borderColor: "blue",
    transition:
      "opacity 500ms 100ms ease-in-out, background-color 100ms linear",
    "&:hover": {
      cursor: "pointer",
      ".btn--outer:before": {
        transform: "translate3d(0,-100%,0)",
      },
      p: {
        color: "blue",
      },
      ".btn--inner": {
        color: "blue",
      },
    },

    "&:focus": {
      outlineColor: "blue",
      p: {
        textDecoration: "underline",
      },
      "&:active": {
        p: {
          textDecoration: "none",
        },
      },
    },
    "&:active": {
      backgroundColor: "blue",
      ".btn--inner": {
        color: "white",
      },
      p: {
        color: "white",
      },
    },
  },
  btnOuter: {
    "&:before": {
      backgroundColor: "blue",
    },
  },
  btnInner: {
    color: "white", // For the arrow
    position: "relative",
  },
  text: {
    fontWeight: "500",
    color: "white",
  },
};

const secondaryButtonStyles = {
  btn: {
    outlineOffset: "3px",
    border: "1px solid",
    borderColor: "blue",
    transition:
      "opacity 500ms 100ms ease-in-out, background-color 100ms linear",
    "&:hover": {
      cursor: "pointer",
      ".btn--outer:before": {
        transform: "translate3d(0,-100%,0)",
      },
      p: {
        color: "white",
      },
      ".btn--inner": {
        color: "white",
      },
    },
    // Temporary focus state?
    "&:focus": {
      outlineColor: "blue",
      p: {
        textDecoration: "underline",
      },
      "&:active": {
        p: {
          textDecoration: "none",
        },
      },
    },
    "&:active": {
      ".btn--outer:before": {
        backgroundColor: "transparent",
      },
      p: {
        color: "blue",
      },
      ".btn--inner": {
        color: "blue",
      },
    },
  },
  btnOuter: {
    "&:before": {
      top: "100%",
      backgroundColor: "blue",
      transition:
        "transform 0.3s cubic-bezier(0.7, 0, 0.2, 1), background-color 100ms linear",
    },
  },
  btnInner: {
    color: "blue", // For the arrow
    position: "relative",
  },
  text: {
    fontWeight: "500",
    color: "blue",
  },
};

const tertiaryButtonStyles = {
  btn: {
    outlineOffset: "3px",
    backgroundColor: "redLight",
    border: "1px solid",
    borderColor: "redLight",
    outlineColor: "blue",
    transition:
      "opacity 500ms 100ms ease-in-out, background-color 100ms linear",
    "&:hover": {
      cursor: "pointer",
      p: {
        color: "white",
      },
      ".btn--inner": {
        color: "white",
      },
      ".btn--outer:before": {
        transform: "translate3d(0,-100%,0)",
      },
    },
    // Temporary focus state?
    "&:focus": {
      outlineColor: "blue",
      p: {
        textDecoration: "underline",
      },
      "&:active": {
        backgroundColor: "blue",
        p: {
          textDecoration: "none",
          color: "white",
        },
      },
    },
    "&:active": {
      ".btn--outer:before": {
        backgroundColor: "blue",
      },
    },
  },
  btnOuter: {
    padding: "16px 32px",
    textAlign: "center",
    justifyContent: "center",
    width: "100%",
    "&:before": {
      top: "100%",
      backgroundColor: "blue",
      transition:
        "transform 0.3s cubic-bezier(0.7, 0, 0.2, 1), background-color 100ms linear",
    },
  },
  btnInner: {
    position: "relative",
    textAlign: "center",
    justifyContent: "center",
    color: "blueStatic",
    "&:hover": {
      color: "white",
    },
  },
  text: { fontWeight: "500", color: "blueStatic" },
};

const navButtonStyles = {
  wrapper: {
    pointerEvents: "auto",
    padding: ["10px 28px", "", "9px 24px"],
    backgroundColor: "transparent",
    border: "1px solid",
    borderColor: "blue",
    outlineOffset: "4px",
    transition: "background-color 100ms linear",
    "&:hover": {
      backgroundColor: "blueLight",
      div: {
        color: "white",
      },
      p: {
        color: "white",
      },
    },
    // Temporary focus state?
    "&:focus": {
      backgroundColor: "white",
      div: {
        color: "blue",
      },
      p: {
        textDecoration: "underline",
        color: "blue",
      },
    },
  },
  text: {
    fontWeight: "400",
    color: "blue",
    transition: "color 100ms linear",
  },
};

const inlineLinkGeneralStyles = {
  color: "blue",
  transition: "color 100ms linear",
  whiteSpace: "nowrap",
  "&:hover, &:focus": {
    color: "red",
  },
  "&.disabled": {
    opacity: "0.5",
    pointerEvents: "none",
  },
};

export const Button = {
  Primary: ({
    as,
    text,
    ariaLabel,
    to,
    type,
    theshdold = 0.1,
    sx,
    ...props
  }) => {
    const [isExternal] = useState(isExternalLink(to));
    const [buttonRef, buttonView] = useInView({
      threshold: theshdold,
      triggerOnce: true,
    });
    return (
      <Box
        sx={{
          opacity: buttonView ? 1 : 0,
          border: type ? "none" : "",
          ...buttonGeneralStyles,
          ...primaryButtonStyles.btn,
          ...sx,
        }}
        ref={buttonRef}
        {...(type ? { as: "button" } : { ...(as ? { as: as } : { as: Link }) })}
        {...(type ? {} : { to: to })}
        {...(isExternal ? { href: to } : {})}
        {...(isExternal ? { target: "_blank" } : {})}
        aria-label={ariaLabel}
        title={ariaLabel}
        {...props}
      >
        <Box
          className="btn--outer"
          sx={{
            ...buttonGeneralStyles.btnOuter,
            ...primaryButtonStyles.btnOuter,
          }}
        >
          <Box
            className="btn--inner"
            sx={{
              ...buttonGeneralStyles.btnInner,
              ...primaryButtonStyles.btnInner,
            }}
          >
            <TextBlock
              sx={{ ...buttonGeneralStyles.text, ...primaryButtonStyles.text }}
            >
              {text}
            </TextBlock>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.309 11.3231L10.3267 0.987558L11.249 0L24 12L11.249 24.0001L10.3267 23.0125L21.3093 12.6768L-6.09173e-05 12.6768L-6.10352e-05 11.3231L21.309 11.3231Z"
                fill="currentColor"
              />
            </svg>
          </Box>
        </Box>
      </Box>
    );
  },
  Secondary: ({
    as,
    anchorLink,
    text,
    ariaLabel,
    to,
    type,
    threshold = 0.5,
    sx,
    ...props
  }) => {
    const [buttonRef, buttonView] = useInView({
      threshold: threshold,
      triggerOnce: true,
    });
    const anchorLinkStyles = {
      svg: {
        transform: anchorLink && "rotate(90deg)",
      },
    };
    return (
      <Box
        sx={{
          opacity: buttonView ? 1 : 0,
          ...buttonGeneralStyles,
          ...secondaryButtonStyles.btn,
          ...sx,
        }}
        as={as ? as : Link}
        to={to}
        ref={buttonRef}
        {...(type ? { as: "button" } : { ...(as ? { as: as } : { as: Link }) })}
        {...(type ? {} : { to: to })}
        aria-label={ariaLabel}
        title={ariaLabel}
        {...props}
      >
        <Box
          className="btn--outer"
          sx={{
            ...buttonGeneralStyles.btnOuter,
            ...secondaryButtonStyles.btnOuter,
          }}
        >
          <Box
            className="btn--inner"
            sx={{
              ...buttonGeneralStyles.btnInner,
              ...secondaryButtonStyles.btnInner,
              ...anchorLinkStyles,
            }}
          >
            <TextBlock
              sx={{
                ...buttonGeneralStyles.text,
                ...secondaryButtonStyles.text,
              }}
            >
              {text}
            </TextBlock>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.309 11.3231L10.3267 0.987558L11.249 0L24 12L11.249 24.0001L10.3267 23.0125L21.3093 12.6768L-6.09173e-05 12.6768L-6.10352e-05 11.3231L21.309 11.3231Z"
                fill="currentColor"
              />
            </svg>
          </Box>
        </Box>
      </Box>
    );
  },
  Tertiary: ({ as, text, ariaLabel, to, type, arrow = true, sx, ...props }) => {
    const [buttonRef, buttonView] = useInView({
      threshold: 0.1,
      triggerOnce: true,
    });
    return (
      <Box
        sx={{
          opacity: buttonView ? 1 : 0,
          ...buttonGeneralStyles,
          ...tertiaryButtonStyles.btn,
          ...sx,
        }}
        as={as ? as : Link}
        to={to}
        ref={buttonRef}
        {...(type ? { as: "button" } : { ...(as ? { as: as } : { as: Link }) })}
        {...(type ? {} : { to: to })}
        aria-label={ariaLabel}
        title={ariaLabel}
        {...props}
      >
        <Box
          className="btn--outer"
          sx={{
            ...buttonGeneralStyles.btnOuter,
            ...tertiaryButtonStyles.btnOuter,
          }}
        >
          <Box
            className="btn--inner"
            sx={{
              ...buttonGeneralStyles.btnInner,
              ...tertiaryButtonStyles.btnInner,
            }}
          >
            <TextBlock
              sx={{ ...buttonGeneralStyles.text, ...tertiaryButtonStyles.text }}
            >
              {text}
            </TextBlock>
            {arrow === true && (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.309 11.3231L10.3267 0.987558L11.249 0L24 12L11.249 24.0001L10.3267 23.0125L21.3093 12.6768L-6.09173e-05 12.6768L-6.10352e-05 11.3231L21.309 11.3231Z"
                  fill="currentColor"
                />
              </svg>
            )}
          </Box>
        </Box>
      </Box>
    );
  },
  Nav: ({ text, to, sx, ...props }) => {
    const [buttonRef, buttonView] = useInView({
      threshold: 0.1,
      triggerOnce: true,
    });
    return (
      <Box
        sx={{
          opacity: buttonView ? 1 : 0,
          ...buttonGeneralStyles,
          ...navButtonStyles.wrapper,
          ...sx,
        }}
        as={Link}
        to={to}
        ref={buttonRef}
        {...props}
      >
        <Box>
          <TextBlock sx={{ ...navButtonStyles.text }}>{text}</TextBlock>
        </Box>
      </Box>
    );
  },
};

export const InlineLink = ({ as, to, text, variant, sx, ...props }) => {
  const [buttonRef, buttonView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  return (
    <Box
      sx={{
        variant: variant === "large" ? "text.cta.large" : "text.link.regular",
        textDecoration: variant === "large" ? "none" : "",
        opacity: buttonView ? 1 : 0,
        ...inlineLinkGeneralStyles,
        ...sx,
      }}
      as={as ? as : Link}
      to={to}
      ref={buttonRef}
      {...props}
    >
      {text}
    </Box>
  );
};

import { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Box, useThemeUI } from "theme-ui";
import { useBreakpointIndex } from "@theme-ui/match-media";

const StyledSpacer = styled(Box)`
  label: spacer;
`;

const spacerStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  p: {
    background: "white",
  },
};

const debugStyles = {
  backgroundImage: `linear-gradient(
    45deg,
    #ff0000 2.5%,
    #ffffff 2.5%,
    #ffffff 50%,
    #ff0000 50%,
    #ff0000 52.5%,
    #ffffff 52.5%,
    #ffffff 100%
  )`,
  backgroundSize: "28.28px 28.28px",
};

const Spacer = ({ debug = false, short = false, sx, id }) => {
  const { theme } = useThemeUI();
  const index = useBreakpointIndex();
  const [height, setHeight] = useState(0);
  const [breakpoint, setBreakpoint] = useState(null);

  const spacerRef = useRef();

  useEffect(() => {
    if (!debug) return;
    if (debug && spacerRef.current) {
      setHeight(spacerRef.current.getBoundingClientRect().height);
      setBreakpoint(theme.breakpoints[index - 1]);
    }
  }, [debug, height, index, theme.breakpoints]);

  return (
    <StyledSpacer
      id={id}
      ref={spacerRef}
      sx={{
        height: short
          ? ["50px", "", "50px", "100px"]
          : ["100px", "", "150px", "300px"],
        ...(debug && debugStyles),
        ...spacerStyles,
        ...sx,
      }}
    >
      {debug && (
        <p>
          height: {height}px, min-width: {breakpoint}
        </p>
      )}
    </StyledSpacer>
  );
};

export default Spacer;

import styled from "@emotion/styled";
import { Box, Flex } from "theme-ui";
import { Link } from "react-router-dom";
import { Grid } from "../../styles/layout";
import Spacer from "../../components/utility/Spacer";
import HorizontalLine from "../../components/shared/decorative/HorizontalLine";
import TextBlock from "../../components/shared/typography/TextBlock";
import ImageBlock from "../../components/media/ImageBlock";
import TextRow, { mapTextRowData } from "../../components/content/TextRow";
import { useCustomCursor } from "../../hooks/useCustomCursor";

const StyledHomeSectors = styled(Box)`
  label: homepage-sectors;
`;

const textRowStyles = {
  headingStyles: {
    marginBottom: ["32px", "", "32px", "0"],
  },
};

const HomeSectors = (props) => {
  const { sectors, textRowContent } = props.data;
  const [ref, handleMouseEnter, handleMouseLeave] = useCustomCursor(
    "Explore",
    "default"
  );

  return (
    <StyledHomeSectors
      as="section"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <HorizontalLine />
      {textRowContent && (
        <TextRow data={mapTextRowData(textRowContent)} styles={textRowStyles} />
      )}
      {sectors && (
        <Grid>
          <Flex
            ref={ref}
            sx={{
              gridColumn: ["columns-start / columns-end", "", "3 / -2"],
              flexWrap: "wrap",
              margin: [
                "0 -16px 32px",
                "",
                "0 -32px 14px",
                "0 -32px 8px",
                "0 -50px 8px",
              ],
              paddingTop: ["32px", "14px", "8px"],
            }}
          >
            {sectors.items.map((sector, index) => (
              <Box
                key={index}
                as={Link}
                to={sector.slug}
                sx={{
                  overflow: "hidden",
                  mt: ["48px", "", "66px", "120px"],
                  display: "block",
                  textAlign: "center",
                  flex: ["0 0 50%", "", "0 0 33%", "0 0 25%"],
                  padding: ["0 16px", "", "0 32px", "", "0 50px"],
                  textDecoration: "none",
                  ":nth-of-type(10n+2)": {
                    mr: ["", "", "33%", "0"],
                  },
                  ":nth-of-type(10n+6)": {
                    // Temporary styling if 8 sectors are listed to avoid single handing item as per UI request
                    ml: ["", "", sectors.items.length === 8 ? "" : "33%", "0"],
                  },
                  img: {
                    transformOrigin: "50% 50%",
                    transform: "scale3d(1 , 1 , 1)",
                    transition: "transform 450ms ease-in-out",
                  },
                  "&:hover, &:focus": {
                    textDecoration: "underline",
                    color: "inherit",
                    img: {
                      transform: "scale3d(1.1, 1.1, 1)",
                      transition: "transform 450ms ease-in-out",
                    },
                  },
                }}
              >
                <Box
                  className="c-sector-item"
                  sx={{ overflow: "hidden", borderRadius: "500px" }}
                >
                  <ImageBlock
                    sx={{
                      img: {
                        aspectRatio: "3 / 4.5",
                        objectFit: "cover",
                        height: "100%",
                      },
                    }}
                    borderRadius="500px"
                    height="100%"
                    data={{
                      image: {
                        url:
                          sector && sector.heroImage
                            ? sector.heroImage[0].public_id
                            : null,
                        alt: sector.heroImageAltText,
                        src: sector.heroImage[0].url,
                      },
                      maskColour: sector.heroImageMaskColour
                        ? sector.heroImageMaskColour
                        : "#050514",
                    }}
                  />
                </Box>
                <TextBlock
                  as="p"
                  variant="text.cta.large"
                  sx={{ mt: ["24px", "", "32px", "56px"] }}
                >
                  {sector.title}
                </TextBlock>
              </Box>
            ))}
          </Flex>
        </Grid>
      )}
      <Spacer sx={{ height: ["200px", "", "250px", "350px"] }} />
    </StyledHomeSectors>
  );
};

export default HomeSectors;

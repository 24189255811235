import { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { StickyContainer } from "react-sticky";
import ReactGA from "react-ga4";
// Components
import Layout from "./components/global/Layout";
import Footer from "./components/global/Footer";
import Loader from "./components/global/Loader";
import ContactUs from "./components/shared/ctas/ContactUs";

// Utilities
import ScrollToTop from "./components/utility/ScrollToTop";

// Hooks
import { useCreateRoutes } from "./hooks/useCreateRoutes";
// import { useStateWithSessionStorage } from "./hooks/useStateWithSessionStorage";

// Styles
import "./App.css";

// Analytics
// ReactGA.initialize("GTM-MNP7DJD");
ReactGA.initialize("G-PERF0CPQCM");

// Upgrade from v5 to v6
// Move <Route children> to <Route element>
// https://reactrouter.com/en/main/upgrading/v5#relative-routes-and-links
const RouteChildren = (routeComponent) => {
  return (
    <>
      <StickyContainer>
        <ScrollToTop />
        {routeComponent}
        <ContactUs />
        <Footer />
      </StickyContainer>
    </>
  );
};

const App = () => {
  const location = useLocation();
  const routes = useCreateRoutes();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (routes !== null) {
      setIsReady(true);
    }
  }, [routes]);

  return !isReady ? (
    <Loader />
  ) : (
    <Layout location={location}>
      <Routes location={location} key={location.pathname}>
        {routes.map((route, index) => {
          return (
            route.public && (
              <Route
                key={index}
                path={route.path}
                element={RouteChildren(route.component)}
              />
            )
          );
        })}
      </Routes>
    </Layout>
  );
};

export default App;

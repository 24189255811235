import styled from "@emotion/styled";
import { Box } from "theme-ui";

import { Grid } from "../../styles/layout";
import { Heading } from "../../components/shared/typography/Heading";
import ImageBlock from "../media/ImageBlock";
import RichText from "../shared/richtext/RichText";
import Spacer from "../utility/Spacer";
import TextBlock from "../shared/typography/TextBlock";

const StyledHero = styled(Box)`
  label: hero-with-columns;
`;

const imageLeftStyles = {
  gridColumn: [
    "columns-start / columns-end",
    "",
    "columns-start / span 4",
    "columns-start / span 5",
  ],
};

const imageRightStyles = {
  gridColumn: [
    "columns-start / columns-end",
    "",
    "span 4 / columns-end",
    "span 5 / columns-end",
  ],
};

const textLeftStyles = {
  gridColumn: [
    "columns-start / columns-end",
    "",
    "columns-start / span 4",
    "columns-start / span 4",
  ],
};

const textRightStyles = {
  gridColumn: ["columns-start / columns-end", "", "6 / span 4", "10 / span 4"],
};

const HeroSingleColumn = ({ data }) => {
  return (
    <StyledHero as="section" sx={{ marginTop: ["120px", "", "28px", "32px"] }}>
      <Grid label="internal-hero">
        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              "4 / span 1",
              "5 / span 1",
            ],
          }}
        >
          <Box
            sx={{
              writingMode: ["", "", "vertical-lr"],
              transform: ["", "", "scale(-1, -1)"],
              width: ["", "", "", "100%"],
              marginBottom: ["24px", "", "0px"],
            }}
          >
            <TextBlock as="span">{data.label}</TextBlock>
          </Box>
        </Box>
        <Heading.H1Variable
          text={data.longTitle}
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              "5 / span 4",
              data.longTitle.length > 27 ? "span 6 / -3" : "span 5 / -4",
              data.longTitle.length > 27 ? "span 6 / -3" : "span 5 / -4",
            ],
          }}
        />
        <Spacer sx={{ gridColumn: "columns-start / columns-end" }} />
        {data.image && (
          <Box
            sx={{
              marginBottom: ["48px", "", "0"],
              gridRow: ["", "", "3 / span 2"],
              ...(data.imagePosition === "left"
                ? imageLeftStyles
                : imageRightStyles),
            }}
          >
            <ImageBlock
              data={{
                image: { url: data.image.public_id, alt: data.imageAltText },
                maskColour: data.maskColour,
              }}
            />
          </Box>
        )}

        <Box
          sx={{
            ...(data.imagePosition !== "left"
              ? textLeftStyles
              : textRightStyles),
            gridRow: ["", "", "3 / span 2"],
          }}
        >
          {data.heroColumnLead && (
            <RichText content={data.heroColumnLead} isLarge={true} />
          )}
          <RichText content={data.heroColumnBody} />
        </Box>
      </Grid>
    </StyledHero>
  );
};

export default HeroSingleColumn;

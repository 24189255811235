import { useContext, useRef, useCallback } from "react";
import { StoreContext } from "../store/store";

export const useCustomCursor = (cursorText, cursorType) => {
  const ref = useRef(null);
  const store = useContext(StoreContext);

  const handleMouseEnter = useCallback(() => {
    store.setCursor({ active: true, text: cursorText, type: cursorType });
  }, [cursorText, cursorType, store]);

  const handleMouseLeave = useCallback(() => {
    store.resetCursor();
  }, [store]);

  return [ref, handleMouseEnter, handleMouseLeave];
};

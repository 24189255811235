import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { Box } from "theme-ui";
import { Grid } from "../../styles/layout";
import { Heading } from "../../components/shared/typography/Heading";
import RichText from "../../components/shared/richtext/RichText";
import { Button } from "../../components/shared/ctas/Button";
import Spacer from "../../components/utility/Spacer";
import { NavyLogo } from "../../components/global/Logo";
import { NavLink } from "react-router-dom";
import Sticky from "react-stickynode";
import OpacityWrap from "../../components/utility/OpacityWrap";
import ImageBlockAnimated from "../../components/media/ImageBlockAnimated";

export const mapHomeHeroData = (data) => {
  return {
    heading: data.homeHeading,
    intro: data.homeHeroIntro,
    leftColumnText: data.homeHeroColumnLeft,
    rightColumnText: data.homeHerColumnRight,
    image: data.homeHeroImage,
    maskColor: data.homeHeroImageMaskColour,
  };
};

const gradientBG = keyframes`
	0% {
		background-position: 0% 50%;
    transform: translate(-50%, -50%) rotate(0deg);
	}
  25% {
    background-position: 100% 50%;
  }
	50% {
    background-position: 0% 50%; 
    transform: translate(-50%, -50%) rotate(360deg); 
	}
  75% {
    background-position: 100% 50%;
  }
	100% {
    background-position: 0% 50%;
   transform: translate(-50%, -50%) rotate(0deg);
	}
`;

const StyledHeader = styled(Box)`
  label: header;
`;

//!! Notes for Hotfix for pseudo element still appearing after overflow:hidden applied
// https://stackoverflow.com/questions/13024812/overflowhidden-not-hiding-elements-under-a-border-radius
// https://stackoverflow.com/questions/5736503/how-to-make-css3-rounded-corners-hide-overflow-in-chrome-opera/10296258#10296258

const HomeHero = (props) => {
  const { data } = props;
  const { hero } = data;
  return (
    <StyledHeader as="header">
      <Grid label="home-hero">
        <Heading.H1
          text={
            hero.homeHeading.includes("Together,")
              ? hero.homeHeading.replace("Together,", "Together,\n")
              : hero.homeHeading
          }
          sx={{
            gridColumn: [
              "columns-start / span 3",
              "",
              "2 / span 4",
              "2 / span 4",
            ],
            gridRow: ["", "", "1"],
            mt: ["20px", "", "40px"],
            marginBottom: ["80px", "", "60px", "120px", "0px"],
            whiteSpace: ["", "", "", "pre-line"],
          }}
        />
        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              "columns-start / span 3",
            ],
            marginBottom: ["32px", "", "0"],
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <Box sx={{ mb: ["40px"], zIndex: 1, width: "max-content" }}>
            <OpacityWrap threshold={0.25} duration={0.6}>
              <Sticky enabled={true} top={0}>
                <NavLink to="/" end title="Home">
                  <NavyLogo />
                </NavLink>
              </Sticky>
            </OpacityWrap>
          </Box>
          <RichText content={hero.homeHeroIntro} />
        </Box>
        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              "span 4 / columns-end",
              "span 4 / -4",
            ],
            gridRow: ["", "", "1 / 3"],
            marginTop: ["", "", "250px", "160px", "200px"],
            marginBottom: ["48px", "", "0", "200px"],
          }}
        >
          <Box>
            <ImageBlockAnimated
              data={{
                images: hero.homeHeroImage,
                maskColour: hero.homeHeroImageMaskColour,
                altText: hero.homeHeroImageAltText,
              }}
              sx={{
                borderRadius: "30em 30em 0px 0px",
                overflow:
                  "hidden" /* apply this to the container you want to animate */,
                WebkitMaskImage:
                  "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC)",
                "&::after": {
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  content: "''",
                  width: "400%",
                  height: "200%",
                  background:
                    "radial-gradient(40% 20%, #ffd76d 10%, #ffc4f3 50%, #74efff 100%)",
                  backgroundSize: "200% 200%",
                  mixBlendMode: "hard-light",
                  opacity: 0.4,
                  transform: "translate(-50%, -50%) rotate(0deg)",
                  animation: `${gradientBG} 15s ease infinite`,
                  animationDelay: "500ms",
                },
              }}
              borderRadius={{ borderRadius: "30em 30em 0px 0px" }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              "4 / span 3",
              "8 / span 3",
            ],
            mt: ["", "", "120px", "0"],
          }}
        >
          <RichText content={hero.homeHeroColumnLeft} />
        </Box>
        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "",
              "7 / span 3",
              "11 / span 3",
            ],
            // mt: ["", "", "180px", "80px"],
            mt: ["24px", "", "120px", "0"],
          }}
        >
          <RichText content={hero.homeHeroColumnRight} />
        </Box>
        <Box
          sx={{
            gridColumn: [
              "columns-start / columns-end",
              "span 3 / columns-end",
              "span 4 / columns-end",
              "span 3 / -2",
            ],
            marginTop: ["56px", "", "100px", "120px"],
          }}
        >
          <Button.Primary
            text="Our approach"
            to="/approach"
            ariaLabel="Learn about our approach"
            sx={{ maxWidth: ["", "", "", "272px"], ml: ["", "", "", "auto"] }}
          />
        </Box>
      </Grid>
      <Spacer />
    </StyledHeader>
  );
};

export default HomeHero;

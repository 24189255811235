// import { useLayoutEffect, useRef } from "react";
import styled from "@emotion/styled";
import { useInView } from "react-intersection-observer";
import useEmblaCarousel from "embla-carousel-react";
import { gql } from "@apollo/client";
import { Box } from "theme-ui";

import ImageBlock from "./ImageBlock";

import { Grid } from "../../styles/layout";

// This should be the only place to add/edit/delete the fields for this component
// Import this fragment into your template where you are using this component.
export const imageCarouselFrag = gql`
  fragment imageCarouselFrag on BlockImageGallery {
    images
    imagesAltText
    imageMaskColour
  }
`;

export const mapImageCarouselData = (data) => {
  return {
    images: data.images,
    imagesAltText: data.imagesAltText,
    maskColour: data.imageMaskColour,
  };
};

const StyledCarouselWrapper = styled.section``;

const StyledEmblaCarouselReact = styled(Box)``;

const StyledCarousel = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  cursor: grab;
`;

const carouselItemBaseStyles = {
  display: "flex",
  position: "relative",
  paddingRight: "1vw",
};

const carouselItemDefault = {
  flex: ["0 0 45%", "", "0 0 45%;", "0 0 31.5%"],
};

const carouselItemLandscape = {
  flex: ["0 0 80%", "", "0 0 70%", "1 0 50%"],
  minWidth: ["", "", "", "40%"],
  maxWidth: ["", "", "", "60%"],
};

const carouselItemPortrait = {
  flex: ["0 0 60%", "", "0 0 50%", "1 0 40%"],
  minWidth: ["", "", "", "20%"],
  maxWidth: ["", "", "", "40%"],
};

const carouselFirstImageStyles = {
  borderTopLeftRadius: ["16px", "", "20px", "", "30px"],
  borderBottomLeftRadius: ["16px", "", "20px", "", "30px"],
  ":before": {
    borderTopLeftRadius: ["16px", "", "20px", "", "30px"],
    borderBottomLeftRadius: ["16px", "", "20px", "", "30px"],
  },
};

const carouselLastImageStyles = {
  borderTopRightRadius: ["16px", "", "20px", "", "30px"],
  borderBottomRightRadius: ["16px", "", "20px", "", "30px"],
  ":before": {
    borderTopRightRadius: ["16px", "", "20px", "", "30px"],
    borderBottomRightRadius: ["16px", "", "20px", "", "30px"],
  },
};

const ImageCarousel = ({ data }) => {
  const [emblaRef] = useEmblaCarousel({
    align: "start",
    containScroll: "keepSnaps",
  });

  const options = {
    threshold: 0.125,
    triggerOnce: true,
  };

  const [carouselRef] = useInView(options);
  const carouselLastItem = data.images != null && data.images.length - 1;
  return (
    <StyledCarouselWrapper ref={carouselRef}>
      <Grid sx={{ overflow: "hidden" }}>
        <StyledEmblaCarouselReact
          ref={emblaRef}
          sx={{
            gridColumn: [
              "columns-start / right-margin",
              "",
              "3 / right-margin",
              "",
            ],
          }}
        >
          <StyledCarousel>
            {data.images != null &&
              data.images.map((image, index) => {
                if (image.orientation) {
                  return (
                    <Box
                      sx={{
                        ...carouselItemBaseStyles,
                        ...(image.orientation === "portrait"
                          ? carouselItemPortrait
                          : carouselItemLandscape),
                      }}
                      key={index}
                    >
                      <ImageBlock
                        data={{
                          image: {
                            url: image.public_id,
                            alt: "Testing",
                            // alt: data.imageAltText && data.imageAltText[index],
                          },
                          maskColour: data.maskColour,
                        }}
                        sx={{
                          borderRadius: 0,
                          ...(index === 0 ? carouselFirstImageStyles : ""),
                          ...(carouselLastItem === index
                            ? carouselLastImageStyles
                            : ""),
                        }}
                      />
                    </Box>
                  );
                } else {
                  return (
                    <Box
                      sx={{
                        ...carouselItemBaseStyles,
                        ...carouselItemDefault,
                      }}
                      key={index}
                    >
                      <ImageBlock
                        data={{
                          image: {
                            url: image.public_id,
                            alt:
                              data.imagesAltText && data.imagesAltText[index],
                          },
                          maskColour: data.maskColour,
                        }}
                        borderRadius={0}
                        sx={{
                          ...(index === 0 ? carouselFirstImageStyles : ""),
                          ...(carouselLastItem === index
                            ? carouselLastImageStyles
                            : ""),
                        }}
                      />
                    </Box>
                  );
                }
              })}
          </StyledCarousel>
        </StyledEmblaCarouselReact>
      </Grid>
    </StyledCarouselWrapper>
  );
};

export default ImageCarousel;

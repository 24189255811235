import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const OpacityWrap = ({
  children,
  threshold = 0.1,
  duration = 0.3,
  delay = 0,
}) => {
  const [ref, inView] = useInView({
    threshold: threshold,
    triggerOnce: true,
  });
  return (
    <motion.div
      ref={ref}
      variants={fadeIn}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      transition={{
        default: { duration: duration, delay: delay, ease: "easeInOut" },
      }}
    >
      {children}
    </motion.div>
  );
};

export default OpacityWrap;

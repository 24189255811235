import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { Box, Label, Input, Textarea, Select, Button } from "theme-ui";
import TextBlock from "../typography/TextBlock";

const hoverStyles = {
  transition: "opacity 500ms 100ms ease-in-out",
  ".lines": {
    "&:hover, &:focus": {
      "&:before, &:after": {
        bg: "blue",
      },
    },
    "&.is-focused": {
      "&:before, &:after": {
        bg: "blue",
      },
    },
    "&.has-errors": {
      "&:before, &:after": {
        bg: "red",
      },
    },
  },
};

const borderStyles = {
  "&:before": {
    content: `""`,
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "1px",
    height: "10px",
    bg: "greyDark",
  },
  "&:after": {
    content: `""`,
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "1px",
    height: "10px",
    bg: "greyDark",
  },
};

const InputLabel = ({ htmlFor, labelText, required }) => (
  <Label sx={{ paddingBottom: "16px" }} htmlFor={htmlFor}>
    <TextBlock
      text={`${labelText}${required ? "*" : ""}`}
      sx={{
        variant: "text.body.small",
        fontWeight: "medium",
        color: "blue",
      }}
    />
  </Label>
);

export const InputField = {
  Standard: ({
    type,
    id,
    name,
    placeholder,
    label,
    register,
    validationRules,
    validationMessage,
    errors,
    required,
    sx,
  }) => {
    const [inputRef, inputView] = useInView({
      threshold: 0.1,
      triggerOnce: true,
    });
    const [isFocused, setIsFocused] = useState(false);
    return (
      <Box
        ref={inputRef}
        sx={{ opacity: inputView ? 1 : 0, ...hoverStyles, ...sx }}
      >
        <InputLabel htmlFor={id} labelText={label} required={required} />
        <Box
          className={`lines ${errors ? "has-errors" : ""} ${
            isFocused ? "is-focused" : ""
          }`}
          sx={{
            position: "relative",
            "&:before": {
              content: `""`,
              position: "absolute",
              bg: "greyDark",
              height: "1px",
              width: "100%",
              left: 0,
              bottom: 0,
            },
          }}
        >
          <Box
            className={`lines ${errors ? "has-errors" : ""} ${
              isFocused ? "is-focused" : ""
            }`}
            sx={{
              display: "flex",
              position: "relative",
              ...borderStyles,
            }}
          >
            <Input
              type={type ? type : "text"}
              id={id}
              name={name}
              placeholder={placeholder}
              onFocus={() => {
                setIsFocused(true);
              }}
              sx={{
                variant: "text.body.regular",
                padding: "0 10px",
                ...borderStyles,
              }}
              {...(register
                ? {
                    ...register(name, {
                      required: required ? validationMessage : "",
                      ...validationRules,
                      onBlur: () => {
                        setIsFocused(false);
                      },
                    }),
                  }
                : {})}
            />
          </Box>
        </Box>
        {errors && (
          <TextBlock
            text={`${errors?.message}`}
            sx={{ marginTop: "8px", variant: "text.body.xSmall", color: "red" }}
          />
        )}
      </Box>
    );
  },
  Dropdown: ({ id, name, label, register, required, selectOptions, sx }) => {
    const [inputRef, inputView] = useInView({
      threshold: 0.1,
      triggerOnce: true,
    });
    const [isFocused, setIsFocused] = useState(false);
    const [, setIsOpened] = useState(false);

    return (
      <Box
        ref={inputRef}
        sx={{
          opacity: inputView ? 1 : 0,
          ...hoverStyles,
          ...sx,
          label: { paddingBottom: "2px" },
        }}
      >
        <InputLabel htmlFor={id} labelText={label} required={required} />
        <Box
          className={`lines ${isFocused ? "is-focused" : ""}`}
          sx={{
            position: "relative",
            "&:before": {
              content: `""`,
              position: "absolute",
              bg: "greyDark",
              height: "1px",
              width: "100%",
              left: 0,
              bottom: 0,
            },
          }}
        >
          <Select
            id={id}
            name={name}
            onFocus={() => {
              setIsFocused(true);
            }}
            onClick={() => {
              setIsOpened(true);
            }}
            defaultValue=""
            arrow={
              <Box
                className="dropdown-icon"
                sx={{
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  pointerEvents: "none",
                }}
              >
                <svg
                  width="24"
                  height="13"
                  viewBox="0 0 24 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.000860214 0.499512L0 0.500372L12 12.5004L24 0.500372L23.9991 0.499512H0.000860214Z"
                    fill="currentColor"
                  />
                </svg>
              </Box>
            }
            sx={{
              border: "none",
              variant: "text.body.regular",
              color: "blue",
              // "+.dropdown-icon": {
              // transition: "transform 100ms ease-in-out",
              // transform: isOpened ? "rotate(180deg)" : "rotate(0deg)",
              // },
            }}
            {...(register
              ? {
                  ...register(name),
                  onBlur: () => {
                    setIsFocused(false);
                  },
                  onChange: () => {
                    setIsOpened(false);
                  },
                }
              : {})}
          >
            {selectOptions.map((item, index) => {
              return (
                <option key={index} value={item} defaultValue>
                  {item}
                </option>
              );
            })}
          </Select>
          <Box
            className="lines"
            sx={{
              display: "flex",
              position: "relative",
              width: "100%",
              ...borderStyles,
            }}
          />
        </Box>
      </Box>
    );
  },
  TextArea: ({
    id,
    name,
    placeholder,
    label,
    rows,
    register,
    validationRules,
    validationMessage,
    errors,
    required,
    sx,
  }) => {
    const [inputRef, inputView] = useInView({
      threshold: 0.1,
      triggerOnce: true,
    });
    return (
      <Box
        ref={inputRef}
        sx={{
          opacity: inputView ? 1 : 0,
          transition: "opacity 500ms 100ms ease-in-out",
          ...sx,
          "&:hover": { textarea: { borderColor: "blue" } },
        }}
      >
        <InputLabel htmlFor={id} labelText={label} required={required} />
        <Textarea
          id={id}
          name={name}
          placeholder={placeholder}
          rows={rows}
          sx={{
            borderColor: "greyDark",
            borderRadius: 0,
            resize: "none",
            fontFamily: "'SuisseIntl Web', 'Helvetica', Arial, sans-serif",
            variant: "text.body.regular",
            color: "blue",
            "&:focus": {
              borderColor: "blue",
            },
          }}
          {...(register
            ? {
                ...register(name, {
                  required: required ? validationMessage : "",
                  ...validationRules,
                }),
              }
            : {})}
        />
        {errors && (
          <TextBlock
            text={`${errors?.message}`}
            sx={{ marginTop: "8px", variant: "text.body.xSmall", color: "red" }}
          />
        )}
      </Box>
    );
  },
  SingleLineSubmit: ({
    type,
    id,
    name,
    placeholder,
    register,
    validationRules,
    validationMessage,
    errors,
    required,
    sx,
  }) => {
    const [inputRef, inputView] = useInView({
      threshold: 0.1,
      triggerOnce: true,
    });
    return (
      <Box
        ref={inputRef}
        sx={{ opacity: inputView ? 1 : 0, ...hoverStyles, ...sx }}
      >
        <Box
          className="lines"
          sx={{
            position: "relative",
            "&:before": {
              content: `""`,
              position: "absolute",
              bg: errors ? "red" : "greyDark",
              height: "1px",
              width: "100%",
              left: 0,
              bottom: 0,
            },
          }}
        >
          <Box
            className="lines"
            sx={{
              display: "flex",
              position: "relative",
              "&:before": {
                content: `""`,
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "1px",
                height: "10px",
                bg: errors ? "red" : "greyDark",
              },
              "&:after": {
                content: `""`,
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "1px",
                height: "10px",
                bg: errors ? "red" : "greyDark",
              },
            }}
          >
            <Input
              type={type ? type : "text"}
              id={id}
              name={name}
              placeholder={placeholder}
              sx={{
                variant: "text.body.regular",
                padding: "0 10px",
                color: errors && "red",
              }}
              {...(register
                ? {
                    ...register(name, {
                      required: required ? validationMessage : "",
                      ...validationRules,
                    }),
                  }
                : {})}
            />
            <Button
              title="Submit"
              sx={{
                color: "blue",
                background: "none",
                "&:hover": { cursor: "pointer" },
                svg: { color: errors && "red" },
              }}
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.3092 11.8231L10.3269 1.48753L11.2491 0.499969L24.0002 12.5L11.2491 24.5L10.3269 23.5125L21.3095 13.1767L0.0001297 13.1767L0.000129582 11.8231L21.3092 11.8231Z"
                  fill="currentColor"
                />
              </svg>
            </Button>
          </Box>
        </Box>
        {errors && (
          <TextBlock
            text={`${errors?.message}`}
            sx={{ marginTop: "8px", variant: "text.body.xSmall", color: "red" }}
          />
        )}
      </Box>
    );
  },
};
